import * as React from 'react'
import {Box} from '@mui/material'
import Card from '@mui/material/Card'
import Question from 'components/clinicalQuestions/Question'
import Link from '@mui/material/Link'

import IconBar from 'components/clinicalQuestions/IconBar'
import QuestionImages from 'components/clinicalQuestions/QuestionImages'

export default function QuestionCard(props) {
  const {question} = props
  const [showIconBar, setShowIconBar] = React.useState(false)
  const hasImage = question.images.length >= 1 ? true : false

  return (
    <Link
      sx={styles.cardContainer}
      href={`/clinical-questions/${question.id}/`}
      underline="none"
    >
      <Card
        variant="outlined"
        sx={styles.card}
        onMouseEnter={() => setShowIconBar(true)}
        onMouseLeave={() => setShowIconBar(false)}
      >
        <Box sx={styles.cardCategory}>{question.category[0]?.name}</Box>
        <Box sx={{mt: 3}}>
          {hasImage && (
            <Box sx={{textAlign: 'center', mb: 0}}>
              <QuestionImages images={question.images} />
            </Box>
          )}
          <Question question={question} shortText={true} />
        </Box>
        <Box
          sx={
            showIconBar ? styles.iconBarContainerShow : styles.iconBarContainer
          }
        >
          <IconBar question={question} />
        </Box>
      </Card>
    </Link>
  )
}

const styles = {
  cardContainer: {
    display: 'flex',
    minWidth: 275,
    mr: {md: 1, sm: 1, xs: 1},
    mb: {md: 0, sm: 3, xs: 3},
    pointer: 'cursor',
  },
  iconBarContainer: {
    display: 'none',
  },
  iconBarContainerShow: {
    display: 'block',
    mt: 2,
    animation: 'fadeIn 2s',
  },
  card: {
    minHeight: 494,
    maxHeight: 494,
    maxWidth: 373,
    p: 2,
    borderRadius: 3,
    backgroundColor: '#fff',
    overflow: 'hidden',
  },
  // add keyframes for animation
  '@keyframes fadeIn': {
    from: {opacity: 0},
    to: {opacity: 1},
  },
  cardCategory: {
    m: -2,
    backgroundColor: 'primary.main',
    color: '#fff',
    p: 1,
  },
}

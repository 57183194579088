import React from 'react'
import Image from 'mui-image'
import {Box, Link, Typography} from '@mui/material'

export default function SharedLink(props) {
  const {item} = props
  return (
    <Link
      href={item.shared_link}
      underline="none"
      target="_blank"
      rel="noreferrer"
    >
      <Box sx={styles.container}>
        {item.shared_link_meta_image && (
          <Image src={item.shared_link_meta_image} sx={styles.imageContainer} />
        )}
        <Box sx={styles.textContainer}>
          <Typography variant="body2">{item.shared_link_meta_title}</Typography>

          <Typography variant="caption">
            {item.shared_link_meta_description}
          </Typography>
          <br />
          <Typography variant="caption">
            {item.shared_link.slice(0, 33)}...
          </Typography>
        </Box>
      </Box>
    </Link>
  )
}

const styles = {
  container: {
    display: 'flex',
    border: '1px solid #e8e8e8',
    flexDirection: {
      xs: 'column',
      sm: 'row',
      md: 'row',
    },
    borderRadius: '6px',
    background: '#f5f5f5',
    m: '16px 0 10px 0',
    p: 2,
    alignItems: 'center',
  },
  imageContainer: {
    maxWidth: '100%',
    height: 'auto',
    flex: '0 0 auto',
  },
  textContainer: {
    flex: '1 1 auto',
    boxSizing: 'border-box',
    p: '0 1em',
    wordBreak: 'normal',
    whiteSpace: 'normal',
  },
}

import React from 'react'
import {Header, List} from 'semantic-ui-react'

export default function InboxMessage({message, openMessage}) {
  const date = new Date(message.sent_at).toLocaleDateString()
  const time = new Date(message.sent_at).toLocaleTimeString()
  return (
    <List.Item
      className={message.read_at === null ? 'message_unread' : null}
      onClick={() => openMessage(message.id)}
    >
      <div className="w-100 d-flex-space-b">
        <Header as="h4" content={message.sender.full_name} />
        <small>
          {date} {time}
        </small>
      </div>
      <p>{message.subject}</p>
    </List.Item>
  )
}

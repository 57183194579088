import React from 'react'
import Loadable from '@loadable/component'

import Loading from '../../components/base/ComponentLoading'

const PageComponent = Loadable(() => import('./Page'))

function Page() {
  return <PageComponent fallback={<Loading />} />
}

export default Page

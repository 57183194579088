import React from 'react'
import {Box, Avatar} from '@mui/material'
import AnswerActions from './AnswerActions'
import AnswerText from './AnswerText'
import ListOfReplies from './replies/ListOfReplies'
import ReplyInput from './replies/ReplyInput'

export default function Answer(props) {
  const {answer} = props
  const [showReplyInput, setShowReplyInput] = React.useState(false)
  const [userSlug, setUserSlug] = React.useState('')

  console.log(answer)
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'flex-start',
          gap: 2,
          mt: 3,
        }}
      >
        <Avatar
          alt={answer.user.full_name}
          src={(answer.user.profile && answer.user.profile.profile_image) || ''}
        />
        <Box sx={{mt: 0.4}}>
          <AnswerText answer={answer} />
          <AnswerActions
            answer={answer}
            setShowReplyInput={setShowReplyInput}
            setUserSlug={setUserSlug}
            showReplyInput={showReplyInput}
          />
          {answer.answer_replies.length > 0 && (
            <ListOfReplies replies={answer.answer_replies} />
          )}
          {showReplyInput && <ReplyInput answer={answer} userSlug={userSlug} />}
        </Box>
      </Box>
    </>
  )
}

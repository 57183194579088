import React from 'react'
import Divider from '@mui/material/Divider'
import LocumOveralRating from './summary-items/LocumOveralRating'
import LocumAverageWorkloadRating from './summary-items/LocumAverageWorkloadRating'
import EmployeeOverallRating from './summary-items/EmployeeOverallRating'
import EmployeeAverageWorkLoad from './summary-items/EmployeeAverageWorkLoad'
import HowLongToBePaid from './summary-items/HowLongToBePaid'
export default function ReviewSummaryItems(props) {
  const {pharmacy} = props
  return (
    <>
      <HowLongToBePaid pharmacy={pharmacy} />
      <Divider />
      <LocumOveralRating pharmacy={pharmacy} />
      <Divider />
      <LocumAverageWorkloadRating pharmacy={pharmacy} />
      <Divider />
      <EmployeeOverallRating pharmacy={pharmacy} />
      <Divider />
      <EmployeeAverageWorkLoad pharmacy={pharmacy} />
      <Divider />
    </>
  )
}

import React from 'react'
import {withRouter} from 'react-router-dom'
import {Formik} from 'formik'
import {Message, Form as FormUi} from 'semantic-ui-react'
import * as Yup from 'yup'
import {useToasts} from 'react-toast-notifications'

import reviewStyle from '../Review.module.css'
import TextInput from '../form-fields/TextInput'
import RatingOutOTen from '../form-fields/RatingOutOTen'
import TextArea from '../form-fields/TextArea'
import RadioButton from '../form-fields/RadioButton'
import Api from '../../../../services/api.service'
import {useHistory} from 'react-router'

const initialValues = {
  date_of_booking: '',
  overall_rating: 1,
  workload_rating: 1,
  hourly_rate: 0,
  recommended: '',
  review: '',
}

const validationSchema = Yup.object({
  date_of_booking: Yup.date().required('Required'),
  overall_rating: Yup.number().required('Required').min(1).max(10),
  workload_rating: Yup.number().required('Required').min(1).max(10),
  hourly_rate: Yup.number()
    .required('Required')
    .min(10, 'Must be between £10 and £100')
    .max(100),
  recommended: Yup.string().required('Required'),
  review: Yup.string()
    .required('Required')
    .min(10, 'Must be at least 10 characters'),
})

function LocumPharmacistReview(props) {
  const {addToast} = useToasts()
  let history = useHistory()
  const {slug} = props.match.params

  const redirectToSuccess = async newPostId => {
    console.log('redirecting', newPostId)
    if (newPostId >= 1) {
      setTimeout(() => {
        history.push(
          `/page/pharmacy/${slug}/create-review/locum-pharmacist/${newPostId}/success/`,
        )
      }, 2000)
    }
  }

  const submitForm = async values => {
    const apiCall = await Api.post(
      `/api/business/pharmacy/${slug}/create_locum_pharmacy_review/`,
      values,
    )
    if (apiCall.status === 201) {
      addToast('Your review has been created successfully. Thank you!', {
        appearance: 'success',
        autoDismiss: true,
      })
      return apiCall.data.id
    } else {
      return 0
    }
  }

  return (
    <div className="main">
      <div className={reviewStyle.pageContainer}>
        <div className={reviewStyle.FormikContainer}>
          <Formik
            initialValues={initialValues}
            onSubmit={async (values, {setSubmitting}) => {
              const newPostId = await submitForm(values)
              setSubmitting(false)
              await redirectToSuccess(newPostId)
            }}
            validationSchema={validationSchema}
          >
            {({
              values,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              touched,
              errors,
            }) => (
              <form className="ui form" onSubmit={handleSubmit}>
                <TextInput
                  label="Date of booking"
                  id="date_of_booking"
                  name="date_of_booking"
                  helpText="for long term bookings please enter last day of your booking"
                  type="date"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.date_of_booking}
                />
                {errors.date && touched.date && errors.date}
                <RatingOutOTen
                  label="Overal Rating"
                  id="overall_rating"
                  name="overall_rating"
                  helpText="How would you rate your overall experience at this branch?"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {errors.overall_rating &&
                  touched.overall_rating &&
                  errors.overall_rating}
                <RatingOutOTen
                  label="Workload Rating"
                  id="workload_rating"
                  name="workload_rating"
                  helpText="How would you rate the workload? "
                  onBlur={handleBlur}
                  onChange={handleChange}
                  lowRating={'Low workload'}
                  highRating={'Very high workload'}
                />
                <TextInput
                  label="What was the hourly rate?"
                  id="hourly_rate"
                  name="hourly_rate"
                  helpText=" (data will be anonymised and used to provide information on the average rate in an area and nationally) IF YOU WERE PAID TRAVEL TIME PLEASE INCORPORATE INTO THE TOTAL HOURLY RATE"
                  type="number"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.hourly_rate}
                />
                <label className={reviewStyle.Label}>
                  Would you recommend working at this pharmacy to other locums?
                </label>
                <FormUi.Group inline>
                  <RadioButton
                    label="Yes"
                    id="recommended"
                    name="recommended"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <RadioButton
                    label="No"
                    id="recommended"
                    name="recommended"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <RadioButton
                    label="Not Sure"
                    id="recommended"
                    name="recommended"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormUi.Group>
                {errors.recommended && touched.hourly_rate && (
                  <Message negative>{errors.recommended}</Message>
                )}
                <TextArea
                  type="textarea"
                  label="Branch review"
                  id="review"
                  name="review"
                  onBlur={handleBlur}
                  value={values.review}
                />
                <div>
                  <button
                    type="submit"
                    className={reviewStyle.outlinedButton}
                    disabled={isSubmitting}
                  >
                    SUBMIT
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}
export default withRouter(LocumPharmacistReview)

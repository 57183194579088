import React from 'react'
import {Box} from '@mui/material'
import AnswerText from '../AnswerText'
import LikeButton from '../LikeButton'

export default function Reply(props) {
  const {reply} = props
  return (
    <Box sx={{borderLeft: '1px solid red', pl: 2, my: 1}}>
      <AnswerText answer={reply} />
      <LikeButton answer={reply} />
    </Box>
  )
}

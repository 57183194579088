import {useEffect, useState} from 'react'
import {Button, Modal, List, Image} from 'semantic-ui-react'
import Api from '../../../services/api.service'

function RecipientList(props) {
  const [open, setOpen] = useState(props.openModal)
  const [followings, setFollowings] = useState([])
  const [loading, setLoading] = useState(false)

  const getFollowings = () => {
    setLoading(true)
    Api.get('/api/friends/list/').then(response => {
      setLoading(false)
      setFollowings(response.data.results)
    })
  }
  const selectRecipient = friend => {
    props.setRecipient(friend)
    setOpen(false)
  }

  useEffect(() => {
    setOpen(props.openModal)
  }, [props.openModal])

  useEffect(() => {
    getFollowings()
  }, [])

  const followingsItems = followings.map(friend => {
    return (
      <List.Item key={friend.id}>
        <List.Content floated="right">
          <Button size="mini" onClick={() => selectRecipient(friend.friend)}>
            Select
          </Button>
        </List.Content>
        <Image avatar src={friend.friend.profile.profile_image} />
        <List.Content>
          <List.Header>
            <a href={`/account/${friend.friend.slug}`}>
              {friend.friend.full_name}
            </a>
          </List.Header>
        </List.Content>
      </List.Item>
    )
  })
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      trigger={<Button>Select the recipient</Button>}
    >
      <Modal.Header>Recipient</Modal.Header>
      <Modal.Content>
        {loading ? (
          'You can only send a message to the people you are following'
        ) : (
          <>
            <p>
              Please select a recipient from your following list. You can only
              message people you are following.
            </p>
            <List selection verticalAlign="middle">
              {followingsItems}
            </List>
          </>
        )}
      </Modal.Content>
    </Modal>
  )
}

export default RecipientList

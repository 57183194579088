import React from 'react'
import Api from '../../../../services/api.service'
import Button from '@mui/material/Button'
import {Typography} from '@mui/material'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

export default function AddNewAttribute(props) {
  // function to suggest an edit
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [inputData, setInputData] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false)
  const {pharmacy} = props

  const handleSubmit = async () => {
    const apiEndpoint = '/api/web/website-feedback/'
    try {
      setLoading(true)
      const res = await Api.post(apiEndpoint, {
        subject: 'Adding new atribute',
        message: `Above user have recommended adding the below ${props.type} to the pharmacy below:\nPharmacy  ${pharmacy.gphc_registration_number}\nPharmacy Name: ${pharmacy.trading_name}\n${props.type}: ${inputData}`,
      })
      if (res.status === 200) {
        setSubmitted(true)
      }
    } catch (error) {
      setLoading(false)
      console.error(error)
    }
  }
  return (
    <>
      <span onClick={handleOpen} color="secondary" style={{cursor: 'pointer'}}>
        click to Add new {props.type}
      </span>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add an edit</DialogTitle>

        {!submitted ? (
          <>
            <form onSubmit={() => handleSubmit()}>
              <DialogContent>
                <TextField
                  id="outlined-basic"
                  label={props.type.toUpperCase()}
                  variant="outlined"
                  placeholder={props.type}
                  onChange={e => setInputData(e.target.value)}
                  fullWidth
                  autoFocus
                  margin="dense"
                  sx={{minWidth: '20vw'}}
                />
              </DialogContent>
              <DialogActions>
                <Button type="submit" disabled={loading}>
                  Submit
                </Button>
              </DialogActions>
            </form>
          </>
        ) : (
          <DialogContent>
            <Typography variant="body1">
              Thank you for your contribution. Someone will verify the
              infomation and update our database.
            </Typography>
          </DialogContent>
        )}
      </Dialog>
    </>
  )
}

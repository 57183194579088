import React from 'react'
import {Box, Typography} from '@mui/material'
import FullAddress from 'components/pharmacies/branch/branch-details/components/FullAddress'
export default function AutoCompleteSearchItem(props) {
  const {result} = props
  const [backgroundColor, setBackgroundColor] = React.useState([])

  const handleClick = slug => () => {
    const url = `/page/pharmacy/${slug}/`
    window.location.href = url
  }

  return (
    <Box
      sx={{
        mb: 1,
        color: 'black',
        px: 3,
        py: 0.5,
        backgroundColor: backgroundColor,
        cursor: 'pointer',
      }}
      onMouseEnter={() => setBackgroundColor('#E8E8E9')}
      onMouseLeave={() => setBackgroundColor('white')}
      onClick={handleClick(result.slug)}
    >
      <Typography sx={{fontWeight: 400, fontSize: '13px'}}>
        {result.trading_name} - {result.company.name}
      </Typography>
      <Typography sx={{color: '#919191', fontSize: '13px'}}>
        <FullAddress pharmacy={result} />
      </Typography>
    </Box>
  )
}

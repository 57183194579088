import React from 'react'
import Avatar from '@mui/material/Avatar'
import FeedCss from '../feed.module.css'

export default function FeedPostUserAvatar(props) {
  const {item, avatarSize} = props
  const size = avatarSize || 39
  return (
    <div className={FeedCss.FeedItemUser}>
      <div>
        <Avatar
          src={item.user.profile.profile_image}
          className="feed__avatar_picture"
          alt={item.user.full_name}
          sx={{width: size, height: size}}
        />
      </div>
      <div className={FeedCss.FeedItemUserInfo}>
        <a href={`/account/${item.user.slug}`}>{item.user.full_name}</a>
        <span>{item.get_date}</span>
      </div>
    </div>
  )
}

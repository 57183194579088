import NotificationCss from '../notification.module.css'
import {Typography, Box, Link} from '@mui/material'

function MessageReceived(props) {
  const {notification} = props
  const postUrl = '/messages/'
  return (
    <Link
      href={postUrl}
      underline="none"
      sx={{display: 'flex', justifyContent: 'space-between'}}
    >
      <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
        <Box sx={{display: 'flex', gap: 2}}>
          <Box>
            <Typography>
              {notification.verb} <br />
              <Typography variant="caption" color="secondary">
                {notification.time_ago}
              </Typography>
            </Typography>
          </Box>
        </Box>
        <Box>
          {notification.unread && (
            <div className={NotificationCss.unreadIndicator}></div>
          )}
        </Box>
      </Box>
    </Link>
  )
}

export default MessageReceived

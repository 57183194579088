import React from 'react'
import {fetchAllClinicalCategories} from 'services/calls/ClinicalAPI'
import {Chip, Box, Typography} from '@mui/material'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export default function QuestionCategories(props) {
  const [loaded, setLoaded] = React.useState(false)
  const [clinicalCategories, setClinicalCategories] = React.useState([])

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 9,
    slidesToScroll: 3,
    initialSlide: 0,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 10,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  }

  React.useEffect(() => {
    fetchAllClinicalCategories().then(res => {
      setClinicalCategories(res.data)
      setLoaded(true)
    })
  }, [])

  const handleClick = category => {
    props.setActiveCategory(category)
  }

  return (
    <>
      {!loaded && <div>Loaded</div>}
      {loaded && (
        <Box>
          <Typography variant="h6" sx={{my: 2}}>
            Categories
          </Typography>
          <Slider {...settings}>
            {clinicalCategories.map((category, index) => {
              return (
                <Box key={index} sx={{mr: 1}}>
                  <Chip
                    key={index}
                    label={category.name}
                    onClick={() => handleClick(category.id)}
                  />
                </Box>
              )
            })}
          </Slider>
        </Box>
      )}
    </>
  )
}

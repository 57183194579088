import {styled, alpha} from '@mui/material/styles'

const FormSelectDiv = styled('div')(({theme}) => ({
  position: 'relative',
  borderTopLeftRadius: theme.shape.borderRadius,
  borderBottomLeftRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  color: theme.palette.common.white,
  marginRight: 0,
  marginLeft: 0,
  maxHeight: '40px',
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}))

export default FormSelectDiv

import React, {Component} from 'react'
import {Loader} from 'semantic-ui-react'

class ComponentLoading extends Component {
  render() {
    return (
      <div className="loading__background">
        <Loader active={true}>Loading</Loader>
      </div>
    )
  }
}

export default ComponentLoading

import React from 'react'
import {withRouter} from 'react-router-dom'
import {Formik} from 'formik'
import {Message, Form as FormUi} from 'semantic-ui-react'
import * as Yup from 'yup'
import {useToasts} from 'react-toast-notifications'

import reviewStyle from '../Review.module.css'
import TextInput from '../form-fields/TextInput'
import RatingOutOTen from '../form-fields/RatingOutOTen'
import TextArea from '../form-fields/TextArea'
import RadioButton from '../form-fields/RadioButton'
import MultiChoice from '../form-fields/MultiChoice'
import Api from '../../../../services/api.service'
import {useHistory} from 'react-router'
import listOfYearChoices from '../../../../components/base/utility/ListOfYear'

const initialValues = {
  last_year_at_pharmacy: '',
  overall_rating: 1,
  workload_rating: 1,
  yearly_salary: 0,
  recommended: '',
  review: '',
}

const validationSchema = Yup.object({
  last_year_at_pharmacy: Yup.number().required('Required'),
  overall_rating: Yup.number().required('Required').min(1).max(10),
  workload_rating: Yup.number().required('Required').min(1).max(10),
  yearly_salary: Yup.number().required('Required'),
  recommended: Yup.string().required('Required'),
  review: Yup.string()
    .required('Required')
    .min(10, 'Must be at least 10 characters'),
})

function PharmacistReview(props) {
  const {addToast} = useToasts()
  let history = useHistory()
  const {slug} = props.match.params

  const redirectToSuccess = async newPostId => {
    if (newPostId >= 1) {
      setTimeout(() => {
        history.push(
          `/page/pharmacy/${slug}/create-review/pharmacist/${newPostId}/success/`,
        )
      }, 2000)
    }
  }

  const submitForm = async values => {
    const apiCall = await Api.post(
      `/api/business/pharmacy/${slug}/create_pharmacist_review/`,
      values,
    )
    console.log(apiCall)
    if (apiCall.status === 201) {
      addToast('Your review has been created successfully. Thank you!', {
        appearance: 'success',
        autoDismiss: true,
      })
      return apiCall.data.id
    } else {
      return 0
    }
  }

  return (
    <div className="main">
      <div className={reviewStyle.pageContainer}>
        <div className={reviewStyle.FormikContainer}>
          <Formik
            initialValues={initialValues}
            onSubmit={async (values, {setSubmitting}) => {
              const newPostId = await submitForm(values)
              setSubmitting(false)
              await redirectToSuccess(newPostId)
            }}
            validationSchema={validationSchema}
          >
            {({
              values,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              touched,
              errors,
            }) => (
              <form className="ui form" onSubmit={handleSubmit}>
                <div className={reviewStyle.questionContainer}>
                  <label className={reviewStyle.Label}>
                    Last year at this employer?
                  </label>
                  <MultiChoice
                    label="Date of booking"
                    id="last_year_at_pharmacy"
                    choices={listOfYearChoices(5)}
                    name="last_year_at_pharmacy"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {errors.last_year_at_pharmacy &&
                    touched.last_year_at_pharmacy && (
                      <Message negative>{errors.last_year_at_pharmacy}</Message>
                    )}
                </div>
                <div className={reviewStyle.questionContainer}>
                  <RatingOutOTen
                    label="Overal Rating"
                    id="overall_rating"
                    name="overall_rating"
                    helpText="How would you rate your overall experience at this branch?"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {errors.overall_rating &&
                    touched.overall_rating &&
                    errors.overall_rating}
                </div>
                <div className={reviewStyle.questionContainer}>
                  <RatingOutOTen
                    label="Workload Rating"
                    id="workload_rating"
                    name="workload_rating"
                    helpText="How would you rate the workload? "
                    onBlur={handleBlur}
                    onChange={handleChange}
                    lowRating={'Low workload'}
                    highRating={'Very high workload'}
                  />
                </div>
                <div className={reviewStyle.questionContainer}>
                  <TextInput
                    label="What is your yearly salary?"
                    id="yearly_salary"
                    name="yearly_salary"
                    helpText="(data will be anonymised and used to provide information on the average rate in an area and nationally)"
                    type="number"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.yearly_salary}
                  />
                </div>
                <div className={reviewStyle.questionContainer}>
                  <label className={reviewStyle.Label}>
                    Would you recommend working at this Hospital to other
                    Pharmacists?
                  </label>
                  <FormUi.Group inline>
                    <RadioButton
                      label="Yes"
                      id="recommended"
                      name="recommended"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <RadioButton
                      label="No"
                      id="recommended"
                      name="recommended"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <RadioButton
                      label="Not Sure"
                      id="recommended"
                      name="recommended"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </FormUi.Group>
                  {errors.recommended && touched.yearly_salary && (
                    <Message negative>{errors.recommended}</Message>
                  )}
                </div>
                <TextArea
                  type="textarea"
                  label="Branch review"
                  id="review"
                  name="review"
                  onBlur={handleBlur}
                  value={values.review}
                />
                <div>
                  <button
                    type="submit"
                    className={reviewStyle.outlinedButton}
                    disabled={isSubmitting}
                  >
                    SUBMIT
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}
export default withRouter(PharmacistReview)

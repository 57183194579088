import React from 'react'
import * as d3 from 'd3'
import * as topojson from 'topojson-client'
import UkMap from '../UkMap/UkMap'
import Box from '@mui/material/Box'
import {Typography} from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'

export default function MapContainer(props) {
  const {pharmacyData} = props
  const [uk, setUk] = React.useState(null)
  const [ukMap, setUkMap] = React.useState(null)
  const [loaded, setLoaded] = React.useState(false)
  const [mergedData, setMergedData] = React.useState([])
  React.useEffect(() => {
    // Load the UK map data and convert it to GeoJSON format
    d3.json('/map.json').then(data => {
      const cities = topojson.feature(data, data.objects.GBR_adm2)
      setUk(cities)
    })
  }, [])

  React.useEffect(() => {
    if (pharmacyData && uk) {
      const dataByRegion = d3.rollup(
        pharmacyData.filter(d => d.region_upper),
        v => d3.mean(v, d => d.average_locum_rate),
        d => {
          if (d.region_upper === 'NAN') {
            return ''
          } else {
            return d.region_upper
          }
        },
      )
      const mergedFeatures = uk.features.map(feature => {
        const region = feature.properties.NAME_2.toUpperCase()
        return {
          ...feature,
          properties: {
            ...feature.properties,
            average_locum_rate: dataByRegion.get(region) || 0,
          },
        }
      })

      const mergedData = {
        ...uk,
        features: mergedFeatures,
      }
      setUkMap(mergedData)
      setMergedData(mergedFeatures)
      setLoaded(true)
    }
  }, [pharmacyData, uk])

  return (
    <Box sx={{overflowY: 'hidden'}}>
      <Typography variant="h6" align="center">
        UK Average Locum Pharmacy Rate <br />
        <CircleIcon sx={{color: 'red'}} /> LIVE
      </Typography>
      {loaded && (
        <UkMap uk={ukMap} pharmacyData={pharmacyData} mapData={mergedData} />
      )}
    </Box>
  )
}

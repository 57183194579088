import {createTheme} from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#E94A6B',
      light: '#ff7e99',
      dark: '#b10041',
      contrastText: '#fff',
      lightBackground: '#F4F4F4',
      mainBackground: '#ffffff',
    },
    secondary: {
      main: '#870d4d',
      light: '#bb4679',
      dark: '#550025',
      contrastText: '#ffffff',
      textMain: '#666666',
      textDark: '#333333',
      textLight: '#999999',
    },
    greyBackground: {
      main: '#F4F4F4',
      light: '#FBFAFD',
    },
  },
})

export default theme

import React from 'react'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import Image from 'mui-image'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'

export default function FeedPostImage(props) {
  const [open, setOpen] = React.useState(false)
  const [activeImage, setActiveImage] = React.useState(null)

  const {
    item: {image},
  } = props
  const handleClose = () => setOpen(false)

  return (
    <>
      <ImageList sx={{width: '100%', height: 'auto'}} cols={1}>
        {image.map(item => (
          <ImageListItem key={item.id}>
            <Image
              src={item.image}
              alt={'feed post image'}
              loading="lazy"
              onClick={() => {
                setActiveImage(item.image)
                setOpen(true)
              }}
              sx={{
                borderRadius: 2,
                cursor: 'pointer',
                width: '100%',
                height: Math.round((item.height * 650) / item.width),
              }}
            />
          </ImageListItem>
        ))}
      </ImageList>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'black',
            border: 'none',
            p: 1,
          }}
        >
          <Image
            src={activeImage}
            alt={'feed post image'}
            sx={{maxHeight: '95vh', maxWidth: '95vw', minWidth: '75vw'}}
          />
        </Box>
      </Modal>
    </>
  )
}

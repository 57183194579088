import React from 'react'
import Box from '@mui/material/Box'
import {Typography} from '@mui/material'
import ReviewSummary from './review-summary/ReviewSummary'
import ReviewAccordion from './reviews/ReviewAccordion'
import AddReview from './reviews/AddReview'

export default function ProfessionalReviews(props) {
  const {pharmacy} = props
  return (
    <>
      <Typography variant="h6">Professional Reviews</Typography>
      <ReviewSummary pharmacy={pharmacy} />
      <Box sx={{maxWidth: 500, my: 4}}>
        <ReviewAccordion pharmacy={pharmacy} />
      </Box>
      <Box sx={{maxWidth: 500, my: 4}}>
        <AddReview pharmacy={pharmacy} />
      </Box>
    </>
  )
}

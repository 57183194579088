import React from 'react'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'

function DataList(props) {
  const {pharmacyData} = props
  const listOfPharmacies = pharmacyData?.map((item, index) => {
    return (
      <ListItem key={index} component="div" disablePadding>
        <ListItemButton>
          <ListItemText
            primary={`${item.region_upper}`}
            secondary={`Average Locum Rate: £${item.average_locum_rate} | Reviewed pharmacies: ${item.num_pharmacies}`}
          />
        </ListItemButton>
      </ListItem>
    )
  })
  return <>{listOfPharmacies}</>
}

export default DataList

import React from 'react'
import Review from './components/Review'

export default function EmployedReviews(props) {
  const {pharmacy} = props

  const EmployedReviews = pharmacy.reviews.employed.map((review, index) => {
    return <Review key={index} review={review} reviewType={'Employed'} />
  })
  return <>{pharmacy.reviews.employed.length > 0 ? EmployedReviews : null}</>
}

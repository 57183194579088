import React from 'react'
import {Box, Typography} from '@mui/material'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import SecondarySpan from 'components/layout/common/typography/SecondarySpan'

export default function AddReview(props) {
  const {pharmacy} = props
  return (
    <Box
      sx={{
        display: 'flex',
        justifyItems: 'center',
        gap: 1,
        flexWrap: 'nowrap',
        width: '100%',
        flexDirection: {md: 'row', xs: 'column'},
      }}
    >
      <a href={`/page/pharmacy/${pharmacy.slug}/create-review/`}>
        <Button variant="contained" endIcon={<AddIcon />} sx={{color: '#fff'}}>
          Add a review
        </Button>
      </a>
      <Typography variant="caption" display="block">
        <SecondarySpan>
          Share your experience with other locums & employees
        </SecondarySpan>
        <br />
        It only takes 30 seconds
      </Typography>
    </Box>
  )
}

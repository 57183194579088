import React from 'react'
import {Box, Typography} from '@mui/material'
import Api from 'services/api.service'
import AutoCompleteSearchItem from './AutoCompleteSearchItem'

export default function AutoCompleOptions(props) {
  const {searchQuery} = props
  const [results, setResults] = React.useState([])

  const searchPharmacies = (q = '') => {
    Api.get(`api/business/pharmacy/search/?q=${searchQuery}`)
      .then(response => {
        setResults(response.data.results)
      })
      .catch(error => {
        console.log(error)
      })
  }

  React.useEffect(() => {
    searchPharmacies(searchQuery)
  }, [searchQuery])

  const searchResults = results.reverse().map(result => {
    return <AutoCompleteSearchItem key={result.id} result={result} />
  })
  return (
    <Box
      sx={{
        position: 'absolute',
        top: '56px',
        left: '50%',
        transform: 'translate(-50%, 0)',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 200,
        backgroundColor: 'white',
        minHeight: '200px',
        minWidth: '200px',
        maxWidth: '500px',
        width: '100%',
        boxShadow:
          '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
      }}
    >
      {searchResults}
    </Box>
  )
}

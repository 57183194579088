import {NavLink} from 'react-router-dom'
import AccountCircle from '@mui/icons-material/AccountCircle'
import Badge from '@mui/material/Badge'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import IconButton from '@mui/material/IconButton'
import NotificationsIcon from '@mui/icons-material/Notifications'
import ProfileMenuIcon from '../ProfileMenuIcon'
import ExitToAppTwoToneIcon from '@mui/icons-material/ExitToAppTwoTone'

let mobileMenuLink = {
  textDecoration: 'none',
  color: 'black',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

export default function MobileDropdownMenu(props) {
  const {
    mobileMoreAnchorEl,
    handleMobileMenuClose,
    mobileMenuId,
    isMobileMenuOpen,
    UnreadNotificationsCount,
    user,
    handleProfileMenuOpen,
    logout,
  } = props
  return (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <NavLink to="/notifications/" style={mobileMenuLink}>
          <IconButton
            size="large"
            aria-label={`show ${UnreadNotificationsCount()} new notifications`}
            color="primary"
          >
            <Badge badgeContent={UnreadNotificationsCount()} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <p>Notifications</p>
        </NavLink>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <NavLink to="/account/" style={mobileMenuLink}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="primary"
          >
            <ProfileMenuIcon user={user} />
          </IconButton>
          <p>Profile</p>
        </NavLink>
      </MenuItem>
      <MenuItem onClick={() => logout()}>
        <IconButton
          size="large"
          aria-label="Logout"
          aria-haspopup="true"
          color="primary"
        >
          <ExitToAppTwoToneIcon />
        </IconButton>
        Logout
      </MenuItem>
    </Menu>
  )
}

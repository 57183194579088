import React from 'react'
import {withRouter} from 'react-router-dom'
import Lottie from 'lottie-react'

import reviewStyle from './Review.module.css'
import ReviewAnimation from 'assets/lottie/review.json'

function CreatePharmacyReview(props) {
  const {slug} = props.match.params
  return (
    <div className="main">
      <div className={reviewStyle.pageContainer}>
        <div className={reviewStyle.contentContainer}>
          {/* <IconMenu width="400px" /> */}
          <Lottie
            animationData={ReviewAnimation}
            className={reviewStyle.ReviewAnimation}
            loop={true}
          />
          <div className={reviewStyle.optionSlectContainer}>
            <div className={reviewStyle.title}>
              <h2>
                First select your role as professional when you worked here.
              </h2>
            </div>
            <a href={`/page/pharmacy/${slug}/create-review/pharmacist/`}>
              <button className={reviewStyle.optionSelect}>
                Employed Pharmacist
              </button>
            </a>
            <a href={`/page/pharmacy/${slug}/create-review/locum-pharmacist/`}>
              <button className={reviewStyle.optionSelect}>
                Locum Pharmacist
              </button>
            </a>
            <a>
              <button className={reviewStyle.optionSelect} disabled>
                Pre-Reg Pharmacist
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
export default withRouter(CreatePharmacyReview)

import {Typography, Box, Link} from '@mui/material'
import NotificationCss from '../notification.module.css'
import Avatar from '@mui/material/Avatar'

function QuestionAnswered(props) {
  const {notification} = props
  const data = notification?.data?.data
  const userSlug = data.user?.slug || false
  const postUrl = userSlug ? `/account/${userSlug}` : '#'

  const notificationText = (
    <Box
      sx={{
        display: 'flex',
        flexDirecton: 'column',
        width: '100%',
        flexFlow: 'column wrap',
      }}
    >
      <Typography sx={{pr: 0.6}}>
        <Link href={postUrl} underline="none">
          {data.user?.full_name}{' '}
        </Link>
        {notification.verb}
      </Typography>
      <Link href={`/clinical-questions/${data.question.id}/`} underline="none">
        <Box
          sx={{
            p: 2,
            backgroundColor: 'primary.main',
            border: '1px solid red',
            borderRadius: 5,
            my: 2,
          }}
        >
          <Typography variant="caption" sx={{color: '#fff !important'}}>
            {data.question.question}
          </Typography>
        </Box>
      </Link>
      <Typography variant="caption" color="secondary">
        {notification.time_ago}
      </Typography>
    </Box>
  )

  const profilePicture = (
    <Avatar
      alt={data.user.full_name || ''}
      src={data.user.profile_image || ''}
    />
  )

  return (
    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
      <Box sx={{display: 'flex', gap: 2, flexGrow: 4}}>
        {profilePicture}
        {notificationText}
      </Box>
      <Box>
        {notification.unread && (
          <div className={NotificationCss.unreadIndicator}></div>
        )}
      </Box>
    </Box>
  )
}

export default QuestionAnswered

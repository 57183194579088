import React from 'react'
import CustomCss from '../feed.module.css'

export default function PreviewImage(props) {
  const {files, removeAllImage} = props
  const filesRendered = files.map((file, index) => {
    return <img src={file} key={index} alt="user-post" />
  })
  return (
    <div className={CustomCss.NewPostPreviewImages}>
      {filesRendered}
      <span onClick={() => removeAllImage()}>X</span>
    </div>
  )
}

import React from 'react'
import {Box, Container, Typography, Avatar} from '@mui/material'
import StarRating from 'components/layout/common/starrating/StarRating'

export default function AgencyProfile(props) {
  const {agency} = props

  const roundedAvgRating =
    agency.stats.average_overall_rating.rounded_avg_overal_rating
  const AvgRatingCount =
    agency.stats.average_overall_rating.overall_rating_count
  //   const percentageHighestRate =
  //     agency.stats.gets_the_highest_rate.perc_highest_rate_yes
  //   const highestRateTotalCount = agency.stats.gets_the_highest_rate.total_count

  const starRating = (
    <Box sx={styles.starRatingContainer}>
      {roundedAvgRating}{' '}
      <StarRating
        score={roundedAvgRating}
        sx={{fontSize: {md: '1.5 rem', xs: '1.1rem'}}}
      />
      {AvgRatingCount} reviews
    </Box>
  )
  return (
    <Container maxWidth="xxl" sx={styles.ParentContainer}>
      <Container>
        <Box sx={styles.NameContainer}>
          <Avatar src={agency.logo} alt={agency.name} sx={styles.Avatar} />
          <Box>
            <Typography variant="h5">{agency.name}</Typography>
            {starRating}
          </Box>
        </Box>
      </Container>
    </Container>
  )
}

const styles = {
  ParentContainer: {
    bgcolor: 'grey.100',
    minHeight: '200px',
  },
  NameContainer: {
    display: 'flex',
    gap: 2,
    mt: 5,
    alignItems: 'center',
  },
  Avatar: {
    border: '2px solid',
    borderColor: 'secondary.light',
    width: '80px',
    height: '80px',
  },
  starRatingContainer: {
    display: 'flex',
    gap: 1,
    mt: 1,
    alignItems: 'center',
    flexWrap: 'wrap',
  },
}

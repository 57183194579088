import React from 'react'
import Box from '@mui/material/Box'
import {Typography} from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

function mapOrder(array, order, key) {
  array.sort(function (a, b) {
    var A = a[key],
      B = b[key]

    if (order.indexOf(A) > order.indexOf(B)) {
      return 1
    } else {
      return -1
    }
  })

  return array
}

export default function OpeningHours(props) {
  const [todayOpeningHours, setTodayOpeningHours] = React.useState({
    weekday: 'Monday',
    opens: '00:00:00',
    close: '00:00:00',
  })
  const {pharmacy} = props
  const item_order = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ]
  const ordered_array = mapOrder(pharmacy.opening_hours, item_order, 'weekday')

  React.useEffect(() => {
    pharmacy.opening_hours.map(day => {
      var days = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ]
      var d = new Date()
      var dayName = days[d.getDay()]
      if (dayName === day.weekday) {
        setTodayOpeningHours(day)
      }
    })
  }, [])

  function formatTime(time) {
    if (time != null) {
      return time.slice(0, 5)
    }
    return '---'
  }

  const openingTimes = ordered_array.map(day => (
    <div key={day.id}>
      {day.is_open && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            my: 1,
          }}
        >
          <Typography>
            {day.weekday === todayOpeningHours.weekday ? (
              <strong>{day.weekday}</strong>
            ) : (
              day.weekday
            )}
          </Typography>
          <Typography sx={{color: 'text.secondary'}}>
            {formatTime(day.opens)} - {formatTime(day.close)}
          </Typography>
        </Box>
      )}
    </div>
  ))
  return (
    <Box>
      <Accordion sx={{mt: 4}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Typography>{todayOpeningHours.weekday}</Typography>
            <Typography sx={{color: 'text.secondary'}}>
              {formatTime(todayOpeningHours.opens)} --
              {formatTime(todayOpeningHours.close)}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>{openingTimes}</AccordionDetails>
      </Accordion>
    </Box>
  )
}

import React from 'react'
import Loadable from '@loadable/component'

import Loading from '../../components/base/ComponentLoading'

const AccountComponent = Loadable(() => import('./Account'))

function Account() {
  return <AccountComponent fallback={<Loading />} />
}

export default Account

import React from 'react'
import Api from 'services/api.service'
import {Typography, Box, Button, Card, CardContent} from '@mui/material'
import FollowingSuggestedItem from './FollowingSuggestedItem'

export default function FollowingSuggestion() {
  const [suggested, setSuggested] = React.useState([])
  const [limit, setLimit] = React.useState(5)

  React.useEffect(() => {
    Api.get('/user/recommended/')
      .then(response => {
        setSuggested(response.data)
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  const ListDiv = suggested?.results?.slice(0, limit).map((item, index) => {
    return <FollowingSuggestedItem item={item} key={index} />
  })

  const handleClick = () => {
    if (limit === 20) {
      setLimit(5)
    } else {
      setLimit(20)
    }
  }
  return (
    <Card variant="outlined">
      <CardContent>
        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography variant="body1">Who to follow</Typography>
          <Button
            size="small"
            color="secondary"
            sx={{textTransform: 'none'}}
            onClick={handleClick}
          >
            {limit === 5 ? 'See All' : 'Collapse'}
          </Button>
        </Box>
        {ListDiv}
      </CardContent>
    </Card>
  )
}

import React from 'react'
import Box from '@mui/material/box'
import SecondarySpan from 'components/layout/common/typography/SecondarySpan'
import PaddedTypography from 'components/layout/common/typography/PaddedTypography'

export default function EmployeeOverallRating(props) {
  const {pharmacy} = props
  const locum_is_busy = () => {
    if (
      pharmacy.review_stats.locum.average_workload_rating.workload_rating__avg >
      5
    ) {
      return true
    }
  }
  return (
    <>
      <Box
        sx={{display: 'flex', width: '100%', justifyContent: 'space-between'}}
      >
        <PaddedTypography variant="body1">
          Employee overall rating:
        </PaddedTypography>
        <PaddedTypography variant="body1">
          <SecondarySpan>
            {pharmacy.review_stats.employed.average_workload_rating
              .workload_rating__avg ? (
              <>
                {pharmacy.review_stats.employed.average_workload_rating.workload_rating__avg.toFixed(
                  1,
                )}
                /10
                {locum_is_busy() ? (
                  <span style={{color: 'red', fontSize: '12px'}}> Busy</span>
                ) : (
                  <span style={{color: 'green', fontSize: '12px'}}> Quiet</span>
                )}
              </>
            ) : (
              'No reviews'
            )}
          </SecondarySpan>
        </PaddedTypography>
      </Box>
    </>
  )
}

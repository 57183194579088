import React from 'react'
// import DashboardCard from 'components/datainsight/dashboard/layout/Card/DashboardCard'
import DashboardLayout from 'components/datainsight/dashboard/layout/DashboardLayout'

export default function Dashboard() {
  return (
    <div className="main">
      <div className="main__content">
        <DashboardLayout />
      </div>
    </div>
  )
}

import React from 'react'
import {AuthContext} from 'context/context'
import AddClinicalQuestionForm from 'components/clinicalQuestions/form/AddClinicalQuestionForm'

export default function AddClinicalQuestionPage() {
  const {user} = React.useContext(AuthContext)
  return (
    <div className="main">
      <div className="main__content">
        <AddClinicalQuestionForm />
      </div>
    </div>
  )
}

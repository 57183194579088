import * as React from 'react'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Hashtags from './Hashtags'
import {styled} from '@mui/material/styles'
import AvatarChips from './AvatarChip'
import _ from 'lodash'

const QuestionTypography = styled(Typography)({
  fontFamily: 'Roboto',
  textAlign: 'center',
})

export default function Question(props) {
  // set a default for shortText in props

  const {question, shortText = false} = props

  const calculateFontSize = () => {
    const sentenceLength = question.question.length
    const minFontSize = 11.5
    const maxFontSize = 31
    const maxLength = 250 // Maximum length of the sentence

    // Calculate the font size based on the length of the sentence
    let fontSize =
      maxFontSize - ((maxFontSize - minFontSize) * sentenceLength) / maxLength

    // Ensure that the fontSize does not go below the minFontSize
    fontSize = Math.max(minFontSize, fontSize)
    return Math.round(fontSize)
  }

  const shorten = text => {
    const limit = 310
    if (text.length <= limit) {
      return text
    } else {
      const shortenedText = _.truncate(text, {
        length: limit,
        separator: ' ',
      })
      return `${shortenedText} Read more`
    }
  }

  const card = (
    <React.Fragment>
      <CardContent sx={styles.cardContent}>
        <QuestionTypography
          sx={{
            fontSize: calculateFontSize(),
            textOverflow: 'ellipsis',
            whiteSpace: 'pre-wrap',
            fontFamily: 'Roboto',
            textAlign: 'center',
          }}
        >
          {shortText ? shorten(question.question) : question.question}
        </QuestionTypography>
        <QuestionTypography sx={{mb: 1.5, fontSize: 14}} color="text.secondary">
          {question.time_ago}
        </QuestionTypography>
      </CardContent>

      <CardActions sx={styles.cardActions}>
        {!shortText && <Hashtags hashtags={question.hashtags} />}
        <AvatarChips user={question.user} />
      </CardActions>
    </React.Fragment>
  )

  return card
}

const styles = {
  cardContent: {
    flex: '1 0 auto',
    p: 0,
  },
  cardActions: {
    flex: '0 0 auto',
    flexDirection: 'column',
    gap: 2,
  },
}

import React from 'react'
import ShareIcon from '@mui/icons-material/Share'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import ShareForm from './ShareForm'

const styles = {
  container: {
    color: '#888da8',
    my: 0,
    py: 0,
    textTransform: 'none',
    fontSize: 11,
  },
  shareIcon: {
    color: '#888da8',
    fontSize: '1.1rem',
    mr: 1,
  },
  modalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
      xs: '97vw',
      sm: '80vw',
      md: '70vw',
      lg: '600px',
    },
    bgcolor: 'background.paper',
    borderRadius: '25px',
    boxShadow: 24,
    p: 2,
    overflow: 'scroll',
    height: 'auto',
  },
}

export default function ShareButton(props) {
  const {item} = props
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  return (
    <>
      <Button sx={styles.container} onClick={handleOpen}>
        <ShareIcon sx={styles.shareIcon} />
        {item.shared_count >= 1 ? `${item.shared_count}` : 'Share'}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.modalBox}>
          <IconButton onClick={() => setOpen(false)} size="small" sx={{mb: 2}}>
            <CloseIcon />
          </IconButton>
          <ShareForm post={item} setOpen={setOpen} />
        </Box>
      </Modal>
    </>
  )
}

export const alignCenterStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: 1,
}

export const flexSpaceBetween = {
  display: 'flex',
  justifyContent: 'space-between',
  mb: 3,
  gap: 1,
  mx: 'auto',
  width: '100%',
}

export const DisplayFlexColumn = {
  display: 'flex',
  flexDirection: 'column',
  my: 1,
  mx: 'auto',
  gap: 2,
  maxWidth: '600px',
}

import {useContext, useState} from 'react'
import * as _ from 'lodash'

import {AuthContext} from '../../context/context'
import Api from '../../services/api.service'
import {apiUrl} from '../../config/vars'
import PostDropDown from './feedItemChidren/PostDropDown'
import SharedLink from './feedItemChidren/SharedLink'
import FeedCss from './feed.module.css'
import CommentList from './feedItemChidren/comments/CommentList'
import FeedPostFooter from './feedItemChidren/FeedPostFooter'
import FeedPostImages from './feedItemChidren/FeedPostImages'
import FeedPostSharingItem from './feedItemChidren/FeedPostSharingItem'
import FeedPostUserAvatar from './feedItemChidren/FeedPostUserAvatar'
import ShortenCaption from './feedItemChidren/ShortenCaption'

function FeedItem(props) {
  const {user} = useContext(AuthContext)
  const [item, setItem] = useState(props.item)
  const [deleted, setDeleted] = useState(false)
  const [showComments, setShowComments] = useState(false)

  const deleteItem = () => {
    Api.delete(`${apiUrl}api/news-feed/post/delete/${item.id}/`)
      .then(res => {
        setDeleted(true)
        setItem([])
      })
      .catch(error => {
        console.log(error)
      })
  }

  return (
    <>
      {deleted ? (
        <div className="feed__post_deleted">
          <div>This post has been deleted</div>
        </div>
      ) : (
        <div key={item.id} className={FeedCss.FeedItemCard}>
          <div className={FeedCss.FeedItemContainer}>
            {!showComments ? (
              <>
                <div className={FeedCss.FeedItemHeader}>
                  <FeedPostUserAvatar item={item} />
                  <div className={FeedCss.FeedItemDropdown}>
                    {item.user.id === user.id ? (
                      <PostDropDown deleteItem={deleteItem} />
                    ) : null}
                  </div>
                </div>
                <div className={FeedCss.FeedBody}>
                  <div
                    style={{whiteSpace: 'pre-line'}}
                    className={FeedCss.FeedPostText}
                  >
                    <ShortenCaption fullCaption={item.caption} />
                  </div>

                  {item.image.length > 0 ? (
                    <FeedPostImages item={item} />
                  ) : null}
                  {item.is_sharing && <FeedPostSharingItem item={item} />}
                  {item.shared_link ? <SharedLink item={item} /> : null}
                  <FeedPostFooter
                    item={item}
                    user={user}
                    showComments={showComments}
                    setShowComments={setShowComments}
                  />
                </div>
              </>
            ) : (
              <CommentList
                item={item}
                user={user}
                setShowComments={setShowComments}
              />
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default FeedItem

import React from 'react'
import AgencySingleReview from './AgencySingleReview'
import {Box, Button, Container, Grid} from '@mui/material'
import ReviewModal from './ReviewModal'

export default function ReviewDisplay(props) {
  const {agency} = props
  const [shownCount, setShownCount] = React.useState(3)
  const [showLoadMore, setShowLoadMore] = React.useState(true)

  const reviews = agency.reviews.slice(0, shownCount).map((review, index) => {
    return <AgencySingleReview key={index} review={review} />
  })

  const handleLoadMore = () => {
    setShownCount(shownCount + 3)
    if (shownCount >= agency.reviews.length) {
      setShowLoadMore(false)
    }
  }
  const LoadMoreButton = (
    <>
      {shownCount < agency.reviews.length && showLoadMore ? (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <Button variant="outlined" onClick={handleLoadMore}>
            Load More
          </Button>
        </Box>
      ) : null}
    </>
  )

  return (
    <Container>
      <Grid container spacing={4}>
        <Grid item md={6} sm={12} xs={12}>
          <Box sx={{my: 4}}>
            {reviews}
            {LoadMoreButton}
          </Box>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <Box
            sx={{
              width: '100%',
              height: '500px',
              position: 'relative',
            }}
          >
            <ReviewModal agency={agency} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

import React from 'react'
import {withRouter} from 'react-router-dom'
import {fetchSingleAgency} from 'services/calls/AgencyAPI'
import AgencyProfile from '../profile/AgencyProfile'
import ReviewDisplay from '../reviews/container/ReviewDisplay'
import Loading from 'components/layout/common/loading/Loading'

function AgencyPageLayout(props) {
  const {slug} = props.match.params
  const [agency, setAgency] = React.useState([])
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    fetchSingleAgency(slug)
      .then(res => {
        setAgency(res.data)
        setLoading(false)
      })
      .catch(err => console.log(err))
  }, [slug])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <AgencyProfile agency={agency} />
          <ReviewDisplay agency={agency} />
        </>
      )}
    </>
  )
}

export default withRouter(AgencyPageLayout)

import React from 'react'
import {Form, Button} from 'semantic-ui-react'
import {Formik} from 'formik'
import {useToasts} from 'react-toast-notifications'
import {apiUrl} from '../../../config/vars'
import Api from '../../../services/api.service'

export default function ReplyToThread({message}) {
  const {addToast} = useToasts()
  console.log(message)
  const sendMessage = (values, actions) => {
    const data = {...values}
    data.recipient = message.sender.id
    data.parent_id = message.id
    data.subject = 'Re: ' + message.subject
    console.log(data)
    Api.post(`${apiUrl}api/messages/reply/`, data)
      .then(() => {
        actions.setSubmitting(false)
        actions.resetForm()

        addToast('Message was sent successfully.', {
          type: 'success',
          autoDismiss: true,
        })
        window.location = '/messages/'
      })
      .catch(error => {
        actions.setSubmitting(false)
        console.log(error)
        addToast('something went wrong', {
          type: 'danger',
          autoDismiss: true,
        })
      })
  }
  return (
    <Formik
      initialValues={{
        body: '',
      }}
      onSubmit={(values, actions) => {
        sendMessage(values, actions)
      }}
    >
      {props => {
        const {
          values,
          errors,
          touched,
          handleChange,
          isSubmitting,
          handleSubmit,
        } = props
        return (
          <Form onSubmit={handleSubmit} className="mt-20">
            <h5>
              <small>To: </small>
              {message.sender && message.sender.full_name} <br />
              <small>Subject: </small>Re: {message.subject}
            </h5>
            <Form.TextArea
              name="body"
              id="body"
              component="textarea"
              label="Message"
              value={values.body}
              line={10}
              required={true}
              onChange={handleChange}
              error={touched.body && errors.body}
            />
            <Button
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              Reply
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}

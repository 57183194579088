import React, {useState, useEffect} from 'react'
import BranchDetail from '../../components/pharmacies/branch/BranchDetail'
import Container from '@mui/material/Container'
import {withRouter} from 'react-router-dom'
import Api from '../../services/api.service'
import CustomCss from './Pharmacy.module.css'
import ProfessionalReviews from '../../components/pharmacies/branch/ProfessionalReviews'
import ServicesAndFacilities from '../../components/pharmacies/branch/ServicesAndFacilities'
import Grid from '@mui/material/Grid'
import PharmacyMap from '../../components/pharmacies/branch/location/PharmacyMap'
import DispensingData from 'components/pharmacies/branch/dispensing-data/DispensingData'

function PharmacyPage(props) {
  const {slug} = props.match.params
  const [message, setMessage] = useState(null)
  const [pharmacy, setPharmacy] = useState()
  const [loading, setLoading] = useState(true)

  const getPharmacy = () => {
    Api.get(`api/business/pharmacy/list/${slug}/`)
      .then(response => {
        setPharmacy(response.data)
        setLoading(false)
      })
      .catch(err => {
        console.error(err)
      })
  }

  useEffect(() => {
    getPharmacy()
  }, [])

  return (
    <div className="main">
      <div className="main__content">
        {pharmacy ? (
          <>
            <Container
              maxWidth="xxl"
              sx={theme => ({
                bgcolor: 'grey.100',
              })}
            >
              <BranchDetail branch={pharmacy} loading={loading} />
            </Container>
            <Container sx={{mt: 7}}>
              <Grid container>
                <Grid xs={12} md={6} item={true}>
                  <ProfessionalReviews pharmacy={pharmacy} />
                  <DispensingData dispensingData={pharmacy.dispensing_data} />
                </Grid>
                {pharmacy.services.length > 0 && (
                  <Grid xs={12} md={6} item={true}>
                    <ServicesAndFacilities pharmacy={pharmacy} />
                  </Grid>
                )}
              </Grid>
            </Container>
            <Container
              maxWidth="xxl"
              sx={theme => ({
                bgcolor: 'grey.100',
                my: 5,
              })}
              id={'map'}
            >
              <Container>
                <Grid container>
                  <Grid xs={12} md={6} item={true}>
                    {!loading && Boolean(pharmacy.lat) && (
                      <PharmacyMap pharmacy={pharmacy} />
                    )}
                  </Grid>
                </Grid>
              </Container>
            </Container>
          </>
        ) : (
          <div className={CustomCss.notFound}>
            <h1>{message}</h1>
          </div>
        )}
      </div>
    </div>
  )
}

export default withRouter(PharmacyPage)

import React from 'react'
import {Box} from '@mui/material'

export default function HotelBookingMap(props) {
  const {pharmacy} = props

  React.useEffect(() => {
    // Load Booking.com script
    const script = document.createElement('script')
    script.src = 'https://www.booking.com/affiliate/prelanding_sdk'
    script.defer = true
    document.head.appendChild(script)

    script.onload = () => {
      console.log('script is loaded')
      // Check if Booking is defined before using it
      if (window.Booking && pharmacy) {
        // Initialize Booking.com Affiliate Widget after the script is loaded
        const BookingAffiliateWidget = new window.Booking.AffiliateWidget({
          iframeSettings: {
            selector:
              'bookingAffiliateWidget_e673bfaf-83ef-493e-93a6-1ca8c1f51469',
            responsive: true,
          },
          widgetSettings: {
            ss: `${pharmacy.post_code}`,
            latitude: pharmacy.lat || 51.507393,
            longitude: pharmacy.lon || -0.127634,
            zoom: 13,
          },
        })
      } else {
        console.error('Booking.com script not loaded correctly.')
      }
    }
  }, [pharmacy])
  return (
    <Box sx={{maxWidth: '500px'}}>
      <div id="bookingAffiliateWidget_e673bfaf-83ef-493e-93a6-1ca8c1f51469">
        &nbsp;
      </div>
    </Box>
  )
}

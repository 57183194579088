import React from 'react'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'

export default function BranchName(props) {
  const {pharmacy} = props
  return (
    <Box
      sx={{
        display: 'flex',
        mt: 3,
        gap: 2,
        alignItems: 'center',
      }}
    >
      <Avatar
        src={pharmacy.company.logo}
        alt={pharmacy.company.name}
        sx={{
          border: '2px solid',
          borderColor: 'secondary.light',
          width: '80px',
          height: '80px',
        }}
      />
      <div>
        <Typography variant="h5" gutterBottom>
          {pharmacy.trading_name}
        </Typography>
        <Box sx={{mb: 2}}>
          <Chip label={pharmacy.premises_type} variant="outlined" />
        </Box>
      </div>
    </Box>
  )
}

import React, {useContext} from 'react'
import {Redirect, Route} from 'react-router-dom'
import Helmet from 'react-helmet'
import Layout from '../layout/Layout'
import {AuthContext} from '../../context/context'

const AppRoutes = ({
  component: Component,
  path,
  isPrivate,
  navbarMode,
  ...rest
}) => {
  const {auth} = useContext(AuthContext)

  return (
    <>
      <Helmet>
        <title>{rest.title ? `${rest.title} - Syrkle` : 'Syrkle'}</title>
      </Helmet>

      <Route
        path={path}
        render={props => {
          if (isPrivate && !auth.accessToken) {
            return <Redirect to={'/home'} />
          }

          return (
            <Layout component={Component} navbarMode={navbarMode} {...props} />
          )
        }}
        {...rest}
      />
    </>
  )
}

export default AppRoutes

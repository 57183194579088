import React from 'react'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import AddNewAttribute from './AddNewAttribute'
import SecondarySpan from 'components/layout/common/typography/SecondarySpan'
import PaddedTypography from 'components/layout/common/typography/PaddedTypography'
import LinkToMap from './components/LinkToMap'
import FullAddress from './components/FullAddress'

export default function ContactDetails(props) {
  const {pharmacy} = props

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', my: 4, maxWidth: 550}}>
      <PaddedTypography variant="body1" gutterBottom>
        Company:{' '}
        <SecondarySpan>
          {pharmacy.company && pharmacy.company.name}
        </SecondarySpan>
        {pharmacy.previous_company && (
          <>
            <br />
            Previously:{' '}
            <SecondarySpan>{pharmacy.previous_company.name}</SecondarySpan>{' '}
          </>
        )}
      </PaddedTypography>
      {(pharmacy.gphc_registration_number || pharmacy.fcode) && (
        <>
          <Divider />
          <PaddedTypography variant="body1" gutterBottom>
            {pharmacy.gphc_registration_number && (
              <>
                GPHC Number:{' '}
                <SecondarySpan>
                  {pharmacy.gphc_registration_number}
                </SecondarySpan>
              </>
            )}
            {pharmacy.fcode && (
              <>
                <span style={{marginLeft: '20px'}}>ODS number:</span>{' '}
                <SecondarySpan>{pharmacy.fcode}</SecondarySpan>
              </>
            )}
          </PaddedTypography>
        </>
      )}

      <Divider />
      <PaddedTypography
        variant="body1"
        gutterBottom
        sx={{display: 'flex', flexWrap: 'wrap', justifyItems: 'center'}}
      >
        Address:{' '}
        <SecondarySpan sx={{display: 'flex', flexWrap: 'wrap'}}>
          <FullAddress pharmacy={pharmacy} />
          {Boolean(pharmacy.lat) && <LinkToMap path={'#map'} />}
        </SecondarySpan>
      </PaddedTypography>
      <Divider />
      <PaddedTypography variant="body1" gutterBottom>
        Contact Number:{' '}
        <SecondarySpan>
          {pharmacy.branch_number || (
            <AddNewAttribute type="phone number" pharmacy={pharmacy} />
          )}
        </SecondarySpan>
      </PaddedTypography>
      <Divider />
      <PaddedTypography variant="body1" gutterBottom>
        Email Address:{' '}
        <SecondarySpan>
          {pharmacy.branch_email || (
            <AddNewAttribute type="Email address" pharmacy={pharmacy} />
          )}
        </SecondarySpan>
      </PaddedTypography>
      <Divider />
      <PaddedTypography variant="body1" gutterBottom>
        System:{' '}
        <SecondarySpan>
          {pharmacy.system || (
            <AddNewAttribute type="System Type" pharmacy={pharmacy} />
          )}
        </SecondarySpan>
      </PaddedTypography>
    </Box>
  )
}

import {Container} from '@mui/system'
import {AuthContext} from '../../context/context'
import {useContext} from 'react'
import {Typography, Box, Button} from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ProfilePictureUpload from '../../components/account/profile/profile-edit/ProfilePictureUpload'
import ProfileInfoUpdate from '../../components/account/profile/profile-edit/ProfileInfoUpdate'
import {NavLink} from 'react-router-dom'

export default function ProfileEdit() {
  const {user} = useContext(AuthContext)
  return (
    <div className="main">
      <div className="main__content">
        <Box
          sx={theme => {
            return {
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: theme.palette.grey[200],
              m: 0,
              p: 2,
            }
          }}
        >
          <NavLink to="/account/">
            <Button>
              <ArrowBackIosIcon />
            </Button>
          </NavLink>
          <Typography variant="h6">Edit Profile</Typography>
        </Box>
        <Container className="mt-20 profile__edit_image_container">
          {user.profile ? (
            <div>
              <ProfilePictureUpload user={user} />
              <ProfileInfoUpdate user={user} />
            </div>
          ) : null}
        </Container>
      </div>
    </div>
  )
}

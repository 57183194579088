import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import LineChart from './LineChart/LineChart'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'

export default function DispensingData(props) {
  const {dispensingData} = props
  const [dataKey, setDataKey] = React.useState('total_prescriptions')
  const [trendLine, setTrendLine] = React.useState(false)
  return (
    <>
      <Typography variant="h6">Dispensing Data</Typography>
      <Box sx={{maxWidth: 500, my: 4}}>
        {dispensingData?.length > 1 ? (
          <>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
              <Switch onChange={() => setTrendLine(!trendLine)} />
              <Typography variant="body2">Trend Line</Typography>
            </Box>
            <LineChart
              data={dispensingData}
              dataKey={dataKey}
              trendLine={trendLine}
            />
            <Stack direction="row" spacing={2}>
              <Button
                variant="text"
                onClick={() => setDataKey('total_prescriptions')}
              >
                Total Prescription{' '}
              </Button>
              <Button
                variant="text"
                onClick={() => setDataKey('nms_interventions')}
              >
                NMS Intervention
              </Button>
              <Button variant="text" onClick={() => setDataKey('cpcs_fees')}>
                CPCS Fees
              </Button>
            </Stack>
          </>
        ) : (
          <Typography variant="body">
            Sorry there is no data available for this branch
          </Typography>
        )}
      </Box>
    </>
  )
}

import React from 'react'
import {Box, Typography, LinearProgress} from '@mui/material/'

export default function ReviewFact(props) {
  const {value, label} = props
  return (
    <Box sx={{mb: 2}}>
      <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
        <Typography variant="body1">{label}</Typography>
        <Typography variant="body1">{value}/10</Typography>
      </Box>
      <Box sx={{width: '100%', mt: 1}}>
        <LinearProgress variant="determinate" value={value * 10} />
      </Box>
    </Box>
  )
}

import React from 'react'
import {NavLink} from 'react-router-dom'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

let mobileMenuLink = {
  textDecoration: 'none',
  color: 'black',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '90%',
}

export default function DrawerItem(props) {
  const {text, children, urlPath} = props
  return (
    <ListItem disablePadding>
      <NavLink to={urlPath} style={mobileMenuLink}>
        <ListItemButton>
          <ListItemIcon>{children}</ListItemIcon>
          <ListItemText primary={text} sx={{ml: 0}} />
        </ListItemButton>
      </NavLink>
    </ListItem>
  )
}

import React from 'react'
import QuestionGrid from './QuestionGrid'
import QuestionCategories from './QuestionCategories'

export default function QuestionGridWithCategory() {
  const [activeCategory, setActiveCategory] = React.useState(null)
  return (
    <div>
      <QuestionCategories setActiveCategory={setActiveCategory} />
      <QuestionGrid activeCategory={activeCategory} />
    </div>
  )
}

import React, {createContext, useEffect, useState} from 'react'

export const AuthContext = createContext({})

const accessToken = localStorage.getItem('accessToken') || ''
const refreshToken = localStorage.getItem('refreshToken') || ''

export const AuthProvider = ({children}) => {
  const [user, setUser] = useState({})
  const [auth, setAuth] = useState({
    accessToken: accessToken,
    refreshToken: refreshToken,
  })

  const setUserData = data => {
    setUser(data ? data : {})
  }

  const setAuthData = data => {
    setAuth(data ? data : {accessToken: '', refreshToken: ''})
  }

  useEffect(() => {
    setAuth({
      accessToken: localStorage.getItem('accessToken'),
      refreshToken: localStorage.getItem('refreshToken'),
    })
  }, [])

  return (
    <AuthContext.Provider value={{user, setUserData, auth, setAuthData}}>
      {children}
    </AuthContext.Provider>
  )
}

import {useEffect, useState} from 'react'
import Api from '../../../services/api.service'
import {apiUrl} from '../../../config/vars'
import InboxMessage from './InboxMessage'
import {List} from 'semantic-ui-react'
import MessageThread from './MessageThread'

export default function Inbox() {
  const [messages, setMessages] = useState([])
  const [messagesCount, setMessagesCount] = useState(0)
  const [showInbox, setShowInbox] = useState(true)
  const [messageId, setMessageId] = useState(null)

  const getInbox = () => {
    Api.get(`${apiUrl}api/messages/inbox/`).then(response => {
      setMessages(response.data.results)
      setMessagesCount(response.data.count)
    })
  }

  const openMessageThread = id => {
    setShowInbox(false)
    setMessageId(id)
  }

  useEffect(() => {
    getInbox()
  }, [])

  const messageList = messages.map(message => {
    return (
      <InboxMessage
        message={message}
        key={message.id}
        openMessage={openMessageThread}
      />
    )
  })
  const inboxList = (
    <div>
      {messagesCount === 0 ? (
        <p>You have no messages to show</p>
      ) : (
        <List selection verticalAlign="middle">
          {messageList}
        </List>
      )}
    </div>
  )
  return (
    <div>
      {showInbox ? (
        inboxList
      ) : (
        <MessageThread messageId={messageId} showInbox={setShowInbox} />
      )}
    </div>
  )
}

import React from 'react'
import {Box, Typography, Chip} from '@mui/material'
import RandomAvatar from 'components/explore/common/RandomAvatar'
import ReviewFact from '../../../../../layout/common/progressbar/ReviewFact'

export default function Review(props) {
  const {review, reviewType} = props
  let year = 0
  if (review) {
    year = review?.date_of_booking?.split('-')[0]
  }
  return (
    <Box sx={{mb: 2}}>
      <Box sx={{display: 'flex', gap: 2}}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <RandomAvatar />
          <Typography variant="body2">{year}</Typography>
        </Box>
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h6">Anonymised</Typography>
            <Chip label={reviewType} size="small" />
          </Box>
          <Typography variant="body1">{review.review}</Typography>
          <Box sx={{mt: 2}}>
            <ReviewFact
              label={'Overall Rating:'}
              value={review.overall_rating}
            />
            <ReviewFact
              label={'Workload Rating:'}
              value={review.workload_rating}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

import {useState} from 'react'
import {Avatar, Menu, MenuItem, ListItemText, ListItemIcon} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import Api from 'services/api.service'
import {apiUrl} from '../../../../config/vars'
import FeedCss from '../../feed.module.css'

export default function CommentItem({commentItem, user}) {
  const [item, setItem] = useState(commentItem)
  const time = new Date(item.created_at).toDateString()
  const [deleted, setDeleted] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const deleteItem = () => {
    Api.delete(`${apiUrl}api/news-feed/post/comment/delete/${item.pk}/`)
      .then(res => {
        setDeleted(true)
        setItem([])
      })
      .catch(error => {
        console.log(error)
      })
  }

  return (
    <>
      {deleted ? (
        <div className="feed__post_deleted">
          <div>Deleted successfully!</div>
        </div>
      ) : (
        <>
          <div className={FeedCss.FeedItemHeader}>
            <div className={FeedCss.FeedItemUser}>
              <Avatar src={item.user.profile.profile_image} />
              <div className={FeedCss.FeedItemUserInfo}>
                <a href={`/account/${item.user.slug}`}>{item.user.full_name}</a>
                <span>
                  <div>{time === 'Invalid Date' ? 'Just Now' : time}</div>
                </span>
              </div>
            </div>
            {item.user.id === user.id ? (
              <>
                <div className={FeedCss.FeedItemDropdown}>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? 'long-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <MenuItem onClick={deleteItem}>
                      <ListItemIcon>
                        <DeleteOutlineIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Delete</ListItemText>
                    </MenuItem>
                  </Menu>
                </div>
              </>
            ) : null}
          </div>
          <div className={FeedCss.CommentBodyText}>
            <p>{item.comment}</p>
          </div>
        </>
      )}
    </>
  )
}

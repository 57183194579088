import React from 'react'
import Box from '@mui/material/Box'
import Api from 'services/api.service'
import MapContainer from '../MapContainer/MapContainer'
import DataContainer from '../DataContainer/DataContainer'
import Typography from '@mui/material/Typography'
import LinearProgress from '@mui/material/LinearProgress'
import CopyRightFooter from 'components/layout/common/footer/CopyRightFooter'
import {Card, CardContent, Grid} from '@mui/material'

export default function PharmacyLocumRateDataContainer() {
  const [PharmacyData, setPharmacyData] = React.useState([])
  const [progress, setProgress] = React.useState(0)

  React.useEffect(() => {
    const fetchData = async () => {
      let nextUrl = '/api/business/pharmacy/locum-rates-summary/'
      let allData = []
      setProgress(5)
      while (nextUrl) {
        const response = await Api.get(nextUrl)
        const data = response.data.results
        allData = [...allData, ...data]
        setProgress(oldProgress => {
          if (oldProgress === 100) {
            return 100
          }
          const diff = oldProgress + 3
          return diff
        })
        nextUrl = response.data.next
      }

      setPharmacyData(allData)
    }

    fetchData()
  }, [])

  return (
    <>
      {PharmacyData.length > 1 ? (
        <>
          <Box sx={{flexGrow: 1, px: 2}}>
            <Grid container spacing={4}>
              <Grid item lg={9} md={8} sm={12} xs={12}>
                <Card variant="outlined">
                  <CardContent>
                    <MapContainer pharmacyData={PharmacyData} />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item lg={3} md={4} sm={12} xs={12}>
                <Card variant="outlined">
                  <CardContent>
                    <DataContainer pharmacyData={PharmacyData} />
                  </CardContent>
                </Card>
                <CopyRightFooter />
              </Grid>
            </Grid>
          </Box>
        </>
      ) : (
        <Box sx={{width: '70vw'}}>
          <Card>
            <CardContent>
              <Typography variant="h6" sx={{mb: 1}}>
                Downloading data...
              </Typography>
              <LinearProgress variant="determinate" value={progress} />
            </CardContent>
          </Card>
        </Box>
      )}
    </>
  )
}

import React, {useContext, useEffect} from 'react'
import {AuthContext} from '../../context/context'
import Api from '../../services/api.service'
import PrimaryNavigation from './PrimaryNavigation'
import NavBar from './NavBar'

function Layout(props) {
  const {auth, setUserData} = useContext(AuthContext)

  useEffect(() => {
    if (auth.accessToken) {
      Api.get('/auth/users/me/').then(response => {
        setUserData(response.data)
      })
    }
  }, [])

  const Component = props.component

  return (
    <>
      {auth.accessToken ? <PrimaryNavigation /> : <NavBar />}
      <Component {...props} />
    </>
  )
}

export default Layout

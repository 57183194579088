import React from 'react'
import NotificationList from 'components/notifications/NotificationList'
import FriendsRequestList from 'components/account/profile/tabs/components/FollowRequestList'
import {Container, Typography, Box} from '@mui/material'

function Notifications() {
  return (
    <div className="main">
      <div className="main__content">
        <Box
          className="wrapper"
          sx={{
            backgroundColor: 'primary.lightBackground',
          }}
        >
          <Container
            sx={{
              maxWidth: {md: '600px', lg: '600px', sm: '600px'},
              backgroundColor: 'primary.mainBackground',
              borderRadius: 2,
              p: 2,
            }}
          >
            <Typography variant="h4" sx={{mb: 3}}>
              Notifications
            </Typography>

            <FriendsRequestList />
            <NotificationList />
          </Container>
        </Box>
      </div>
    </div>
  )
}

export default Notifications

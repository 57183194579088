import React from 'react'
import {Box, TextField, IconButton} from '@mui/material'
import Picker from 'emoji-picker-react'
import Api from 'services/api.service'
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions'
import SendRoundedIcon from '@mui/icons-material/SendRounded'

export default function ReplyToAnswer(props) {
  const [loading, setLoading] = React.useState(false)
  const [answerInput, setAnswerInput] = React.useState(props.userSlug || '')
  const [showEmoji, setShowEmoji] = React.useState(false)

  const onEmojiClick = (event, emojiObject) => {
    setAnswerInput(answerInput => {
      return answerInput + emojiObject.emoji
    })
    setShowEmoji(false)
  }

  const submitReply = () => {
    setLoading(true)
    const data = {
      answer: answerInput,
      parent_id: props.answer.id,
    }

    Api.post(`api/clinical-questions/answers/add/`, data)
      .then(res => {
        setAnswerInput('')
        // setAnswers([...answers, res.data])
        setShowEmoji(false)
        setLoading(false)
      })
      .catch(error => {
        console.log(error)
      })
  }

  return (
    <Box sx={{width: '100%'}}>
      <form>
        <Box sx={{display: 'flex', alignItems: 'flex-start'}}>
          <IconButton
            aria-label="smile outline"
            onClick={() => setShowEmoji(!showEmoji)}
          >
            <EmojiEmotionsIcon />
          </IconButton>
          <TextField
            id="filled-multiline-flexible"
            label="Write an answer"
            multiline
            minRows={2}
            variant="filled"
            sx={styles.textfield}
            value={answerInput}
            onChange={e => setAnswerInput(e.target.value)}
          />
          <IconButton
            variant="contained"
            onClick={submitReply}
            disabled={answerInput.length === 0 || loading}
          >
            <SendRoundedIcon />
          </IconButton>
        </Box>
        {showEmoji && <Picker onEmojiClick={onEmojiClick} preload />}
      </form>
    </Box>
  )
}

const styles = {
  textfield: {
    width: '100%',
    flexGrow: 3,
    minWidth: '290px',
  },
}

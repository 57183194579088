import React from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import SearchIcon from '@mui/icons-material/Search'
import SearchDiv from './SearchDiv'
import StyledInputBase from './StyledInputBase'
import FormSelectDiv from './FormSelectDiv'
import IconButton from '@mui/material/IconButton'
import AutoCompleOptions from './AutoCompleOptions'

const SearchBar = () => {
  const [searchCategory, setSearchCategory] = React.useState('pharmacies')
  const [searchQuery, setSearchQuery] = React.useState('')
  const [autoCompleteOptions, setAutoCompleteOptions] = React.useState(false)

  const handleChange = event => {
    setSearchCategory(event.target.value)
  }
  const handleInputChange = event => {
    setSearchQuery(event.target.value)
    if (searchCategory === 'pharmacies') {
      setAutoCompleteOptions(true)
    }
    if (event.target.value === '') {
      setAutoCompleteOptions(false)
    }
  }
  const handleSubmit = e => {
    e.preventDefault()
    const newUrl = `/${searchCategory}/?q=${searchQuery}`
    window.location.href = newUrl
  }
  const formStyle = {
    padding: 0,
    margin: 0,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '40px',
    justifyContent: 'center',
  }
  return (
    <>
      <form style={formStyle} onSubmit={e => handleSubmit(e)}>
        <FormSelectDiv>
          <FormControl
            variant="filled"
            sx={{m: 0, p: 0}}
            color="primary"
            size="small"
          >
            <InputLabel
              id="demo-simple-select-label"
              sx={{color: 'white', fontSize: '12px'}}
            >
              Category
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={searchCategory}
              label="Pharmacies"
              onChange={handleChange}
              sx={{color: 'white', fontSize: '12px'}}
            >
              <MenuItem value={'pharmacies'}>Pharmacies</MenuItem>
              <MenuItem value={'users'}>Users</MenuItem>
              <MenuItem value={'agencies'}>Agencies</MenuItem>
            </Select>
          </FormControl>
        </FormSelectDiv>
        <SearchDiv>
          <StyledInputBase
            placeholder="Search…"
            inputProps={{'aria-label': 'search'}}
            onChange={handleInputChange}
          />
          <IconButton
            type="submit"
            sx={theme => {
              return {p: '10px', color: theme.palette.common.white}
            }}
            aria-label="search"
          >
            <SearchIcon />
          </IconButton>
        </SearchDiv>
        <button type="submit" hidden></button>
      </form>
      {autoCompleteOptions && <AutoCompleOptions searchQuery={searchQuery} />}
    </>
  )
}

export default SearchBar

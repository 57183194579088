import React from 'react'
import {
  Box,
  TextField,
  FormControl,
  Rating,
  Typography,
  MenuItem,
  Button,
} from '@mui/material'
import StarIcon from '@mui/icons-material/Star'
import Api from 'services/api.service'
import {useToasts} from 'react-toast-notifications'

export default function AgencyReviewForm(props) {
  const {addToast} = useToasts()
  const [starRating, setStarRating] = React.useState(0)
  const [hover, setHover] = React.useState(-1)
  const {agency} = props
  const [data, setData] = React.useState({
    overall_rating: 0,
    review: '',
    recommended: 'Not Sure',
    gets_the_highest_rate: '',
    professional_and_courteous: 0,
  })

  const handleChange = event => {
    let {name, value} = event.target
    // TODO: temp fix
    if (name === 'professional_and_courteous') {
      if (value === 'Yes') {
        value = 1
      } else {
        value = 0
      }
    }
    setData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleSubmit = async event => {
    event.preventDefault()

    try {
      const response = await Api.post(
        `/api/business/agency/agency_review/${agency.id}/add/`,
        data,
      )
      // Handle the response as needed
      if (response.status === 201) {
        props.handleClose()
        addToast('Your Review has been submitted. Thank you', {
          appearance: 'success',
          autoDismiss: true,
        })
      }
    } catch (error) {
      // Handle the error
      console.error(error)
    }
  }

  const handleStarChange = (name, value) => {
    if (value === null) {
      value = 0
    }
    setData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }

  const recommendedOptions = ['Yes', 'No', 'Not Sure']

  const labels = {
    1: 'Stay Away',
    2: 'Stay Away+',
    3: 'Poor',
    4: 'Poor+',
    5: 'Ok',
    6: 'Ok+',
    7: 'Good',
    8: 'Good+',
    9: 'Excellent',
    10: 'Excellent+',
  }

  function getLabelText(starRating) {
    return `${starRating} Star${starRating !== 1 ? 's' : ''}, ${
      labels[starRating]
    }`
  }

  const ReviewText = (
    <FormControl fullWidth sx={styles.formControl}>
      <TextField
        required
        id="outlined-required"
        name="review"
        label="Review"
        multiline
        rows={5}
        placeholder="Write your review here"
        onChange={handleChange}
      />
    </FormControl>
  )

  const ReviewStars = (
    <FormControl fullWidth sx={styles.formControl}>
      <Typography gutterBottom>How would you rate this agency?</Typography>
      <Box sx={styles.starRating}>
        <Rating
          name="overall_rating"
          max={10}
          value={starRating}
          getLabelText={getLabelText}
          icon={<StarIcon color={'secondary'} fontSize={'inherit'} />}
          emptyIcon={<StarIcon color={'grey'} fontSize={'inherit'} />}
          onChange={(event, newValue) => {
            setStarRating(newValue)
            handleStarChange('overall_rating', newValue)
          }}
          onChangeActive={(event, newHover) => {
            setHover(newHover)
          }}
        />
        {starRating !== null && (
          <Box sx={{ml: 2}}>{labels[hover !== -1 ? hover : starRating]}</Box>
        )}
      </Box>
    </FormControl>
  )

  const ReviewRecommended = (
    <FormControl fullWidth sx={styles.formControl}>
      <Typography gutterBottom>
        Would you recommend this agency to other locums?
      </Typography>
      <TextField
        id="select-recommended"
        select
        fullWidth
        label="Select"
        defaultValue={'Not Sure'}
        name="recommended"
        onChange={handleChange}
        sx={{mt: 2}}
      >
        {recommendedOptions.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  )
  const ReviewGetsHighestRate = (
    <FormControl fullWidth sx={styles.formControl}>
      <Typography gutterBottom>
        Do you think the agency does it’s best to get you the highest rate??
      </Typography>
      <TextField
        id="select-recommended"
        select
        fullWidth
        label="Select"
        defaultValue={'Not Sure'}
        name="gets_the_highest_rate"
        onChange={handleChange}
        sx={{mt: 2}}
      >
        {recommendedOptions.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  )
  const ReviewProfessional = (
    <FormControl fullWidth sx={styles.formControl}>
      <Typography gutterBottom>
        Is the agency always professional and courteous?
      </Typography>
      <TextField
        id="select-recommended"
        select
        fullWidth
        label="Select"
        defaultValue={'Not Sure'}
        name="professional_and_courteous"
        onChange={handleChange}
        sx={{mt: 2}}
      >
        {recommendedOptions.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  )
  return (
    <form>
      <Typography gutterBottom>
        Please let us know about your experience with {agency.name}:
      </Typography>
      {ReviewText}
      {ReviewStars}
      {ReviewRecommended}
      {ReviewGetsHighestRate}
      {ReviewProfessional}
      <Box sx={styles.submitButton}>
        <Button variant="outlined" onClick={handleSubmit}>
          Submit
        </Button>
      </Box>
    </form>
  )
}

const styles = {
  formControl: {
    mt: 3,
  },
  starRating: {
    display: 'flex',
    gap: 1,
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  submitButton: {
    mt: 2,
    width: {
      md: '350px',
      sm: '100%',
    },
  },
}

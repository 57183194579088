import React from 'react'
import {GoogleMap, useJsApiLoader, Marker} from '@react-google-maps/api'
const containerStyle = {
  width: '100%',
  height: '450px',
}

export default function GoogleMapComp(props) {
  const {isLoaded} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyBQWZfvIV-eEpGapF-5M780uJH-zw_cqp0',
  })

  const center = {
    lat: props.lat,
    lng: props.lng,
  }

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!!
    const bounds = new window.google.maps.LatLngBounds(center)
    // since zooming happens asynchronously, below is workaround with event listener to set the zoom manually
    new window.google.maps.event.addListener(map, 'zoom_changed', function () {
      const zoomChangeBoundsListener = new window.google.maps.event.addListener(
        map,
        'bounds_changed',
        function (event) {
          if (this.getZoom() > 15 && this.initialZoom == true) {
            // Change max/min zoom here
            this.setZoom(17)
            this.initialZoom = false
          }
          new window.google.maps.event.removeListener(zoomChangeBoundsListener)
        },
      )
    })
    map.initialZoom = true
    map.fitBounds(bounds)

    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={2.5}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {/* Child components, such as markers, info windows, etc. */}
      <Marker position={center} />
      <></>
    </GoogleMap>
  ) : (
    <></>
  )
}

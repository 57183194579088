import React from 'react'
import {IconButton, Typography} from '@mui/material'
import ThumbUpRoundedIcon from '@mui/icons-material/ThumbUpRounded'
import Api from 'services/api.service'

export default function LikeButton(props) {
  const {answer} = props
  const [liked, setLiked] = React.useState(answer.liked_by_user || false)

  const handleLike = async () => {
    const response = await Api.post(
      `api/clinical-questions/answers/${answer.id}/like/`,
    )
    console.log(response)
    if (response.status === 200) {
      setLiked(true)
    } else {
      setLiked(false)
    }
  }

  return (
    <>
      <IconButton
        variant="text"
        sx={theme => {
          return liked
            ? {color: theme.palette.primary.main}
            : {color: '#817e7c'}
        }}
        onClick={handleLike}
      >
        <ThumbUpRoundedIcon sx={{width: '14px', height: '14px'}} />
      </IconButton>
      <Typography variant="caption">
        {liked
          ? answer.likes_count + 1
          : answer.likes_count !== 0 && answer.likes_count}
      </Typography>
    </>
  )
}

import React from 'react'
import Box from '@mui/material/box'
import ReviewSummaryItems from './ReviewSummaryItems'

export default function ReviewSummary(props) {
  const {pharmacy} = props
  return (
    <>
      <Box sx={{maxWidth: 500, my: 4}}>
        <ReviewSummaryItems pharmacy={pharmacy} />
      </Box>
    </>
  )
}

import * as React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'

export default function DashboardCard(props) {
  return (
    <Card sx={{minWidth: 275}}>
      <CardContent>
        <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
          {props.secondaryTitle}
        </Typography>
        <Typography variant="h5" component="div">
          {props.mainTitle}
        </Typography>
        {props.children}
      </CardContent>
    </Card>
  )
}

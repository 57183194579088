import {useState} from 'react'
import {Box, Avatar, IconButton, TextField} from '@mui/material'
import Api from 'services/api.service'
import Picker from 'emoji-picker-react'
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions'
import SendRoundedIcon from '@mui/icons-material/SendRounded'

const WriteAnAnswer = props => {
  const {questionId, user, setAnswers, answers} = props
  const [answerInput, setAnswerInput] = useState('')
  const [showEmoji, setShowEmoji] = useState(false)
  const [loading, setLoading] = useState(false)

  const onEmojiClick = (event, emojiObject) => {
    setAnswerInput(answerInput => {
      return answerInput + emojiObject.emoji
    })
    setShowEmoji(false)
  }

  const SubmitComment = () => {
    setLoading(true)
    const data = {
      answer: answerInput,
      question_id: questionId,
    }

    Api.post(`api/clinical-questions/answers/add/`, data)
      .then(res => {
        setAnswerInput('')
        setAnswers([...answers, res.data])
        setShowEmoji(false)
        setLoading(false)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const bottomBar = (
    <Box sx={styles.bottomBarContainer}>
      <div>
        <IconButton
          aria-label="smile outline"
          onClick={() => setShowEmoji(!showEmoji)}
        >
          <EmojiEmotionsIcon />
        </IconButton>
        <IconButton
          variant="contained"
          onClick={SubmitComment}
          disabled={answerInput.length === 0 || loading}
        >
          <SendRoundedIcon />
        </IconButton>
      </div>
    </Box>
  )

  return (
    <Box sx={styles.container}>
      <Box sx={styles.formContainer}>
        <form>
          <Box sx={{display: 'flex', gap: 1, alignItems: 'flexStart'}}>
            <Avatar
              alt={user.full_name}
              src={(user.profile && user.profile.profile_image) || ''}
              sx={{width: 24, height: 24}}
            />
            <TextField
              id="filled-multiline-flexible"
              label="Write an answer"
              multiline
              minRows={2}
              variant="filled"
              value={answerInput}
              sx={styles.textField}
              onChange={e => setAnswerInput(e.target.value)}
            />
          </Box>
          {bottomBar}
          {showEmoji && <Picker onEmojiClick={onEmojiClick} preload />}
        </form>
      </Box>
    </Box>
  )
}

const styles = {
  container: {
    display: 'flex',
    width: '90vw',
    maxWidth: 500,
    flexDirection: 'column',
    margin: '0 auto',
    bgcolor: 'greyBackground.light',
    p: 2,
    borderRadius: ' 0 0 20px 20px',
    mb: 20,
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  textField: {
    width: '100%',
    mb: 2,
  },
  bottomBarContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}
export default WriteAnAnswer

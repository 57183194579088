import {useState, useCallback} from 'react'
// import {Modal, Button} from 'semantic-ui-react'
import {Avatar, Modal, Button, Typography, Box} from '@mui/material'
import {useDropzone} from 'react-dropzone'
import Api from 'services/api.service'
import {apiUrl} from 'config/vars'
import {useToasts} from 'react-toast-notifications'
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined'

const ProfilePictureUpload = props => {
  const {addToast} = useToasts()
  const user = props.user
  const [open, setOpen] = useState(false)
  const [file, setFile] = useState(null)
  const [profilePicture, setProfilePicture] = useState(
    user.profile.profile_image,
  )
  const [uploadLoading, setUploadLoading] = useState(false)
  const uploadDocument = () => {
    setUploadLoading(true)

    const data = new FormData()

    data.append('profile_image', file)

    Api.put(`${apiUrl}user/profile-update/${user.profile.id}`, data)
      .then(response => {
        setOpen(false)
        setFile(null)
        setUploadLoading(false)
        addToast('Profile Picture has been uploaded successfully.', {
          appearance: 'success',
          autoDismiss: true,
        })
        setProfilePicture(response.data.profile_image)
      })
      .catch(error => {
        setUploadLoading(false)
        console.log(error)
        if (error.response) {
          addToast(error.response.data.detail, {
            appearance: 'error',
            autoDismiss: true,
          })
        } else {
          addToast(error, {
            appearance: 'error',
            autoDismiss: true,
          })
        }
      })
  }

  const onDrop = useCallback(acceptedFiles => {
    setFile(acceptedFiles[0])
  }, [])
  const {getRootProps, getInputProps} = useDropzone({
    onDrop: onDrop,
    multiple: false,
  })

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: 600,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 3,
          mb: 4,
        }}
      >
        <Avatar src={profilePicture} sx={{height: 80, width: 80}} />
        <Box>
          <Typography>{user.full_name}</Typography>
          <Button onClick={() => setOpen(true)} variant="text">
            <InsertPhotoOutlinedIcon /> Change profile picture
          </Button>
        </Box>
      </Box>
      <Modal onClose={() => setOpen(false)} open={open}>
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Upload your profile picture
          </Typography>
          <div className="dropzone">
            <div {...getRootProps({className: 'dropzone__input'})}>
              <input {...getInputProps()} />
              <p>
                Please drag and drop your document here, or click to select file
              </p>
            </div>

            {file && <div className="dropzone__file">{file.name}</div>}

            <Button
              loading={uploadLoading}
              disabled={!file}
              variant="outlined"
              onClick={() => uploadDocument()}
            >
              Upload Document
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  )
}

export default ProfilePictureUpload

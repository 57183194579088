import React, {useEffect, useRef} from 'react'
import * as d3 from 'd3'

function UkMap(props) {
  const svgRef = useRef(null)
  const {uk, mapData} = props
  function toFixedIfNecessary(value, dp) {
    return +parseFloat(value).toFixed(dp)
  }

  useEffect(() => {
    var colorScale = d3
      .scaleThreshold()
      .domain([10, 20, 30, 40, 50, 60, 80])
      .range(d3.schemeBlues[7])

    // let setFillColour
    // Define the projection and path generator

    const projection = d3.geoMercator().fitSize([302, 600], uk)
    const pathGenerator = d3.geoPath(projection)
    // Render the map using mergedData
    if (mapData.length > 1 && svgRef.current) {
      const setFillColour = feature => {
        const locumRate = feature.properties.average_locum_rate
        let colour = colorScale(locumRate)
        return colour
      }

      const tooltip = d3
        .select('body')
        .append('div')
        .attr('class', 'tooltip')
        .style('opacity', 0)
        .style('position', 'fixed')

      d3.select(svgRef.current)
        .selectAll('path')
        .data(mapData)
        .enter()
        .append('path')
        //retrieve the name of the country from data
        .attr('data-name', function (d) {
          return d.properties.NAME_2.toUpperCase()
        })
        .attr('d', feature => pathGenerator(feature))
        .style('fill', feature => setFillColour(feature))
        .style('stroke', '#fff')
        .style('stroke-width', '0.5px')
        .on('mouseover', (event, feature) => {
          const city = feature.properties.NAME_2.toUpperCase()
          const locumRate = toFixedIfNecessary(
            feature.properties.average_locum_rate,
            2,
          )
          d3.select(event.currentTarget).style('fill', '#D23A65')
          tooltip.transition().duration(200).style('opacity', 0.9)
          tooltip.html(`${city}<br>Average Locum rate: £${locumRate}`)
        })
        .on('mouseleave', (event, feature) => {
          const locumRate = feature.properties.average_locum_rate
          let colour = colorScale(locumRate)
          d3.select(event.currentTarget).style('fill', colour)
          tooltip.transition().style('opacity', 0)
        })
    }
  }, [mapData, svgRef])

  return (
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <svg ref={svgRef} width={305} height={600} />
    </div>
  )
}

export default UkMap

import React from 'react'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import BranchName from './branch-details/BranchName'
import ContactDetails from './branch-details/ContactDetails'
import OpeningHours from './branch-details/OpeningHours'

export default function BranchDetail(props) {
  const {branch, loading} = props
  return (
    <Container sx={{mt: 10, mb: 2}}>
      <BranchName pharmacy={branch} />
      {!loading && (
        <Grid container spacing={2} columns={12}>
          <Grid item xs={12} md={6}>
            <ContactDetails pharmacy={branch} />
          </Grid>
          <Grid item xs={12} md={6}>
            {branch.opening_hours.length > 0 && (
              <OpeningHours pharmacy={branch} />
            )}
          </Grid>
        </Grid>
      )}
    </Container>
  )
}

import NotificationCss from '../notification.module.css'
import {Typography, Box, Avatar, Link} from '@mui/material'

function ReviewApproved(props) {
  const {notification} = props
  const time = new Date(notification.timestamp).toDateString()
  const slug = notification.data.data.pharmacy.slug || false
  const gphcNumber =
    notification.data.data.pharmacy.gphc_registration_number || '#'
  const postUrl = slug ? `/page/pharmacy/${slug}/` : '#'
  return (
    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
      <Box sx={{display: 'flex', gap: 2}}>
        <Link href={postUrl} underline="none">
          <Avatar
            src={notification.data.data.pharmacy.company_logo || null}
            alt={notification.data.data.pharmacy.trading_name}
          />
        </Link>
        <Box>
          <Typography>
            <Link href={postUrl} underline="none">
              {notification.data.data.pharmacy.trading_name}
            </Link>{' '}
            {notification.verb} <br />
            <Typography variant="caption" color="secondary">
              {notification.time_ago}
            </Typography>
          </Typography>
        </Box>
      </Box>
      <Box>
        {notification.unread && (
          <div className={NotificationCss.unreadIndicator}></div>
        )}
      </Box>
    </Box>
  )
}

export default ReviewApproved

import React from 'react'
import {useField} from 'formik'
import {Message, Form as FormUi} from 'semantic-ui-react'
import CustomCss from '../Review.module.css'

const TextArea = ({label, helpText, ...props}) => {
  const [field, meta] = useField(props)

  return (
    <FormUi.Field>
      <label className={CustomCss.Label}>
        {label} <br />
        <span className={CustomCss.helpText}>{helpText}</span>
      </label>
      <FormUi.TextArea {...field} {...props} />
      {meta.touched && meta.error ? (
        <Message negative>{meta.error}</Message>
      ) : null}
    </FormUi.Field>
  )
}

export default TextArea

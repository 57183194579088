import React from 'react'
import {Box, Button, Container, Typography} from '@mui/material'
import Api from 'services/api.service'
import PharmacyCarouselItem from 'components/feed/recentlyReviewedPharmacies/PharmacyCarouselItem'
import Loading from 'components/layout/common/loading/Loading'

export default function PharmacyReviewPage() {
  const [data, setData] = React.useState([])
  const [hasNext, setHasNext] = React.useState(false)
  const [count, setCount] = React.useState(0)
  const [nextUrl, setNextUrl] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const fetchReviews = () => {
    setLoading(true)
    Api.get('/api/business/pharmacy/all/include_reviews')
      .then(response => {
        setHasNext(response.data.next && true)
        setCount(response.data.count)
        setNextUrl(response.data.next)
        setData(response.data.results)
        setLoading(false)
      })
      .catch(error => console.error(error))
  }

  const fetchMore = () => {
    Api.get(nextUrl)
      .then(response => {
        setHasNext(response.data.next && true)
        setNextUrl(response.data.next)
        setData([...data, ...response.data.results])
      })
      .catch(error => console.error(error))
  }

  React.useEffect(() => {
    fetchReviews()
  }, [])

  const reviewItems = data?.map((item, index) => {
    return <PharmacyCarouselItem item={item} key={index} />
  })

  return (
    <div className="main">
      <div className="main__content">
        <div className="wrapper" style={{backgroundColor: '#F4F4F4'}}>
          {loading ? (
            <Loading />
          ) : (
            <Container maxWidth="lg">
              <Box sx={{flexGrow: 1, px: 4}}>
                <Typography variant="h4">Latest Pharmacy Reviews</Typography>
                <Typography variant="body">{count} reviews found</Typography>
                <Box sx={{my: 4}}>{reviewItems}</Box>
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                  {hasNext && (
                    <Button
                      variant="outlined"
                      onClick={() => {
                        fetchMore()
                      }}
                    >
                      Load More
                    </Button>
                  )}
                </Box>
              </Box>
            </Container>
          )}
        </div>
      </div>
    </div>
  )
}

import React from 'react'
import {fetchAllAgencies} from 'services/calls/AgencyAPI'
import {Box, Button} from '@mui/material'
import Item from './Item'
import Loading from 'components/layout/common/loading/Loading'

export default function AgencyReviewsWidget() {
  const [agencies, setAgencies] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [shownCount, setShownCount] = React.useState(6)
  const [showLoadMore, setShowLoadMore] = React.useState(true)
  const fetchData = async () => {
    try {
      const allData = await fetchAllAgencies()
      console.log(allData)
      setAgencies(allData)
      setLoading(false)
    } catch (error) {
      // Handle error if necessary
      console.error(error)
    }
  }

  React.useEffect(() => {
    fetchData()
  }, [])

  const LoadMore = () => {
    const newCount = shownCount + 6
    if (shownCount < agencies.length && newCount !== agencies.length) {
      setShownCount(newCount)
    } else {
      setShowLoadMore(false)
    }
  }

  const reviewItem = agencies?.slice(0, shownCount).map(agency => {
    return <Item agency={agency} key={agency.id} />
  })
  return (
    <div style={{minHeight: '400px'}}>
      {loading ? (
        <Loading />
      ) : (
        <>
          {reviewItem}
          {showLoadMore && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                minHeight: '80px',
              }}
            >
              <Button onClick={LoadMore}>Load more</Button>
            </Box>
          )}
        </>
      )}
    </div>
  )
}

import React from 'react'
import SinglePostContainer from 'components/feed/SinglePostContainer/SinglePostContainer'

export default function SinglePost(props) {
  const {id} = props.match.params
  return (
    <div className="main">
      <div className="main__content">
        <div
          className="wrapper"
          style={{backgroundColor: '#F4F4F4', minHeight: '100vh'}}
        >
          <SinglePostContainer postId={id} />
        </div>
      </div>
    </div>
  )
}

import * as React from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Api from 'services/api.service'
import _ from 'lodash'

export default function CategoriesAutoComplete(props) {
  const {category, setSelectedCategory} = props
  const [categories, setCategories] = React.useState([])
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    Api.get('api/clinical-questions/clinical-categories/').then(res => {
      const result = res.data
      //   change name in each object inside the array to label using loadash
      _.map(result, function (o) {
        o.label = o.name
        delete o.name
      })
      setCategories(result)
      setSelectedCategory(result[0])
      setLoading(false)
    })
  }, [])

  return (
    <>
      {!loading && (
        <Autocomplete
          value={category}
          disablePortal
          id="combo-box-demo"
          options={categories}
          sx={{width: '100%'}}
          onChange={(e, newValue) => setSelectedCategory(newValue)}
          renderInput={params => <TextField {...params} label="Categories" />}
        />
      )}
    </>
  )
}

import React from 'react'
import {Box} from '@mui/material'
import Image from 'mui-image'

export default function NhsAccreditation() {
  const nhs = {
    url: 'https://www.nhs.uk',
    logo: 'https://www.nhs.uk/nhscwebservices/documents/logo1.jpg',
    email: 'nhswebsite.servicedesk@nhs.net',
    name: 'NHS website',
  }
  return (
    <Box sx={{my: 3}}>
      <a href={nhs.url} target="_blank" rel="noreferrer">
        <Image src={nhs.logo} alt={nhs.name} width={'220px'} height="auto" />
      </a>
    </Box>
  )
}

import * as React from 'react'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'

export default function CustomeSnackbar(props) {
  const {severity, message, timeOut} = props

  const [open, setOpen] = React.useState({
    open: true,
  })

  React.useEffect(() => {
    const time = timeOut || 5000
    setTimeout(() => {
      setOpen(false)
    }, time)
  }, [])

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Box
      sx={{
        mb: 2,
        display: 'block',
        textAlign: 'center',
        gap: 2,
      }}
    >
      {open && (
        <Alert
          onClose={handleClose}
          severity={severity || 'success'}
          sx={{width: '100%', m: '0 auto'}}
        >
          {message}
        </Alert>
      )}
    </Box>
  )
}

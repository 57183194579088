import React from 'react'
import Box from '@mui/material/box'
import SecondarySpan from 'components/layout/common/typography/SecondarySpan'
import PaddedTypography from 'components/layout/common/typography/PaddedTypography'

export default function EmployeeAverageWorkLoad(props) {
  const {pharmacy} = props
  return (
    <>
      <Box
        sx={{display: 'flex', width: '100%', justifyContent: 'space-between'}}
      >
        <PaddedTypography variant="body1">
          Employee average workload:
        </PaddedTypography>
        <PaddedTypography variant="body1">
          <SecondarySpan>
            {pharmacy.review_stats.employed.average_workload_rating
              .workload_rating__avg ? (
              <>
                {pharmacy.review_stats.employed.average_workload_rating.workload_rating__avg.toFixed(
                  1,
                )}
                /10
              </>
            ) : (
              'No reviews'
            )}
          </SecondarySpan>
        </PaddedTypography>
      </Box>
    </>
  )
}

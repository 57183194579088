import React from 'react'
import LikeButton from './LikeButton'
import CommentButton from './comments/CommentButton'
import FeedCss from '../feed.module.css'
import FeedPostLikedUsers from './FeedPostLikedUsers'
import ShareButton from './share/ShareButton'
export default function FeedPostFooter(props) {
  const {item, user, showComments, setShowComments} = props
  return (
    <div className={FeedCss.FeedPostFooter}>
      <FeedPostLikedUsers item={item} />
      <div className={FeedCss.FeedPostFooterActions}>
        <ShareButton item={item} />
        <LikeButton item={item} />
        <CommentButton
          item={item}
          user={user}
          showComments={showComments}
          setShowComments={setShowComments}
        />
      </div>
    </div>
  )
}

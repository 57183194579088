import React from 'react'
import {withRouter} from 'react-router-dom'
import Lottie from 'lottie-react'
import {useHistory} from 'react-router-dom'

import reviewStyle from '../Review.module.css'
import successAnimation from 'assets/lottie/success.json'

function SuccessLocumReview(props) {
  const history = useHistory()
  const {slug, review_id} = props.match.params
  const moreReviewQuestionUrl = `/page/pharmacy/${slug}/create-review/locum-pharmacist/${review_id}/more-questions/`

  return (
    <div className="main">
      <div className={reviewStyle.pageContainer}>
        <div className={reviewStyle.FormikContainer}>
          <Lottie
            animationData={successAnimation}
            style={{margin: '0 auto', width: 200}}
          />
          <h2>You have successfully submitted your review.</h2>
          <button
            onClick={() => history.push(moreReviewQuestionUrl)}
            className={reviewStyle.filledButton}
          >
            Answer more questions
          </button>
          <br />
          <div className="mt-20" />
          <button className={reviewStyle.outlinedButton}>
            Go back to Pharmacy page
          </button>
        </div>
      </div>
    </div>
  )
}

export default withRouter(SuccessLocumReview)

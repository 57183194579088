import React from 'react'
import FeedCss from '../feed.module.css'

export default function FeedPostLikedUsers(props) {
  const {item} = props
  const likedUsers = item.liked_users
  const likedUsersCount = likedUsers.length
  let likedUsersRendered = null
  if (likedUsersCount > 0) {
    likedUsersRendered = likedUsers.slice(0, 3).map(user => {
      return (
        <div className={FeedCss.FeedPostLikedUsersImages} key={user.id}>
          <img src={user.profile.profile_image} alt="user" />
        </div>
      )
    })
  }
  const likedUsersTextRendered = (
    <>
      {likedUsersCount > 0 ? (
        <div className={FeedCss.likedUsersTextRendered}>
          <p>
            <a href={`/account/${likedUsers[0].slug}`}>
              {likedUsers[0].full_name}{' '}
            </a>
          </p>
          <p>
            {likedUsersCount > 1 && (
              <span>and {likedUsersCount - 1} more </span>
            )}
            liked this
          </p>
        </div>
      ) : null}
    </>
  )
  return (
    <div className={FeedCss.FeedPostLikedUsers}>
      {likedUsersRendered}
      {likedUsersTextRendered}
    </div>
  )
}

import {Typography, ImageList, ImageListItem} from '@mui/material'
import React from 'react'
import {fetchAllQuestions, endPoints} from 'services/calls/ClinicalAPI'
import QuestionCard from 'components/feed/QuestionSlider/QuestionCard'

export default function QuestionGrid(props) {
  const [loading, setLoading] = React.useState(true)
  const [questions, setQuestions] = React.useState([])
  const {activeCategory} = props

  React.useEffect(() => {
    if (activeCategory === null) {
      fetchAllQuestions(endPoints.clinicalQuestion).then(questions => {
        setQuestions(questions)
        setLoading(false)
      })
    } else {
      fetchAllQuestions(
        endPoints.clinicalQuestion + `?category=${activeCategory}`,
      ).then(questions => {
        setQuestions(questions)
        setLoading(false)
      })
    }
  }, [activeCategory])

  return (
    <>
      <Typography variant="h6" sx={{my: 2}}>
        Questions
      </Typography>
      <ImageList
        cols={3}
        gap={4}
        rowHeight="auto"
        sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}
      >
        {loading && <Typography>Loading...</Typography>}
        {questions.map((question, index) => (
          <ImageListItem key={index}>
            <QuestionCard question={question} />
          </ImageListItem>
        ))}
      </ImageList>
    </>
  )
}

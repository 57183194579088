import React from 'react'
import Loadable from '@loadable/component'

import Loading from '../../components/base/ComponentLoading'

const ResetPasswordConfirmationComponent = Loadable(() =>
  import('./ResetPasswordConfirmation.js'),
)

function ResetPasswordConfirmation() {
  return <ResetPasswordConfirmationComponent fallback={<Loading />} />
}

export default ResetPasswordConfirmation

import AgencyLogoPlaceholder from '../../../assets/images/pharmacies/pharmacy_icon.png'
import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

const StyledPaper = styled(Paper)(({theme}) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(4),
  maxWidth: 400,
  color: theme.palette.text.primary,
  ':hover': {
    backgroundColor:
      theme.palette.mode === 'dark' ? '#1A2027' : theme.palette.primary.light,
    cursor: 'pointer',
  },
}))

export default function AgencySearchResult(props) {
  const {results} = props

  const resultDiv = results.map((agency, index) => {
    return (
      <>
        <Link
          underline="none"
          color="inherit"
          href={`/page/agency/${agency.slug}/`}
        >
          <StyledPaper
            sx={{
              my: 1,
              mx: 'auto',
              p: 2,
            }}
          >
            <Grid
              container
              wrap="nowrap"
              spacing={2}
              sx={{alignItems: 'center'}}
            >
              <Grid item>
                <Avatar
                  src={agency.logo || AgencyLogoPlaceholder}
                  sx={{width: 60, height: 60}}
                >
                  A
                </Avatar>
              </Grid>
              <Grid item xs>
                <Typography>{agency.name}</Typography>
              </Grid>
            </Grid>
          </StyledPaper>
        </Link>
      </>
    )
  })

  return (
    <div>
      <Box sx={{flexGrow: 1, overflow: 'hidden', px: 3}}>{resultDiv}</Box>
    </div>
  )
}

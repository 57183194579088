import NotificationCss from '../notification.module.css'
import {Typography, Box, Avatar, Link} from '@mui/material'

function FriendRequestAcceptedNotification(props) {
  const {notification} = props
  const time = new Date(notification.timestamp).toDateString()
  const slug = notification?.data?.data?.new_friend?.slug || false
  const postUrl = slug ? `/account/${slug}` : '#'

  return (
    <Link
      href={postUrl}
      underline="none"
      sx={{display: 'flex', justifyContent: 'space-between'}}
    >
      <Box sx={{display: 'flex', gap: 2}}>
        <Avatar
          src={notification?.data?.data?.new_friend?.profile_picture || null}
          alt={notification?.data?.data?.new_friend?.full_name}
        />

        <Typography>
          {notification.verb} <br />
          <Typography variant="caption" color="secondary">
            {notification.time_ago}
          </Typography>
        </Typography>
      </Box>
      <Box>
        {notification.unread && (
          <div className={NotificationCss.unreadIndicator}></div>
        )}
      </Box>
    </Link>
  )
}

export default FriendRequestAcceptedNotification

import {useState} from 'react'
import Api from '../../../services/api.service'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import FavoriteIcon from '@mui/icons-material/Favorite'
import Button from '@mui/material/Button'

export default function LikeButton(props) {
  const [likeCount, setLikeCount] = useState(props.item.like_counts)
  const [likedByMe, setLikedByMe] = useState(props.item.liked_by_me)

  const likeThisPost = postId => {
    const data = new FormData()
    data.append('post', postId)
    Api.post('/api/news-feed/like/post/add/', data).then(response => {
      let newLikeCount = likedByMe ? likeCount - 1 : likeCount + 1
      setLikeCount(newLikeCount)
      setLikedByMe(!likedByMe)
    })
  }

  const likeText = () => {
    if (likeCount > 1) {
      return `${likeCount} likes`
    } else {
      return `${likeCount} like`
    }
  }
  return (
    <Button
      onClick={() => likeThisPost(props.item.id)}
      variant="text"
      sx={{
        ...styles.feedLikeButton,
        color: likedByMe ? 'red' : 'inherit',
      }}
    >
      {likedByMe ? (
        <FavoriteIcon fontSize="small" />
      ) : (
        <FavoriteBorderIcon fontSize="small" />
      )}
      {likeText()}
    </Button>
  )
}

const styles = {
  feedLikeButton: {
    border: 'none',
    backgroundColor: 'transparent',
    color: 'rgba(0, 0, 0, 0.4)',
    gap: '1px',
    fontSize: 11,
    alignIitems: 'center',
    width: '78px',
    m: 0,
    p: 0,
    textTransform: 'none',
  },
}

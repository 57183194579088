import {useState, useEffect, useMemo} from 'react'
import {useLocation} from 'react-router-dom'
import {Divider, Button, Container, Typography} from '@mui/material'
import AgencySearchResult from 'components/account/search/AgencySearchResult'
import Api from 'services/api.service'
import {apiUrl} from '../../../config/vars'

function useQuery() {
  const {search} = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

export default function AgencySearchResults() {
  const [query, setQuery] = useState('')
  const [results, setResults] = useState([])
  let urlQuery = useQuery()

  const searchAgencies = (q = '') => {
    Api.get(`${apiUrl}api/business/agency/lookup/?q=${query || q}`)
      .then(response => {
        setResults(response.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const loadMore = async () => {
    const {next} = results
    if (next) {
      const response = await Api.get(next)
      const updatedList = [...results.results, ...response.data.results]
      setResults({...results, results: [...updatedList]})
    }
  }

  useEffect(() => {
    let search = urlQuery.get('q')
    searchAgencies(search)
    setQuery(search)
  }, [])

  return (
    <div className="main">
      <div className="main__content">
        <Container sx={{mt: 2}}>
          {results.count > 0 && (
            <Typography variant="h6">
              {results.count} results found for &apos;{query}&apos;
            </Typography>
          )}
          {results.count > 0 ? (
            <div style={{marginBottom: '160px'}}>
              <AgencySearchResult results={results.results} />
              <Divider hidden />
              {results.count != results.results.length && (
                <Button onClick={() => loadMore()}>LoadMore</Button>
              )}
            </div>
          ) : (
            <Typography variant="h6">Nothing was found!</Typography>
          )}
        </Container>
      </div>
    </div>
  )
}

import NotificationCss from '../notification.module.css'
import {Typography} from '@mui/material'

function NotificationItem(props) {
  const notification = props.notification
  const time = new Date(props.notification.timestamp).toDateString()
  return (
    <div className={NotificationCss.NotificationItemContainer}>
      <div>
        <div>
          <Typography className={NotificationCss.notificationText}>
            {notification.verb} <br />
            <span className={NotificationCss.timeStamp}>{time}</span>
          </Typography>
        </div>
      </div>
      {notification.unread && (
        <div className={NotificationCss.unreadIndicator}></div>
      )}
    </div>
  )
}

export default NotificationItem

import React from 'react'
import {Box, Button, Link} from '@mui/material'
export default function LocumReteWidget() {
  return (
    <Box sx={{display: 'flex', justifyContent: 'center', my: 3}}>
      <Link href="/data-insight/locum-rate-choropleth/">
        <Button variant="outlined" color="secondary">
          view the interactive map (choropleth)
        </Button>
      </Link>
    </Box>
  )
}

import React from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ReviewModalContent from 'components/explore/pharmacies/branch/ReviewModalContent'

export default function ReviewAccordion(props) {
  const [expanded, setExpanded] = React.useState(false)
  const {pharmacy} = props

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <Accordion
      expanded={expanded === 'panel1'}
      onChange={handleChange('panel1')}
      style={{boxShadow: 'none', borderBottom: '1px solid rgba(0, 0, 0, 0.12)'}}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{p: 0, m: 0}}
      >
        <Typography sx={{width: '70%', flexShrink: 0, color: 'primary.main'}}>
          Read the Reviews
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <ReviewModalContent pharmacy={pharmacy} />
      </AccordionDetails>
    </Accordion>
  )
}

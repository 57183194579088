import {styled} from '@mui/material/styles'

const LogoWrapper = styled('div')(({theme}) => ({
  padding: theme.spacing(0, 1),
  height: '100%',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}))

export default LogoWrapper

import NotificationCss from '../notification.module.css'
import {Typography, Box, Avatar, Link} from '@mui/material'

function PostLikeNotification(props) {
  const {notification} = props
  const time = new Date(props.notification.timestamp).toDateString()
  const postUrl = notification?.data?.data?.post?.post_id
    ? `/post/${notification?.data?.data?.post?.post_id}`
    : '#'
  return (
    <Link
      href={postUrl}
      underline="none"
      sx={{display: 'flex', justifyContent: 'space-between'}}
    >
      <Box sx={{display: 'flex', gap: 2}}>
        <Avatar
          src={notification?.data?.data?.sender?.profile_image || null}
          alt="{notification?.data?.data?.sender?.full_name}"
        />
        <Box>
          <Typography>{notification.verb}</Typography>
          <Typography variant="caption" color="secondary">
            {notification.time_ago}
          </Typography>
        </Box>
      </Box>
      <Box>
        {notification.unread && (
          <div className={NotificationCss.unreadIndicator}></div>
        )}
      </Box>
    </Link>
  )
}

export default PostLikeNotification

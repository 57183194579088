import React from 'react'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import DrawerList from './DrawerList'

const drawerWidth = 240

export default function LeftDrawer(props) {
  const {openDrawer, toggleDrawer} = props
  return (
    <SwipeableDrawer
      anchor={'left'}
      open={openDrawer}
      onClose={toggleDrawer}
      onOpen={toggleDrawer}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
    >
      <DrawerList toggleDrawer={toggleDrawer} />
    </SwipeableDrawer>
  )
}

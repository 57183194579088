import React from 'react'
import Loadable from '@loadable/component'

import Loading from '../../components/base/ComponentLoading'

const HomeComponent = Loadable(() => import('./Home'))

function Home() {
  return <HomeComponent fallback={<Loading />} />
}

export default Home

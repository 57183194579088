import React from 'react'
import Reply from './Reply'

export default function ListOfReplies(props) {
  const {replies} = props
  const listOfReplies = replies.map((reply, index) => {
    return (
      <div key={index}>
        <Reply reply={reply} />
      </div>
    )
  })
  return listOfReplies
}

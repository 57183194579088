import React from 'react'
import Answer from './Answer'

export default function AnswerList(props) {
  const {answers} = props

  const renderedAnswers = answers.map((answer, index) => {
    return (
      <div key={index}>
        <Answer answer={answer} />
      </div>
    )
  })

  return renderedAnswers
}

import {Grid, Box, Typography} from '@mui/material'
import pharmacyIconImage from '../../../assets/images/pharmacies/pharmacy_icon.png'
import Avatar from '@mui/material/Avatar'

export default function PharmacySearchResult(props) {
  const {results} = props

  const resultDiv = results.map((pharmacy, index) => {
    return (
      <Grid item xs={12} md={4} key={index} sx={{mt: 2}}>
        <a href={`/page/pharmacy/${pharmacy.slug}/`}>
          <Box sx={{display: 'flex', mb: 3, gap: 2}}>
            <Avatar
              alt="Remy Sharp"
              src={pharmacy.company.logo || pharmacyIconImage}
              sx={{
                width: 90,
                height: 90,
                border: '2px solid #ff7e99',
              }}
            />
            <Box>
              <Typography
                variant={'h6'}
                sx={{fontSize: 16}}
                color="primary.main"
              >
                {pharmacy.trading_name} ({pharmacy.city}){' '}
              </Typography>
              <Typography sx={{fontSize: 11}} color="text.primary">
                {pharmacy.company.name}
                {pharmacy.gphc_registration_number && (
                  <>
                    <br />
                    <strong>GPHC Reg Number:</strong>{' '}
                    {pharmacy.gphc_registration_number}
                  </>
                )}
                <br />
                {pharmacy.address_line_1},<br /> {pharmacy.post_code}
              </Typography>
            </Box>
          </Box>
        </a>
      </Grid>
    )
  })

  return (
    <Grid container columns={12} spacing={2}>
      {resultDiv}
    </Grid>
  )
}

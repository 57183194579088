import React from 'react'
import {Avatar, Box, Typography, Link} from '@mui/material'
import FollowButton from 'components/account/search/FollowButton'
import VerifiedIcon from '@mui/icons-material/Verified'
export default function FollowingSuggestedItem(props) {
  const {item} = props
  return (
    <Box sx={styles.container}>
      <Box sx={styles.nameInfo}>
        <Avatar
          src={item.profile.profile_image}
          alt={item.full_name}
          sx={styles.avatar}
        />
        <Link href={`/account/${item.slug}`} underline="none" color="inherit">
          <Typography variant="body2">
            {item.full_name.length >= 21
              ? `${item.full_name.slice(0, 18)}...`
              : item.full_name}
            {item.verified && <VerifiedIcon fontSize="small" color="primary" />}
          </Typography>
        </Link>
        <Typography variant="caption" color="primary.light">
          {item.relationship_status === 'follows you'
            ? item.relationship_status
            : item.profession}
        </Typography>
      </Box>
      <FollowButton item={item} buttonStyle={'text'} />
    </Box>
  )
}

const styles = {
  container: {
    my: 2,
    display: 'flex',
    justifyContent: 'space-between',
  },
  nameInfo: {
    display: 'flex',
    gap: 1,
    maxWidth: '270px',
    flexWrap: 'wrap',
  },
  avatar: {
    width: 20,
    height: 20,
  },
  button: {
    textTransform: 'none',
  },
}

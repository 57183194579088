import React from 'react'
import {Box, Button, Typography, Modal, IconButton} from '@mui/material'
import Lottie from 'lottie-react'
import Rating from 'assets/lottie/rating.json'
import AgencyReviewForm from '../form/AgencyReviewForm'
import CloseIcon from '@mui/icons-material/Close'

export default function ReviewModal(props) {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const {agency} = props

  const modalCloseButton = (
    <Box sx={style.closeIconContainer}>
      <IconButton onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    </Box>
  )

  const modalContainer = (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style.modal}>
        {modalCloseButton}
        <AgencyReviewForm agency={agency} handleClose={handleClose} />
      </Box>
    </Modal>
  )
  return (
    <>
      <Box
        sx={{
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          position: 'absolute',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Box sx={{width: '300px', height: '300px'}}>
            <Lottie animationData={Rating} height={20} width={20} />
          </Box>
          <Box sx={{textAlign: 'center'}}>
            <Typography gutterBottom sx={{mb: 2}} variant="h6">
              Share your experience with other professionals
            </Typography>
            <Button variant="outlined" onClick={handleOpen}>
              Add a review
            </Button>
            {modalContainer}
          </Box>
        </Box>
      </Box>
    </>
  )
}

const style = {
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
      md: '70vw',
      sm: '95vw',
      xs: '95vw',
    },
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowY: 'scroll',
    maxHeight: '100vh',
  },
  closeIconContainer: {
    float: 'right',
  },
}

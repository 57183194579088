import React from 'react'
import Loadable from '@loadable/component'

import Loading from '../../components/base/ComponentLoading'

const UserActivationComponent = Loadable(() => import('./UserActivation'))

function UserActivation() {
  return <UserActivationComponent fallback={<Loading />} />
}

export default UserActivation

import React from 'react'
import Box from '@mui/material/box'
import SecondarySpan from 'components/layout/common/typography/SecondarySpan'
import PaddedTypography from 'components/layout/common/typography/PaddedTypography'

export default function HowLongToBePaid(props) {
  const {pharmacy} = props
  const duration = pharmacy.review_stats.locum.most_common_payment_duration

  return (
    <>
      <Box
        sx={{display: 'flex', width: '100%', justifyContent: 'space-between'}}
      >
        <PaddedTypography variant="body1">
          Average Time to Receive Payment:
        </PaddedTypography>
        <PaddedTypography variant="body1">
          <SecondarySpan>
            {duration[0]?.count > 0 ? (
              <>{duration[0]?.how_long_to_be_paid}</>
            ) : (
              '---'
            )}
          </SecondarySpan>
        </PaddedTypography>
      </Box>
    </>
  )
}

import React from 'react'
import {BrowserRouter as Router, Switch} from 'react-router-dom'
import {ToastProvider} from 'react-toast-notifications'
import routes from './config/routes.js'
import {AuthProvider} from './context/context'
import AppRoute from './components/base/AppRoute'
import theme from './config/theme'
import {ThemeProvider} from '@mui/material/styles'
import ReactGA from 'react-ga4'
import CustomeSnackbar from './components/layout/common/snackbar/CustomeSnackbar.jsx'

const TRACKING_ID = 'G-9V472PGJ2Z' // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID)

export default function App() {
  React.useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: `${window.location.pathname}${window.location.search}`,
    })
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <ToastProvider
        placement="bottom-center"
        components={{
          Toast: toastProps => (
            <CustomeSnackbar
              severity={toastProps.appearance}
              message={toastProps.children}
              timeOut={toastProps.timeOut}
            />
          ),
        }}
      >
        <AuthProvider>
          <Router>
            <Switch>
              {routes.map(route => (
                <AppRoute
                  key={route.path}
                  path={route.path}
                  component={route.component}
                  isPrivate={route.isPrivate}
                  title={route.title}
                  navbarMode={route.navbarMode}
                  exact={true}
                />
              ))}
            </Switch>
          </Router>
        </AuthProvider>
      </ToastProvider>
    </ThemeProvider>
  )
}

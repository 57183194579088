import React from 'react'
import Loadable from '@loadable/component'

import Loading from '../../components/base/ComponentLoading'

const RegisterComponent = Loadable(() => import('./Register'))

function Register() {
  return <RegisterComponent fallback={<Loading />} />
}

export default Register

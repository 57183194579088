import React from 'react'
import Review from './components/Review'

export default function LocumReviews(props) {
  const {pharmacy} = props
  const LocumReviews = pharmacy.reviews.locum.map((review, index) => {
    return <Review key={index} review={review} reviewType={'Locum'} />
  })
  return <>{pharmacy.reviews.locum.length > 0 ? LocumReviews : null}</>
}

import React from 'react'
import {Box, Button} from '@mui/material'
import LikeButton from './LikeButton'

export default function AnswerActions(props) {
  const {answer, setUserSlug, setShowReplyInput, showReplyInput} = props

  const handleReplyClick = () => {
    setShowReplyInput(!showReplyInput)
    setUserSlug('@' + answer.user.slug + ' ')
  }

  const actions = (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'row',
        width: '100%',
        mt: 2,
        pl: 0,
        ml: '-12px',
      }}
    >
      <Button
        variant="text"
        sx={{
          color: 'grey',
          textTransform: 'capitalize',
          fontSize: '14px',
          p: 0,
        }}
        onClick={handleReplyClick}
      >
        Reply
      </Button>
      <LikeButton answer={answer} />
    </Box>
  )

  return <>{actions}</>
}

import React from 'react'
import Loadable from '@loadable/component'

import Loading from '../../components/base/ComponentLoading'

const JobsComponent = Loadable(() => import('./Jobs'))

function Jobs() {
  return <JobsComponent fallback={<Loading />} />
}

export default Jobs

import React from 'react'
import {Box, Typography, Card, Avatar, Link, Chip} from '@mui/material'

export default function PharmacyCarouselItem(props) {
  const {item} = props

  const address = () => {
    if (item.region == 'nan') {
      return (
        item.city +
        ', ' +
        (item.address_line_2 == 'nan' ? '' : item.address_line_2)
      )
    }
    return item.region
  }

  const employedOverallRating =
    item.review_stats.employed.average_overall_rating.overall_rating__avg
  const locumOverallRating =
    item.review_stats.locum.average_overall_rating.overall_rating__avg
  const overallRating =
    employedOverallRating == 'null'
      ? 1
      : employedOverallRating / locumOverallRating == 'null'
      ? 1
      : locumOverallRating
  return (
    <Link
      underline="none"
      color="inherit"
      href={`/page/pharmacy/${item.slug}/`}
    >
      <Card
        variant="outlined"
        sx={{
          display: 'flex',
          gap: 2,
          alignItems: 'flex-start',
          flexDirection: 'column',
          borderRadius: 8,
          '--Card-padding': theme => theme.spacing(2),
          p: 2,
          mr: 2,
          mb: 3,
          height: '240px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            alignItems: 'center',
          }}
        >
          <Avatar
            src={`${item.company.logo}`}
            alt={item.title}
            size="thumbnail"
            sx={{
              width: 50,
              height: 50,
              border: '1px solid black',
              objectFit: 'cover',
            }}
          >
            {item.trading_name.charAt(0)}
          </Avatar>
          <Box sx={{whiteSpace: 'nowrap'}}>
            <Typography fontWeight="md">{item.trading_name}</Typography>
            <Typography variant="caption">{address()}</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            whiteSpace: 'nowrap',
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Box>
            <Chip label={item.premises_type} size="small" color="primary" />
          </Box>
          <Typography variant="caption" color="secondary">
            Overall rating: {overallRating.toFixed(1)}/10
            <br />
            {item.get_last_reviewed}
          </Typography>
          <Typography
            variant="caption"
            sx={{whiteSpace: 'pre-wrap', maxHeight: '60px', overflow: 'hidden'}}
          >
            {item.latest_review.review.slice(0, 100) + '...'}
          </Typography>
        </Box>
      </Card>
    </Link>
  )
}

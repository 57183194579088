import React from 'react'
import {Link, Box} from '@mui/material'
import PlaceIcon from '@mui/icons-material/Place'

export default function LinkToMap(props) {
  const {path} = props

  return (
    <>
      <Link sx={{display: 'inline-block'}} href={path}>
        <Box sx={{display: 'flex', justifyItems: 'center'}}>
          <PlaceIcon /> See Map
        </Box>
      </Link>
    </>
  )
}

import React from 'react'
import {Container} from '@mui/material'
import QuestionGridWithCategory from 'components/clinicalQuestions/QuestionGrid/QuestionGridWithCategory'

export default function ClinicalQuestions() {
  return (
    <div className="main">
      <div className="main__content">
        <div className="wrapper" style={{backgroundColor: '#F4F4F4'}}>
          <Container maxWidth="lg">
            <QuestionGridWithCategory />
          </Container>
        </div>
      </div>
    </div>
  )
}

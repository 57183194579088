import {useState, useEffect, useMemo} from 'react'
import {useLocation} from 'react-router-dom'
import {Divider, Button, Container, Typography} from '@mui/material'
import PharmacySearchResult from 'components/account/search/PharmacySearchResult'
import Api from 'services/api.service'
import {apiUrl} from '../../config/vars'

function useQuery() {
  const {search} = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

export default function PharmacySearchResults() {
  const [query, setQuery] = useState('')
  const [pharmacies, setPharmacies] = useState([])
  const [pharmacyCount, setPharmacyCount] = useState(0)
  const [resultMeta, setResultMeta] = useState({
    count: 0,
    next: null,
  })
  let urlQuery = useQuery()

  const searchPharmacies = (q = '') => {
    Api.get(`${apiUrl}api/business/pharmacy/search/?q=${query || q}`)
      .then(response => {
        setResultMeta(response.data)
        setPharmacies(response.data.results)
        setPharmacyCount(response.data.count)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const loadMore = async () => {
    const {next} = resultMeta
    if (next) {
      const response = await Api.get(next)
      setPharmacies([...pharmacies, ...response.data.results])
      setResultMeta(response.data)
    }
  }

  useEffect(() => {
    let search = urlQuery.get('q')
    searchPharmacies(search)
    setQuery(search)
  }, [])

  return (
    <div className="main">
      <div className="main__content">
        <Container sx={{mt: 2}}>
          {pharmacyCount > 0 && (
            <Typography variant="h6">
              {pharmacyCount} results found for &apos;{query}&apos;
            </Typography>
          )}
          {pharmacies.length > 0 ? (
            <div style={{marginBottom: '160px'}}>
              <PharmacySearchResult results={pharmacies} />
              <Divider hidden />
              {resultMeta.next && (
                <Button onClick={() => loadMore()}>LoadMore</Button>
              )}
            </div>
          ) : (
            <div>Nothing was found!</div>
          )}
        </Container>
      </div>
    </div>
  )
}

import React from 'react'
import {Box, Link} from '@mui/material'
import FeedPostUserAvatar from './FeedPostUserAvatar'
import ShortenCaption from './ShortenCaption'
import SharedLink from './SharedLink'

const styles = {
  container: {
    my: 2,
    p: 5,
    border: '1px solid',
    borderColor: 'primary.lightBackground',
    borderRadius: '25px',
    '&:hover': {
      backgroundColor: 'primary.lightBackground',
    },
  },
}
export default function FeedPostSharingItem(props) {
  const {item} = props
  const shared = item.sharing_post

  return (
    <Link href={`/post/${shared.id}`} underline="none">
      {item?.sharing_post && (
        <Box sx={styles.container}>
          <FeedPostUserAvatar item={shared} />
          <Box sx={{my: 3}}>
            <Box sx={{whiteSpace: 'pre-line'}}>
              <ShortenCaption fullCaption={shared.caption} />
            </Box>
            {shared.shared_link ? <SharedLink item={shared} /> : null}
            {/* {item.image.length > 0 ? <FeedPostImages item={item} /> : null} */}
          </Box>
        </Box>
      )}
    </Link>
  )
}

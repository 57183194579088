import React from 'react'
import FeedCss from '../feed.module.css'

export default function ImageUploadButton(props) {
  const {setBase65Images, setPreviewImageFiles} = props

  // convert image to base64
  const convertImageToBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }

  // function to create object URL of images
  const createObjectURL = file => {
    return URL.createObjectURL(file)
  }

  // fileUploadHandler
  const fileUploadHandler = e => {
    const files = e.target.files
    const urlFiles = []

    const promises = []
    for (let i = 0; i < files.length; i++) {
      promises.push(convertImageToBase64(files[i]))
      urlFiles.push(createObjectURL(files[i]))
    }
    Promise.all(urlFiles)
      .then(urls => {
        setPreviewImageFiles(previewImageFiles => [
          ...previewImageFiles,
          ...urls,
        ])
      })
      .catch(error => {
        console.log(error)
      })
    Promise.all(promises)
      .then(data => {
        setBase65Images(base64Images => [...base64Images, ...data])
      })
      .catch(error => {
        console.log(error)
      })
  }

  return (
    <>
      <div className={FeedCss.FileInputContainer}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-camera"
        >
          <path d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z"></path>
          <circle cx="12" cy="13" r="4"></circle>
        </svg>
        <span>Media</span>
        <input
          type="file"
          accept="image/*"
          className={FeedCss.fileInput}
          onChange={fileUploadHandler}
        ></input>
      </div>
    </>
  )
}

import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import {NavLink} from 'react-router-dom'

const DesktopDropdown = ({
  anchorEl,
  handleMenuClose,
  menuId,
  isMenuOpen,
  logout,
}) => {
  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem>
        <NavLink to="/account/">My account</NavLink>
      </MenuItem>
      <MenuItem onClick={() => logout()}>Logout</MenuItem>
    </Menu>
  )
}

export default DesktopDropdown

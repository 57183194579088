import React from 'react'
import LocumReviews from './review-modal-content/LocumReviews'
import EmployedReviews from './review-modal-content/EmployedReviews'
import ExploreCss from '../../Explore.module.css'

export default function ReviewModalContent(props) {
  const {pharmacy} = props
  return (
    <div className={ExploreCss.reviewTabMenuContainer}>
      <LocumReviews pharmacy={pharmacy} />
      <EmployedReviews pharmacy={pharmacy} />
    </div>
  )
}

import axios from 'axios'
import {apiUrl} from './../config/vars'
import jwt_decode from 'jwt-decode'
import dayjs from 'dayjs'

let isRefreshing = false
let refreshSubscribers = []

function subscribeTokenRefresh(cb) {
  refreshSubscribers.push(cb)
}

function onRefreshed(token) {
  refreshSubscribers.map(cb => cb(token))
}

const Api = axios.create({
  baseURL: apiUrl,
})

Api.interceptors.response.use(
  response => response,
  error => {
    const {
      config,
      response: {status},
    } = error
    const originalRequest = config

    if (status === 401) {
      if (!isRefreshing) {
        isRefreshing = true

        const refreshToken = localStorage.getItem('refreshToken')
        const data = {
          refresh: refreshToken,
        }
        Api.post('/api/token/refresh/', data)
          .then(response => {
            isRefreshing = false
            onRefreshed(response.data.access)
            localStorage.setItem('accessToken', response.data.access)
          })
          .catch(error => {
            window.location = '/login'
            localStorage.setItem('accessToken', '')
            localStorage.setItem('refreshToken', '')
          })
      } else {
        window.location = '/login'
        localStorage.setItem('accessToken', '')
        localStorage.setItem('refreshToken', '')
      }

      return new Promise(resolve => {
        subscribeTokenRefresh(token => {
          originalRequest.headers['Authorization'] = 'Bearer ' + token
          resolve(Api(originalRequest))
        })
      })
    } else {
      return Promise.reject(error)
    }
  },
)

Api.interceptors.request.use(config => {
  const accessToken = localStorage.getItem('accessToken')

  if (accessToken) {
    config.headers.Authorization = 'Bearer ' + accessToken
    const user = jwt_decode(accessToken)
    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1

    if (!isExpired) return config
    const refreshToken = localStorage.getItem('refreshToken')
    axios
      .post(`${apiUrl}api/token/refresh/`, {
        refresh: refreshToken,
      })
      .then(response => {
        localStorage.setItem('accessToken', response.data.access)
        localStorage.setItem('refreshToken', response.data.refresh)
        config.headers.Authorization = `Bearer ${response.data.access}`
        return config
      })
  }
  return config
})

export default Api

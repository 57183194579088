import React from 'react'
import {Container, Typography} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

function Error() {
  return (
    <div className="main">
      <div className="main__content">
        <div className="wrapper">
          <Container>
            <Typography align={'center'} variant="h5">
              <SearchIcon fontSize="large" />
              <br />
              Sorry, we can&apos;t find the page you were looking for.
            </Typography>
          </Container>
        </div>
      </div>
    </div>
  )
}

export default Error

import TextField from '@mui/material/TextField'
import {Button} from '@mui/material'
import {useState} from 'react'

import Api from 'services/api.service'
import {apiUrl} from 'config/vars'
import {useToasts} from 'react-toast-notifications'

const ProfileInfoUpdate = props => {
  const {addToast} = useToasts()
  const user = props.user
  const [bio, setBio] = useState(user.profile.bio)
  const [inputField, setInputField] = useState({bio: ''})

  const UpdateBio = () => {
    // setUploadLoading(true);

    const data = new FormData()

    data.append('bio', inputField.bio)

    Api.put(`${apiUrl}user/profile-update/${user.profile.id}`, data)
      .then(response => {
        addToast('Profile has been updated successfully.', {
          appearance: 'success',
          autoDismiss: true,
        })
        setBio(response.data.bio)
      })
      .catch(error => {
        console.log(error)
        addToast(error.response.data.detail, {
          appearance: 'error',
          autoDismiss: true,
        })
      })
  }

  const inputsHandler = e => {
    setInputField({[e.target.name]: e.target.value})
  }

  return (
    <form className="mt-20">
      <TextField
        fullWidth
        label="Bio"
        name="bio"
        id="fullWidth"
        placeholder="Tell us more about you..."
        defaultValue={bio}
        onChange={inputsHandler}
        multiline
        rows={2}
        maxRows={4}
        variant="outlined"
      />
      <Button onClick={UpdateBio} variant="outlined" sx={{my: 2}}>
        Update
      </Button>
    </form>
  )
}

export default ProfileInfoUpdate

import React from 'react'
import {Box, Typography, Button} from '@mui/material'
import GoogleMapComp from 'components/layout/common/maps/GoogleMap'
import FullAddress from '../branch-details/components/FullAddress'
import HotelBookingMap from './HotelBookingMap'
export default function PharmacyMap(props) {
  const [toggleView, setToggleView] = React.useState(true)
  const {pharmacy} = props
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        my: 4,
        maxWidth: 550,
        width: '100%',
        gap: 2,
      }}
    >
      <Typography x={{mb: 2}}>
        <FullAddress pharmacy={pharmacy} />
      </Typography>
      <Typography x={{mb: 2, fontWeight: 600}}>
        Book your stay near this pharmacy
      </Typography>
      <Button variant={'outlined'} onClick={() => setToggleView(!toggleView)}>
        {toggleView ? 'Search for hotel' : 'See the map'}
      </Button>
      {toggleView ? (
        <GoogleMapComp
          lat={parseFloat(pharmacy.lat)}
          lng={parseFloat(pharmacy.lon)}
        />
      ) : (
        <HotelBookingMap pharmacy={pharmacy} />
      )}
    </Box>
  )
}

import React from 'react'
import Loadable from '@loadable/component'

import Loading from '../../components/base/ComponentLoading'

const ResetPasswordComponent = Loadable(() => import('./ResetPassword'))

function ResetPassword() {
  return <ResetPasswordComponent fallback={<Loading />} />
}

export default ResetPassword

import React from 'react'
import {Avatar} from '@mui/material'

function stringToColor(string) {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

function stringAvatar(name = 'User Account') {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  }
}
export default function ProfileMenuIcon(props) {
  const {user} = props
  const imageSize = 30
  if (user.profile && user.profile.profile_image) {
    return (
      <Avatar
        alt={user.full_name}
        src={user.profile.profile_image || null}
        sx={{width: imageSize, height: imageSize}}
      />
    )
  } else {
    return (
      <>
        {user && (
          <Avatar
            {...stringAvatar(user.full_name)}
            sx={{width: imageSize, height: imageSize}}
          />
        )}
      </>
    )
  }
}

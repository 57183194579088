import React from 'react'
import {Typography, Link} from '@mui/material'

export default function AnswerText(props) {
  const {answer} = props

  // a function to highlight anything that starts with @ in <a></a>
  const highlightUsernames = answerText => {
    const regex = /(@\S+)/g
    const matches = answerText.match(regex)
    if (matches) {
      matches.forEach(match => {
        const username = match.slice(1)
        answerText = answerText.replace(
          match,
          `<a href="/account/${username}">${match}</a>`,
        )
      })
    }
    return {__html: answerText}
  }
  return (
    <>
      <Link href={`/account/${answer.user.slug}`}>
        <Typography variant="body2" sx={{fontWeight: 600}}>
          {answer.user.full_name}
        </Typography>
      </Link>
      <Typography variant="caption">{answer.time_ago}</Typography>
      <Typography
        variant="body2"
        sx={{
          whiteSpace: 'pre-wrap',
          mt: 1,
          textOverflow: 'ellipsis',
          wordBreak: 'break-word',
        }}
        dangerouslySetInnerHTML={highlightUsernames(answer.answer)}
      ></Typography>
    </>
  )
}

import * as React from 'react'
import {NavLink} from 'react-router-dom'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Image from 'mui-image'
import Badge from '@mui/material/Badge'
import MenuIcon from '@mui/icons-material/Menu'
import NotificationsIcon from '@mui/icons-material/Notifications'
import MoreIcon from '@mui/icons-material/MoreVert'
import Logo from '../../assets/images/logo.png'
import {AuthContext} from '../../context/context'
import UnreadNotificationsCount from '../notifications/UnreadNotificationsCount'
import SearchBar from './search/SearchBar'
import LogoWrapper from './logo/LogoWrapper'
import DesktopDropdown from './menu-components/dropdown/DesktopDropdown'
import LeftDrawer from './menu-components/drawer/LeftDrawer'
import MobileDropDownMenu from './menu-components/dropdown/MobileDropdownMenu'
import ProfileMenuIcon from './menu-components/ProfileMenuIcon'

export default function PrimaryNavigation() {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null)
  const {auth, setUserData, setAuthData, user} = React.useContext(AuthContext)
  const isMenuOpen = Boolean(anchorEl)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)
  const [openDrawer, setOpenDrawer] = React.useState(false)

  // const [, setAppState] = React.useContext(AppContext);

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer)
  }

  const logout = () => {
    setUserData(null)
    setAuthData(null)
    localStorage.setItem('accessToken', '')
    localStorage.setItem('refreshToken', '')
  }

  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    handleMobileMenuClose()
  }
  let activeStyle = {
    textDecoration: 'none',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }

  const handleMobileMenuOpen = event => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const menuId = 'primary-search-account-menu'

  const mobileMenuId = 'primary-search-account-menu-mobile'

  return (
    <Box sx={{flexGrow: 1}}>
      <AppBar
        position="fixed"
        sx={{bgcolor: 'common.black', color: 'common.white'}}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{mr: 1}}
          >
            <MenuIcon />
          </IconButton>
          <LogoWrapper>
            <NavLink to={auth.accessToken ? '/' : '/home'} exact={true}>
              <Image src={Logo} alt="Syrkle" width="70px" fit="scale-down" />
            </NavLink>
          </LogoWrapper>
          <SearchBar />

          <Box sx={{flexGrow: 1}} />
          <Box sx={{display: {xs: 'none', md: 'flex'}}}>
            <NavLink to="/notifications/" style={activeStyle}>
              <IconButton
                size="large"
                aria-label={`show ${UnreadNotificationsCount()} new notifications`}
                color="inherit"
              >
                <Badge badgeContent={UnreadNotificationsCount()} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            </NavLink>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <ProfileMenuIcon user={user} />
            </IconButton>
          </Box>
          <Box sx={{display: {xs: 'flex', md: 'none'}}}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <LeftDrawer openDrawer={openDrawer} toggleDrawer={toggleDrawer} />
      <MobileDropDownMenu
        mobileMoreAnchorEl={mobileMoreAnchorEl}
        mobileMenuId={mobileMenuId}
        menuId={menuId}
        handleMenuClose={handleMenuClose}
        isMobileMenuOpen={isMobileMenuOpen}
        logout={logout}
        UnreadNotificationsCount={UnreadNotificationsCount}
        handleProfileMenuOpen={handleProfileMenuOpen}
        user={user}
      />
      <DesktopDropdown
        handleMenuClose={handleMenuClose}
        anchorEl={anchorEl}
        menuId={menuId}
        isMenuOpen={isMenuOpen}
        logout={logout}
      />
    </Box>
  )
}

import React from 'react'
import {Box, Typography, Link, Divider, Avatar} from '@mui/material'
import CircularProgressWithLabel from 'components/layout/common/progressbar/CircularProgressWithLabel'
import StarRating from 'components/layout/common/starrating/StarRating'

export default function Item(props) {
  const {agency} = props
  const roundedAvgRating =
    agency.stats.average_overall_rating.rounded_avg_overal_rating
  const percentageHighestRate =
    agency.stats.gets_the_highest_rate.perc_highest_rate_yes
  const highestRateTotalCount = agency.stats.gets_the_highest_rate.total_count

  const nameAndRating = (
    <Box sx={styles.nameAndRatingContainer}>
      <Typography variant={'body1'} color="secondary" gutterBottom>
        {agency.name}
      </Typography>
      <StarRating score={roundedAvgRating} fontSize={'small'} />
    </Box>
  )

  const stats = (
    <Box sx={styles.statsContainer} id="stats">
      <CircularProgressWithLabel value={percentageHighestRate} />
      <Typography variant="caption">
        of {highestRateTotalCount} reviews suggests this agency offers a high
        rate
      </Typography>
    </Box>
  )

  return (
    <Link
      href={`/page/agency/${agency.slug}/`}
      sx={styles.linkStyle}
      underline="none"
    >
      <Box sx={styles.mainContainer}>
        <Box sx={styles.nameAndAvatar}>
          <Avatar src={agency.logo} alt={agency.name} />
          {nameAndRating}
        </Box>
        {stats}
      </Box>
      <Divider />
    </Link>
  )
}

const styles = {
  linkStyle: {
    '&:hover': {backgroundColor: 'greyBackground.main'},
  },
  mainContainer: {
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    '&:hover': {
      backgroundColor: 'greyBackground.main',
    },
    '& > #stats': {
      // transition: 'all 1s ease-in-out',
      transition: 'opacity 200ms, display 200ms',
      opacity: 0,
    },
    '&:hover #stats': {
      display: 'flex',
      opacity: 1,
    },
  },
  nameAndAvatar: {
    display: 'flex',
    gap: 1,
    alignItems: 'center',
  },
  nameAndRatingContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  statsContainer: {
    my: 1,
    display: 'none',
    gap: 2,
    alignItems: 'center',
    transition: 'opacity 200ms, display 200ms',
  },
}

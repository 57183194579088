import React from 'react'
import {Typography, Box, Link} from '@mui/material'
export default function CopyRightFooter() {
  // get this year date
  const currentYear = new Date().getFullYear()
  return (
    <Box sx={{my: 3, display: 'flex', gap: 2.5, flexWrap: 'wrap'}}>
      <Link href="/page/privacy-policy" underline="none">
        <Typography variant="body2" color="secondary.textMain">
          Privacy Policy
        </Typography>
      </Link>
      <Link href="/page/terms_and_conditions" underline="none">
        <Typography variant="body2" color="secondary.textMain">
          Terms and Conditions
        </Typography>
      </Link>
      <Link href="/page/about" underline="none">
        <Typography variant="body2" color="secondary.textMain">
          About Us
        </Typography>
      </Link>
      <Typography variant="body2" color="secondary.textMain">
        © Copyright {currentYear}{' '}
      </Typography>
    </Box>
  )
}

import React from 'react'
import Api from 'services/api.service'
import {Box, Typography} from '@mui/material'
import FeedItem from '../FeedItem'
import Error from 'pages/error/Error'
import BlockIcon from '@mui/icons-material/Block'
export default function SinglePostContainer(props) {
  const {postId} = props
  const [post, setPost] = React.useState(null)
  const [apiError, setApiError] = React.useState(null)

  const fetchPost = postId => {
    Api.get('/api/news-feed/list/' + postId)
      .then(response => {
        setPost(response.data)
      })
      .catch(error => {
        const {
          config,
          response: {status},
        } = error
        setApiError(status)
      })
  }
  console.log(apiError)

  React.useEffect(() => {
    fetchPost(postId)
  }, [postId])

  return (
    <div>
      {apiError === 404 && <Error />}
      {apiError === 403 && (
        <Box>
          <Typography variant="body1" align={'center'} sx={{pt: '10%'}}>
            <BlockIcon /> <br />
            You don&apos;t have permission to see this post.
          </Typography>
        </Box>
      )}
      {post && <FeedItem item={post} />}
    </div>
  )
}

import React from 'react'
import Loadable from '@loadable/component'

import Loading from '../../components/base/ComponentLoading'

const LoginComponent = Loadable(() => import('./Login'))

function Login() {
  return <LoginComponent fallback={<Loading />} />
}

export default Login

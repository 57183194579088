import * as React from 'react'
import {Box, Typography} from '@mui/material'
import Api from 'services/api.service'
import PharmacyCarouselItem from './PharmacyCarouselItem'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

export default function PharmacyCarousel() {
  const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const fetchRecomendedPharmacy = () => {
    setLoading(true)
    Api.get('/api/business/pharmacy/all/include_reviews')
      .then(response => {
        setData(response.data.results)
        setLoading(false)
      })
      .catch(error => console.error(error))
  }

  React.useEffect(() => {
    fetchRecomendedPharmacy()
  }, [])

  const pharmacyItems = data?.map((item, index) => {
    return <PharmacyCarouselItem item={item} key={index} />
  })

  return (
    <>
      <Box
        sx={{
          maxWidth: '664px',
          mt: 0,
          mx: 'auto',
          mb: {md: '30px'},
        }}
      >
        <Box sx={{display: 'flex', my: 2}}>
          <Typography varinat="h5">Recently reviewed pharmacies</Typography>
        </Box>
        {!loading && <Slider {...settings}>{pharmacyItems}</Slider>}
      </Box>
    </>
  )
}

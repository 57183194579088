import React, {useCallback, useContext, useEffect, useState} from 'react'
import {NavLink} from 'react-router-dom'
import classNames from 'classnames'
import {Icon, Image} from 'semantic-ui-react'
import {AuthContext} from '../../context/context'
import Logo from '../../assets/images/logo.png'
import SyrkleIcon from '../../assets/images/icon.png'
import UnreadNotificationsCount from '../notifications/UnreadNotificationsCount'
import SearchBar from './search/SearchBar'
function NavBar(props) {
  const [mobileMenu, setMobileMenu] = useState(false)
  const {auth, setUserData, setAuthData, user} = useContext(AuthContext)

  const navbarClassNames = classNames('header__navbar', {
    'header__navbar--transparent': props.navbarMode === 'transparent',
  })

  const logout = e => {
    e.preventDefault()
    setUserData(null)
    setAuthData(null)
    localStorage.setItem('accessToken', '')
    localStorage.setItem('refreshToken', '')
  }

  const openResponsiveMenu = () => {
    setMobileMenu(!mobileMenu)
  }

  const handleScroll = useCallback(() => {
    const scrollPosition = window.scrollY
    const header = document.getElementsByClassName('header__navbar')[0]

    if (props.navbarMode === undefined) {
      return
    }

    if (scrollPosition > 0) {
      header.classList.remove('header__navbar--transparent')
    } else {
      header.classList.add('header__navbar--transparent')
    }
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  return (
    <>
      <div className={navbarClassNames}>
        <div>
          <div className="header__inner">
            <div className="header__logo">
              <NavLink to={auth.accessToken ? '/' : '/home'} exact={true}>
                <img src={Logo} alt="Syrkle" />
              </NavLink>
            </div>
            <div className="header__icon">
              <NavLink to={auth.accessToken ? '/' : '/home'} exact={true}>
                <img src={SyrkleIcon} alt="Syrkle" />
              </NavLink>
            </div>

            <div className="header__menu">
              {!auth.accessToken && (
                <>
                  <ul className="header__horizontal__menu">
                    <li>
                      <NavLink to="/login">Login</NavLink>
                    </li>

                    <li>
                      <NavLink to="/register">Register</NavLink>
                    </li>

                    <li>
                      <NavLink to="/page/about">About us</NavLink>
                    </li>
                    {/* 
                  <li>
                    <NavLink to="/page/privacy-policy">Privacy Policy</NavLink>
                  </li> */}
                  </ul>
                </>
              )}
              {auth.accessToken && (
                <>
                  <ul className="header__horizontal__menu">
                    <li>
                      <SearchBar />
                    </li>
                    <li>
                      <NavLink to="/explore">
                        <div className="header__home__circle__icon">
                          <Icon
                            as="i"
                            name="compass"
                            size="large"
                            className="header__home__icon"
                          />
                        </div>
                      </NavLink>
                    </li>

                    <li>
                      <NavLink to="/notifications/">
                        <div className="header__home__circle__icon">
                          <div>
                            <Icon
                              as="i"
                              name="bell"
                              size="large"
                              className="header__home__icon"
                              corner="top right"
                            />
                            <UnreadNotificationsCount />
                          </div>
                        </div>
                      </NavLink>
                    </li>

                    <li>
                      <NavLink to="/account">
                        <div className="header__home__circle__icon">
                          {user.profile ? (
                            <Image
                              src={user.profile.profile_image || null}
                              alt={user.full_name}
                              className="header__feed__avatar_picture"
                            />
                          ) : (
                            <Icon
                              name="user"
                              size="large"
                              className="header__home__icon"
                              corner="top right"
                            />
                          )}
                        </div>
                      </NavLink>
                    </li>

                    <li>
                      <div
                        className="burger_menu"
                        onClick={openResponsiveMenu}
                        aria-label="Menu"
                      >
                        <Icon
                          name="bars"
                          className="header__home__icon"
                          size="large"
                        />
                      </div>
                    </li>
                  </ul>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {auth.accessToken && (
        <ul
          className={
            mobileMenu
              ? 'header_home_accordion_active'
              : 'header__home__accordion'
          }
        >
          <li className="header__home__accordion_item">
            <NavLink to="/jobs">
              <div className="header__home__circle__icon">
                <Icon
                  as="i"
                  name="user md"
                  size="large"
                  className="header__home__icon"
                />
                Jobs
              </div>
            </NavLink>
          </li>
          <li className="header__home__accordion_item">
            <NavLink to="/messages/">
              <div className="header__home__circle__icon">
                <Icon
                  as="i"
                  name="mail"
                  size="large"
                  className="header__home__icon"
                />
                Messages
              </div>
            </NavLink>
          </li>
          <li className="header__home__accordion_item">
            <a href="/logout" onClick={e => logout(e)}>
              <div>
                <Icon
                  as="i"
                  name="log out"
                  size="large"
                  className="header__home__icon"
                />
                Log Out
              </div>
            </a>
          </li>
        </ul>
      )}
    </>
  )
}

export default NavBar

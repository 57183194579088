import React from 'react'
import {TextField, Box, Avatar, Typography, Button} from '@mui/material'
import PostAbstract from './PostAbstract'
import {AuthContext} from 'context/context'
import Api from 'services/api.service'
import {useToasts} from 'react-toast-notifications'

export default function ShareForm(props) {
  const {user} = React.useContext(AuthContext)
  const {addToast} = useToasts()
  const [caption, setCaption] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const {post, setOpen} = props

  const handleSubmit = async () => {
    const data = {
      caption: caption,
      sharing_item_id: post.id,
    }
    setLoading(true)
    try {
      const submitting = await Api.post('/api/news-feed/post/share/', data)
      if (submitting.status >= 200 && submitting.status <= 300) {
        setOpen(false)
        setLoading(false)
        addToast(`Post has been shared`, {
          appearance: 'success',
        })
      } else {
        setOpen(false)
        throw new Error(submitting.statusText)
      }
    } catch (e) {
      addToast(`${e}`, {
        appearance: 'error',
      })
      console.error(e)
    }
  }
  return (
    <Box sx={styles.container}>
      <Box sx={styles.avatar}>
        <Avatar src={user.profile.profile_image} alt={user.full_name} />
        <Typography>{user.full_name}</Typography>
      </Box>
      <Box sx={styles.formContainer}>
        <form>
          <TextField
            id="standard-basic"
            label="Add a caption"
            variant="standard"
            multiline
            rows={2}
            sx={styles.captionForm}
            onChange={value => setCaption(value.target.value)}
          />
          <PostAbstract post={post} />
          <Box sx={styles.footer}>
            <Button
              variant="contained"
              sx={styles.shareButton}
              onClick={handleSubmit}
              disabled={loading}
            >
              Share
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  )
}

const styles = {
  container: {
    display: 'flex',
    gap: 2,
    flexDirection: 'column',
  },
  avatar: {
    display: 'flex',
    gap: 1,
    alignItems: 'center',
  },
  formContainer: {
    pl: {
      xs: 0,
      sm: 2,
      md: 4,
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  captionForm: {
    width: '100%',
  },
  shareButton: {
    borderRadius: '25px',
  },
}

import React from 'react'
import AgencyPageLayout from 'components/organisations/Agencies/PageLayout/AgencyPageLayout'

export default function AgencyPage() {
  return (
    <div className="main">
      <div className="main__content">
        <AgencyPageLayout />
      </div>
    </div>
  )
}

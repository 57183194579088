import React from 'react'
import AddIcon from '@mui/icons-material/Add'
import Fab from '@mui/material/Fab'
import Link from '@mui/material/Link'

export default function AddIconAction() {
  return (
    <Link href="/clinical-questions/add/">
      <Fab color="secondary" aria-label="add" sx={fabStyle}>
        <AddIcon />
      </Fab>
    </Link>
  )
}

const fabStyle = {
  position: 'fixed',
  bottom: 16,
  right: 16,
}

import NotificationItem from './notificationTypes/NotificationItem'
import PostLikeNotification from './notificationTypes/PostLikeNotification'
import FriendRequestNotification from './notificationTypes/FriendRequestNotification'
import FriendRequestAcceptedNotification from './notificationTypes/FriendRequestAccepted'
import ReviewApproved from './notificationTypes/ReviewApproved'
import MessageReceived from './notificationTypes/MessageReceived'
import QuestionAnswered from './notificationTypes/QuestionAsnwered'

function NotificationManager(props) {
  const {notification} = props
  console.log(notification)
  switch (notification.actor_content_type) {
    case 'clinical | clinical answers':
      return <QuestionAnswered notification={notification} />
    case 'newsfeed | like':
      return <PostLikeNotification notification={notification} />
    case 'friends | friend request':
      return <FriendRequestNotification notification={notification} />
    case 'friends | friendship':
      return <FriendRequestAcceptedNotification notification={notification} />
    case 'postman | message':
      return <MessageReceived notification={notification} />
    case 'employer | locum pharmacy review':
      return <ReviewApproved notification={notification} />
    default:
      return <NotificationItem notification={notification} />
  }
}

export default NotificationManager

import React from 'react'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

export default function ListOfFacilities(props) {
  const {pharmacy} = props
  const [searchedService, setSearchedService] = React.useState('')
  const [showAll, setShowAll] = React.useState(5)

  const filtered = pharmacy.facilities.slice(0, showAll).filter(service => {
    return service.name.toLowerCase().includes(searchedService.toLowerCase())
  })
  const facilities = filtered.map(facility => {
    const chipIcon = facility.value === 'Yes' ? <CheckIcon /> : <CloseIcon />
    return <Chip label={facility.name} key={facility.id} icon={chipIcon} />
  })
  const handleChange = e => {
    setSearchedService(e.target.value)
  }
  return (
    <>
      <Box sx={{mb: 3, mt: -2}}>
        <TextField
          id="outlined-basic"
          label="Search"
          variant="standard"
          onChange={e => handleChange(e)}
        />
      </Box>
      <Stack direction="row" sx={{flexWrap: 'wrap', gap: 1}}>
        {facilities}
        {showAll < 100 && (
          <Chip
            label="SHOW ALL"
            color="primary"
            clickable
            variant="outlined"
            onClick={() => setShowAll(200)}
          />
        )}
      </Stack>
    </>
  )
}

import React from 'react'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import {useToasts} from 'react-toast-notifications'
import ImageUploadButton from 'components/feed/newPost/ImageUploadButton'
import PreviewImage from 'components/feed/newPost/PreviewImage'
import CategoriesAutoComplete from './CategoriesAutoComplete'
import Api from 'services/api.service'

export default function AddClinicalQuestionFrom() {
  const {addToast} = useToasts()
  const [base64Images, setBase65Images] = React.useState([])
  const [previewImageFiles, setPreviewImageFiles] = React.useState([])
  const [category, setSelectedCategory] = React.useState()
  const [question, setQuestion] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)

  const removeAllImage = () => {
    setPreviewImageFiles([])
    setBase65Images([])
  }

  const handleImageUpload = async i => {
    const formData = new FormData()
    formData.append('image_base64', i)
    // let newImageIds = inputField.image_ids;
    const api_call = Api.post(`api/clinical-questions/images/add/`, formData)
      .then(res => {
        console.log(res.data)
        return res.data.id
      })
      .catch(error => {
        console.error(error)
        addToast('There was a proble with uploading your photo!', {
          appearance: 'error',
          autoDismiss: true,
        })
      })
    return Promise.resolve(api_call)
  }

  const SubmitQuestion = async (image_ids = []) => {
    let data = {
      question: question,
      category_ids: [category?.id],
      image_ids: [],
    }

    if (image_ids.length > 0) {
      data = {...data, image_ids}
    }
    Api.post(`api/clinical-questions/questions/`, data)
      .then(res => {
        setIsLoading(false)
        addToast('New question has been posted on Syrkle.', {
          appearance: 'success',
          autoDismiss: true,
        })
        // redirect
        window.location.replace(`/clinical-questions/${res.data.id}`)
      })
      .catch(error => {
        console.log(error)
        addToast('something went wrong!', {
          appearance: 'error',
          autoDismiss: true,
        })
        setIsLoading(false)
      })

    return Promise.resolve(1)
  }

  const clearForm = () => {
    setQuestion('')
    removeAllImage()
  }

  const handleSubmition = async () => {
    setIsLoading(true)
    let promises = []
    if (base64Images.length >= 0) {
      base64Images.forEach(async i => {
        promises.push(handleImageUpload(i))
      })
      Promise.all(promises)
        .then(values => {
          SubmitQuestion(values)
        })
        .then(() => {
          clearForm()
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      await SubmitQuestion()
      clearForm()
    }
  }

  return (
    <form>
      <Container>
        <Box sx={{p: 2, maxWidth: 600, m: '0 auto'}}>
          <TextField
            fullWidth
            id="outlined-basic"
            label="Question"
            variant="outlined"
            multiline
            minRows={5}
            value={question}
            onChange={e => setQuestion(e.target.value)}
            sx={{my: 3}}
          />
          <CategoriesAutoComplete
            category={category}
            setSelectedCategory={setSelectedCategory}
          />
          {previewImageFiles.length > 0 && (
            <Box sx={{my: 2}}>
              <PreviewImage
                files={previewImageFiles}
                removeAllImage={removeAllImage}
              />
            </Box>
          )}
          <Box sx={{display: 'flex', my: 2, flex: '1 4'}}>
            <ImageUploadButton
              setBase65Images={setBase65Images}
              setPreviewImageFiles={setPreviewImageFiles}
            />
            <Button
              variant="contained"
              color="primary"
              sx={{width: {md: '200px', sm: '100%'}, flex: 1}}
              disabled={(isLoading, question === '')}
              onClick={handleSubmition}
            >
              Post
            </Button>
          </Box>
        </Box>
      </Container>
    </form>
  )
}

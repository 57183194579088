import React from 'react'
import Box from '@mui/material/box'
import {Typography} from '@mui/material'
import ListOfServices from './services-facilities/ListOfServices'
import ListOfFacilities from './services-facilities/ListOfFacilities'
import NhsAccreditation from 'components/explore/pharmacies/NhsAccreditation'

export default function ServicesAndFacilities(props) {
  const {pharmacy} = props

  return (
    <>
      <Typography variant="h6">Services</Typography>
      <Box sx={{my: 4}}>
        <ListOfServices pharmacy={pharmacy} />
      </Box>
      <Typography variant="h6">Facilities</Typography>
      <Box sx={{my: 4}}>
        <ListOfFacilities pharmacy={pharmacy} />
      </Box>
      <NhsAccreditation />
    </>
  )
}

import Api from 'services/api.service'

export async function fetchAllAgencies(nextUrl = 'api/business/agency/list/') {
  // Fetched all peginations
  const response = await Api.get(nextUrl)
  const data = response.data.results
  if (response.data.next) {
    const nextPageData = await fetchAllAgencies(response.data.next)
    return [...data, ...nextPageData]
  }

  return data
}

export async function fetchAgenciesFirstPage(
  endPoint = 'api/business/agency/list/',
) {
  // only fetches the given endpoint
  const response = await Api.get(endPoint)
  return response
}

export async function fetchSingleAgency(slug) {
  const response = Api.get(`api/business/agency/list/${slug}/`)
  return response
}

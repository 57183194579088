import React from 'react'
import {Box, Typography} from '@mui/material'
import Tooltip from '@mui/material/Tooltip'

import RemoveRedEyeTwoToneIcon from '@mui/icons-material/RemoveRedEyeTwoTone'
import QuestionAnswerTwoToneIcon from '@mui/icons-material/QuestionAnswerTwoTone'
import BookmarkTwoToneIcon from '@mui/icons-material/BookmarkTwoTone'
function IconItem(props) {
  const {icon, stats, text} = props

  return (
    <Tooltip title={text}>
      <Box sx={{display: 'flex', gap: 0.5, alignItems: 'center'}}>
        <Typography variant="body2" sx={{color: 'greyText.main'}}>
          {stats || 0}
        </Typography>
        {icon}
      </Box>
    </Tooltip>
  )
}
export default function IconBar(props) {
  const {question} = props
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        gap: 3,
      }}
    >
      <IconItem
        icon={<BookmarkTwoToneIcon sx={{width: 16, height: 16}} />}
        stats={question.bookmarked_count}
        text="Bookmarks"
      />
      <IconItem
        icon={<QuestionAnswerTwoToneIcon sx={{width: 16, height: 16}} />}
        stats={question.answers_count}
        text="Answers"
      />
      <IconItem
        icon={<RemoveRedEyeTwoToneIcon sx={{width: 16, height: 16}} />}
        stats={question.views}
        text="Views"
      />
    </Box>
  )
}

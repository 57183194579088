import React from 'react'
import {useField, Field} from 'formik'
import {Form as FormUi} from 'semantic-ui-react'
import CustomCss from '../Review.module.css'

const RatingOutOTen = ({label, helpText, lowRating, highRating, ...props}) => {
  const [field] = useField(props)

  const formValues = [...Array(11).keys()].slice(1)
  const radioButtons = formValues.map((value, index) => {
    const checked = parseInt(field.value) === parseInt(value)
    return (
      <div key={index} className={CustomCss.radioButtonItem}>
        <Field
          type="radio"
          value={parseInt(value)}
          className={CustomCss.radioButton}
          checked={checked}
          {...props}
        />
        <label>{value}</label>
      </div>
    )
  })
  return (
    <FormUi.Field>
      <label className={CustomCss.Label}>
        {label} <br />
        <span className={CustomCss.helpText}>{helpText}</span>
      </label>

      <FormUi.Group inline className={CustomCss.radioButtonContainer}>
        {radioButtons}
      </FormUi.Group>
      <div className={CustomCss.radioButtonFlexBox}>
        <small>{lowRating || 'Very Poor'}</small>
        <small>{highRating || 'Excellent'}</small>
      </div>
    </FormUi.Field>
  )
}

export default RatingOutOTen

import React, {useContext} from 'react'
import NewsFeed from 'components/feed/NewsFeed'
import {AuthContext} from '../../context/context'
import {Box, Grid} from '@mui/material'
import FollowingSuggestion from 'components/feed/followingSuggestion/FollowingSuggestion'
import CopyRightFooter from 'components/layout/common/footer/CopyRightFooter'

function Feed() {
  const {user} = useContext(AuthContext)
  return (
    <div className="main">
      <div className="main__content">
        <div className="wrapper" style={{backgroundColor: '#F4F4F4'}}>
          <Box sx={{flexGrow: 1, px: 4}}>
            <Grid container spacing={2}>
              <Grid item lg={8} md={12} sm={12} xs={12}>
                <NewsFeed user={user} />
              </Grid>
              <Grid item lg={4} md={12} sm={12} xs={12}>
                <Box sx={{position: {md: 'fixed', lg: 'fixed'}}}>
                  <FollowingSuggestion />
                  <CopyRightFooter />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
    </div>
  )
}

export default Feed

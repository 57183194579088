import {useState, useEffect, useMemo} from 'react'
import {useLocation} from 'react-router-dom'
import {Divider, Button, Container, Typography} from '@mui/material'
import UserSearchResult from 'components/account/search/UserSearchResult'
import Api from 'services/api.service'
import {apiUrl} from '../../../config/vars'

function useQuery() {
  const {search} = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

export default function UserSearchResults() {
  const [query, setQuery] = useState('')
  const [results, setResults] = useState([])
  const [nextUrl, setNextUrl] = useState('')
  let urlQuery = useQuery()

  const searchUsers = (q = '') => {
    Api.get(`${apiUrl}user/search/?q=${query || q}`)
      .then(response => {
        setResults(response.data)
        setNextUrl(response.data.next)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const loadMore = async () => {
    if (nextUrl) {
      const response = await Api.get(nextUrl)
      setNextUrl(response.data.next)
      const updatedList = [...results.results, ...response.data.results]
      setResults(() => {
        return {...results, results: [...updatedList]}
      })
    }
  }

  useEffect(() => {
    let search = urlQuery.get('q')
    searchUsers(search)
    setQuery(search)
  }, [])

  return (
    <div className="main">
      <div className="main__content">
        <Container sx={{mt: 2}}>
          {results.count > 0 && (
            <Typography variant="h6">
              {results.count} results found for &apos;{query}&apos;
            </Typography>
          )}
          {results.count > 0 ? (
            <div style={{marginBottom: '160px'}}>
              <UserSearchResult results={results.results} />
              <Divider hidden />
              {results.count != results.results.length && (
                <Button variant="outlined" onClick={() => loadMore()}>
                  LoadMore
                </Button>
              )}
            </div>
          ) : (
            <Typography variant="h6">Nothing was found!</Typography>
          )}
        </Container>
      </div>
    </div>
  )
}

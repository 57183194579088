import React from 'react'
import {Box, Grid, Container} from '@mui/material'
import DashboardCard from './Card/DashboardCard'
import AgencyReviewsWidget from '../Widgets/AgencyReviews/AgencyReviewsWidget'
import LocumReteWidget from '../Widgets/LocumRate/LocumReteWidget'
import DashboardCardFullWidth from './Card/DashboardCardFullWidth'

export default function DashboardLayout() {
  return (
    <Box sx={{backgroundColor: 'greyBackground.main', minHeight: '100vh'}}>
      <Container sx={{mt: 3}}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <DashboardCardFullWidth mainTitle="Agency" secondaryTitle="Reviews">
              <AgencyReviewsWidget />
            </DashboardCardFullWidth>
          </Grid>
          <Grid item xs={12} md={7}>
            <DashboardCard
              mainTitle="UK average locum rate"
              secondaryTitle="Live Data"
            >
              <LocumReteWidget />
            </DashboardCard>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

import {useState, useEffect} from 'react'
import {Box, Avatar, Typography, IconButton} from '@mui/material'
import {useToasts} from 'react-toast-notifications'
import Api from 'services/api.service'
import {flexSpaceBetween, alignCenterStyle, DisplayFlexColumn} from './Styles'
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'

export default function FollowRequestList() {
  const {addToast} = useToasts()
  const [followRequest, setFollowRequest] = useState([])

  const getFriendsRequests = () => {
    Api.get('/api/friends/request/list/').then(response => {
      setFollowRequest(response.data.results)
    })
  }

  const handleFriendRequest = (requestId, action) => {
    const data = new FormData()

    data.append('action', action)
    Api.patch(`/api/friends/request/action/${requestId}/`, data)
      .then(res => {
        if (res.status >= 200 && res.status <= 300) {
          addToast(`Friend request has been ${action}ed.`, {
            appearance: 'success',
          })
          getFriendsRequests()
        } else {
          throw new Error(res.statusText)
        }
      })
      .catch(er => {
        addToast(`${er}`, {
          appearance: 'error',
        })
      })
  }

  useEffect(() => {
    getFriendsRequests()
  }, [])

  const FollowRequestDiv = followRequest.map(friend => {
    return (
      <Box key={friend.from_user?.id} sx={flexSpaceBetween}>
        <Box sx={alignCenterStyle}>
          <Avatar src={friend.from_user?.profile?.profile_image} />
          <Typography variant="body1">
            {friend?.from_user?.full_name}
          </Typography>
        </Box>
        <Box sx={alignCenterStyle}>
          <IconButton
            size="small"
            variant="outlined"
            onClick={() => handleFriendRequest(friend.id, 'accept')}
          >
            <DoneIcon />
          </IconButton>
          <IconButton
            aria-label="reject"
            size="small"
            variant="contained"
            color="error"
            onClick={() => handleFriendRequest(friend.id, 'reject')}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
    )
  })
  return (
    <>
      {followRequest.length > 0 ? (
        <Box sx={DisplayFlexColumn}>
          <Typography variant="h6" sx={{mb: 3}}>
            Follow Requests
          </Typography>
          {FollowRequestDiv}
        </Box>
      ) : null}
    </>
  )
}

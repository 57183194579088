import React from 'react'
import {useLocation} from 'react-router-dom'
import {Container} from 'semantic-ui-react'
import MessageForm from '../../../components/account/messages/MessageForm'
function useQuery() {
  const {search} = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

export default function Compose(props) {
  let query = useQuery()

  return (
    <div className="main">
      <div className="main__content">
        <div className="wrapper">
          <Container>
            <MessageForm recipient={query.get('recipient')} />
          </Container>
        </div>
      </div>
    </div>
  )
}

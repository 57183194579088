import VerifiedIcon from '@mui/icons-material/Verified'
import FollowButton from './FollowButton'
import {Box, Typography, Avatar} from '@mui/material'

export default function UserSearchResult(props) {
  const {results} = props

  const resultDiv = results.map(searchResult => {
    return (
      <Box
        key={searchResult.id}
        sx={{display: 'flex', justifyContent: 'space-between', mb: 5}}
      >
        <Box sx={{display: 'flex', gap: 2}}>
          <Avatar
            src={searchResult.profile.profile_image}
            className="feed__avatar_picture"
          />
          <Box>
            <Box sx={{display: 'flex', alignContent: 'center', gap: 1}}>
              <a href={`/account/${searchResult.slug}/`}>
                {searchResult.full_name}{' '}
              </a>
              {searchResult.verified && (
                <VerifiedIcon fontSize="small" color="primary" />
              )}
            </Box>
            <Typography variant="body2">{searchResult.profession}</Typography>
          </Box>
        </Box>
        <Box>
          <FollowButton item={searchResult} />
        </Box>
      </Box>
    )
  })

  return (
    <Box sx={{mt: 3}}>
      <Box>{resultDiv}</Box>
    </Box>
  )
}

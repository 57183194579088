import React from 'react'

export default function FullAddress(props) {
  const {pharmacy} = props
  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
  }
  const checkIfNan = word => {
    // lower the word and check if it's === 'Nan'
    if (word) {
      if (word && word.toLowerCase() === 'nan') {
        return ''
      } else {
        return toTitleCase(word) + ', '
      }
    } else return ''
  }
  const checkNanForPostCode = word => {
    // lower the word and check if it's === 'Nan'
    if (word) {
      if (word && word.toLowerCase() === 'nan') {
        return ''
      } else {
        return word
      }
    } else return ''
  }
  return (
    <>
      {checkIfNan(pharmacy.address_line_1)}
      {checkIfNan(pharmacy.address_line_2)}
      {checkIfNan(pharmacy.address_line_3)}
      {checkIfNan(pharmacy.city)}
      {checkNanForPostCode(pharmacy.post_code)}
    </>
  )
}

import Api from 'services/api.service'

export const endPoints = {
  clinicalQuestion: 'api/clinical-questions/questions/',
  fetchSingleQuestion: 'api/clinical-questions/questions/',
  clinicalcategories: 'api/clinical-questions/clinical-categories/',
}

export async function fetchClinicalQuestions(
  endPoint = endPoints.clinicalQuestion,
) {
  // only fetches the given endpoint
  const response = await Api.get(endPoint)
  return response
}

export async function fetchAllClinicalCategories(
  endPoint = endPoints.clinicalcategories,
) {
  // this endpoints returns all the categories in one call
  const response = await Api.get(endPoint)
  return response
}

export async function fetchSingleQuestion(id) {
  const endPoint = endPoints.clinicalQuestion
  const url = `${endPoint}${id}/`
  // only fetches the given endpoint
  const response = await Api.get(url)
  return response
}

export async function fetchAllQuestions(nextUrl = endPoints.clinicalQuestion) {
  // Fetched all peginations
  const response = await Api.get(nextUrl)
  const data = response.data.results
  if (response.data.next) {
    const nextPageData = await fetchAllQuestions(response.data.next)
    return [...data, ...nextPageData]
  }

  return data
}

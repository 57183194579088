import React, {useState} from 'react'
import {Button, Form, Loader} from 'semantic-ui-react'
import {Avatar} from '@mui/material'
import Api from '../../services/api.service'
import {apiUrl} from '../../config/vars'
import {useToasts} from 'react-toast-notifications'
import FeedCss from './feed.module.css'
import PreviewImage from './newPost/PreviewImage'
import ImageUploadButton from './newPost/ImageUploadButton'

export default function NewPost(props) {
  const {addToast} = useToasts()
  const {user, toggleNewPost, setToggleNewPost} = props
  const [base64Images, setBase65Images] = useState([])
  const [previewImageFiles, setPreviewImageFiles] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [inputField, setInputField] = useState({
    caption: '',
    image_ids: [],
  })

  // // handle image upload
  const handleImageUpload = async i => {
    const formData = new FormData()
    formData.append('image_base64', i)
    // let newImageIds = inputField.image_ids;
    const api_call = Api.post(
      `${apiUrl}api/news-feed/post/image/create/`,
      formData,
    )
      .then(res => {
        // newImageIds.push(res.data.id);
        return res.data.id
      })
      .catch(error => {
        console.log(error)
        addToast('There was a proble with uploading your photo!', {
          appearance: 'error',
          autoDismiss: true,
        })
      })
    return Promise.resolve(api_call)
  }

  // remove all image from preview
  const removeAllImage = () => {
    setPreviewImageFiles([])
    setBase65Images([])
  }

  // clear the form
  const clearForm = () => {
    setInputField({caption: '', image_ids: []})
    removeAllImage()
    setToggleNewPost(false)
  }
  const SendAPost = async (image_ids = []) => {
    let data = {
      caption: inputField.caption,
    }
    if (image_ids.length > 0) {
      data = {...data, image_ids}
    }
    Api.post(`${apiUrl}api/news-feed/post/create/`, data)
      .then(() => {
        props.getNewsFeed()
        setIsLoading(false)
        addToast('New post has been created.', {
          appearance: 'success',
          autoDismiss: true,
        })
      })
      .catch(error => {
        console.log(error)
        addToast('something went wrong!', {
          appearance: 'error',
          autoDismiss: true,
        })
        setIsLoading(false)
      })

    return Promise.resolve(1)
  }

  const handleSubmition = async () => {
    setIsLoading(true)
    let promises = []
    if (base64Images.length >= 0) {
      base64Images.forEach(async i => {
        promises.push(handleImageUpload(i))
      })
      Promise.all(promises)
        .then(values => {
          SendAPost(values)
        })
        .then(() => {
          clearForm()
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      await SendAPost()
    }
  }

  const inputsHandler = e => {
    setInputField({...inputField, [e.target.name]: e.target.value})
  }

  return (
    <div className={FeedCss.NewPostContainer}>
      <Form>
        {isLoading ? (
          <div className={FeedCss.NewPostContent}>
            <Loader active />
          </div>
        ) : (
          <>
            <div className={FeedCss.NewPostContent}>
              <div className="feed__new_post__input_container">
                {user.profile && (
                  <Avatar
                    src={user.profile.profile_image || null}
                    size="small"
                  />
                )}
                <Form.TextArea
                  name="caption"
                  placeholder={`What's on your mind, ${user.first_name}?`}
                  className={FeedCss.NewPostInput}
                  rows="1"
                  value={inputField.caption}
                  onChange={inputsHandler}
                  onFocus={() => setToggleNewPost(true)}
                />
              </div>
              {previewImageFiles.length > 0 && (
                <PreviewImage
                  files={previewImageFiles}
                  removeAllImage={removeAllImage}
                />
              )}
            </div>
            {toggleNewPost && (
              <div className={FeedCss.NewPostHiddenOptions}>
                <ImageUploadButton
                  setBase65Images={setBase65Images}
                  setPreviewImageFiles={setPreviewImageFiles}
                />

                <Button
                  className={FeedCss.PublishButton}
                  onClick={handleSubmition}
                  color="blue"
                  disabled={inputField.caption !== '' ? false : true}
                >
                  Publish
                </Button>
              </div>
            )}
          </>
        )}
      </Form>
    </div>
  )
}

import {useEffect, useState} from 'react'
import Api from '../../../services/api.service'
import {apiUrl} from '../../../config/vars'
import {List, Header} from 'semantic-ui-react'
import MessageThread from './MessageThread'

export default function SentBox() {
  const [messages, setMessages] = useState([])
  const [messagesCount, setMessagesCount] = useState(0)
  const [showInbox, setShowInbox] = useState(true)
  const [messageId, setMessageId] = useState(null)
  const [expand, setExpand] = useState(false)

  const getInbox = () => {
    Api.get(`${apiUrl}api/messages/sent/`).then(response => {
      setMessages(response.data.results)
      setMessagesCount(response.data.count)
    })
  }

  // const openMessageThread = id => {
  //   setShowInbox(false)
  //   setMessageId(id)
  // }

  useEffect(() => {
    getInbox()
  }, [])

  const messageList = messages.map(message => {
    const date = new Date(message.sent_at).toLocaleDateString()
    const time = new Date(message.sent_at).toLocaleTimeString()
    return (
      <List.Item onClick={() => setExpand()} key={message.id}>
        <div className="w-100 d-flex-space-b">
          <Header
            as="h4"
            content={message.recipient && message.recipient.full_name}
          />
          <small>
            {date} {time}
          </small>
        </div>
        <p>{message.subject}</p>
        {expand ? <p>{message.body}</p> : null}
      </List.Item>
    )
  })
  const inboxList = (
    <div>
      {messagesCount === 0 ? (
        <p>You have no messages to show</p>
      ) : (
        <List selection verticalAlign="middle">
          {messageList}
        </List>
      )}
    </div>
  )
  return (
    <div>{showInbox ? inboxList : <MessageThread messageId={messageId} />}</div>
  )
}

import React from 'react'
import PharmacyLocumRateDataContainer from 'components/datainsight/DispensingData/PharmacyLocumRateDataContainer/PharmacyLocumRateDataContainer'

export default function PharmacyLocumRateData() {
  return (
    <div className="main">
      <div className="main__content">
        <div
          className="wrapper"
          style={{
            backgroundColor: 'greyBackground.main',
            paddingTop: '40px',
            minHeight: '100vh',
          }}
        >
          <PharmacyLocumRateDataContainer />
        </div>
      </div>
    </div>
  )
}

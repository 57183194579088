import {useEffect, useState} from 'react'
import {Formik} from 'formik'
import {Button, Form, Image} from 'semantic-ui-react'
import RecipientList from './RecipientList'
import {messageValidation} from '../../../validations/message.validation'
import Api from '../../../services/api.service'
import {useToasts} from 'react-toast-notifications'
import {apiUrl} from '../../../config/vars'

export default function MessageForm(props) {
  const [open, setOpen] = useState(false)
  const [recipient, setRecipient] = useState([])
  const {addToast} = useToasts()
  useEffect(() => {
    if (!props.recipient) {
      setOpen(true)
    } else {
      setOpen(false)
      getRecipientInfo()
    }
  }, [])

  const selectedRecipient = (
    <>
      {recipient.profile && (
        <div>
          <Image avatar src={recipient.profile.profile_image || null} />
          {recipient.full_name}
        </div>
      )}
    </>
  )

  const getRecipientInfo = () => {
    Api.get(`auth/users/${props.recipient}`)
      .then(response => {
        setRecipient(response.data)
      })
      .catch(error => {
        console.log(error)
        setOpen(true)
      })
  }
  const sendMessage = (values, actions) => {
    const data = {...values}
    data.recipients = [recipient.id]
    Api.post(`${apiUrl}api/messages/compose/`, data)
      .then(() => {
        actions.setSubmitting(false)
        actions.resetForm()

        addToast('Message was sent successfully.', {
          type: 'success',
          autoDismiss: true,
        })
        window.location = '/messages/'
      })
      .catch(error => {
        actions.setSubmitting(false)
        console.log(error)
        addToast('something went wrong', {
          type: 'danger',
          autoDismiss: true,
        })
      })
  }

  return (
    <Formik
      initialValues={{
        subject: '',
        body: '',
      }}
      onSubmit={(values, actions) => {
        sendMessage(values, actions)
      }}
      validationSchema={messageValidation}
    >
      {props => {
        const {
          values,
          errors,
          touched,
          handleChange,
          isSubmitting,
          handleSubmit,
        } = props
        return (
          <Form onSubmit={handleSubmit}>
            <p>To </p>
            <div className="d-flex-space-b mb-20">
              <RecipientList openModal={open} setRecipient={setRecipient} />
              {selectedRecipient}
            </div>
            {/* <Form.Input name="name" label="To" /> */}
            <Form.Input
              name="subject"
              id="subject"
              label="Subject"
              value={values.subject}
              onChange={handleChange}
              error={touched.subject && errors.subject}
            />
            <Form.TextArea
              name="body"
              id="body"
              component="textarea"
              label="Message"
              value={values.body}
              line={10}
              onChange={handleChange}
              error={touched.body && errors.body}
            />
            <Button
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              Send
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}

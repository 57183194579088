import React from 'react'
import {withRouter} from 'react-router-dom'
import {Box, Fade} from '@mui/material'
import {AuthContext} from 'context/context'
import {fetchSingleQuestion} from 'services/calls/ClinicalAPI'
import Api from 'services/api.service'
import Question from 'components/clinicalQuestions/Question'
import WriteAnAnswer from 'components/clinicalQuestions/answers/WriteAnAnswer'
import AnswerList from 'components/clinicalQuestions/answers/AnswerList'
import AddIconAction from 'components/clinicalQuestions/actions/AddIconAction'
import QuestionImages from 'components/clinicalQuestions/QuestionImages'

function ClinicalQuestionPage(props) {
  const {question_id} = props.match.params
  const [question, setQuestion] = React.useState(null)
  const [answers, setAnswers] = React.useState(null)
  const {user} = React.useContext(AuthContext)

  React.useEffect(async () => {
    const questions = await fetchSingleQuestion(question_id)
    setQuestion(questions.data)
    if (questions.data.answers_list.length > 0) {
      const answers = await Api.get(questions.data.answers_list)
      setAnswers(answers.data.results)
    }
  }, [])

  const renderedQuestion = question ? (
    <Fade in timeout={4000}>
      <Box sx={styles.container}>
        <Box sx={{my: 'auto', textAlign: 'center', gap: 4, p: 2}}>
          <Question question={question} />
        </Box>
      </Box>
    </Fade>
  ) : null

  const renderedImages =
    question && question.images.length > 0 ? (
      <Fade in timeout={4000}>
        <Box sx={styles.imageContainer}>
          <QuestionImages images={question.images} />
        </Box>
      </Fade>
    ) : null

  const renderedAnswers = answers ? (
    <Fade in timeout={4000}>
      <Box sx={styles.lightContainer}>
        <AnswerList answers={answers} />
      </Box>
    </Fade>
  ) : null

  const renderedAnswerInput =
    user && question ? (
      <WriteAnAnswer
        questionId={question.id}
        user={user}
        answers={answers}
        setAnswers={setAnswers}
      />
    ) : null

  return (
    <div className="main">
      <div className="main__content">
        <div className="wrapper" style={{backgroundColor: '#F4F4F4'}}>
          {renderedQuestion}
          {renderedImages}
          {renderedAnswers}
          {renderedAnswerInput}
          <AddIconAction />
        </div>
      </div>
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    minHeight: '60vh',
    width: '90vw',
    maxWidth: 500,
    margin: '20px auto 0 auto',
    borderRadius: '20px 20px 0 0',
    position: 'relative',
  },
  lightContainer: {
    display: 'flex',
    backgroundColor: 'greyBackground.light',
    width: '90vw',
    maxWidth: 500,
    flexDirection: 'column',
    margin: '0 auto',
    gap: 3,
    p: 2,
  },
  imageContainer: {
    backgroundColor: 'greyBackground.light',
    width: '90vw',
    maxWidth: 500,
    margin: '0 auto',
    p: 2,
  },
}
export default withRouter(ClinicalQuestionPage)

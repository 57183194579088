import React from 'react'
import {DataList} from '../DataList'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'

export default function DataContainer({pharmacyData}) {
  const [query, setQuery] = React.useState('')

  const onChange = e => {
    const q = e.target.value
    setQuery(q)
  }

  const filteredData = pharmacyData?.filter(item =>
    item.region_upper.includes(query.toUpperCase()),
  )

  return (
    <>
      <TextField
        id="outlined-basic"
        label="Search"
        variant="outlined"
        sx={{width: '100%', mb: 2}}
        onChange={e => onChange(e)}
      />
      <Box
        sx={{
          overflow: 'scroll',
          overflowX: 'hidden',
          maxHeight: '70vh',
        }}
      >
        <DataList pharmacyData={filteredData} />
      </Box>
    </>
  )
}

import {useState} from 'react'
import {Button, Avatar, IconButton} from '@mui/material'
import Api from '../../../../services/api.service'
import {apiUrl} from '../../../../config/vars'
import Picker from 'emoji-picker-react'
import CommentItem from './CommentItem'
import FeedCss from '../../feed.module.css'
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions'

const CommentList = props => {
  const item = props.item
  const [comment, setComment] = useState('')
  const [commentList, setCommentList] = useState(props.item.comments)
  const [showEmoji, setShowEmoji] = useState(false)

  const updateCommentInput = e => {
    setComment(e.target.value)
  }

  const onEmojiClick = (event, emojiObject) => {
    setComment(comment => {
      return comment + emojiObject.emoji
    })
  }

  const SubmitComment = () => {
    // setUploadLoading(true);
    const data = new FormData()

    data.append('comment', comment)
    data.append('post_id', item.id)

    Api.post(`${apiUrl}api/news-feed/post/comment/create/`, data)
      .then(res => {
        setComment('')
        setCommentList([...commentList, res.data])
        setShowEmoji(false)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const commentItem = commentList.map(cmnt => {
    return (
      <div key={cmnt.pk} className={FeedCss.CommentBody}>
        <CommentItem commentItem={cmnt} user={props.user} />
      </div>
    )
  })
  return (
    <div>
      <div className={FeedCss.CommentHeader}>
        <h4>Comments</h4>
        <div
          className={FeedCss.CommentHeaderCloseButton}
          onClick={() => props.setShowComments(false)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-x"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </div>
      </div>
      {commentItem}
      <div className={FeedCss.CommentFooter}>
        <div className={FeedCss.CommentInputForm}>
          <form>
            <div className={FeedCss.CommentInputControl}>
              <textarea
                onChange={updateCommentInput}
                placeholder="Write a comment"
                value={comment}
                rows="5"
                className={FeedCss.CommentInput}
              />
            </div>
            <div className={FeedCss.CommentActions}>
              <div className={FeedCss.FeedItemUserImage}>
                <Avatar
                  src={props.user.profile.profile_image}
                  sx={{width: 35, height: 35}}
                />
              </div>
              <div>
                <IconButton
                  aria-label="smile outline"
                  onClick={() => setShowEmoji(!showEmoji)}
                >
                  <EmojiEmotionsIcon />
                </IconButton>
                <Button
                  onClick={SubmitComment}
                  variant="contained"
                  size="medium"
                >
                  Comment
                </Button>
              </div>
            </div>
            {showEmoji && <Picker onEmojiClick={onEmojiClick} preload />}
          </form>
        </div>
      </div>
    </div>
  )
}

export default CommentList

const listOfYearChoices = numberOfYears => {
  const date = new Date()
  const currentYear = date.getFullYear()
  const listOfYears = []
  for (let i = 0; i < numberOfYears; i++) {
    listOfYears.push(currentYear - i)
  }
  return listOfYears
}

export default listOfYearChoices

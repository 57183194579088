import React, {useEffect, useState} from 'react'
import Api from '../../services/api.service'
import {apiUrl} from '../../config/vars'

export default function UnreadNotificationsCount() {
  const [count, setCount] = useState(0)

  const getUnreadNotificationsCount = () => {
    Api.get(`${apiUrl}notifications/unread_count/`).then(response => {
      setCount(response.data.unread_count)
    })
  }

  useEffect(() => {
    getUnreadNotificationsCount()
  }, [])
  return count
}

import {useState, useEffect} from 'react'
import Button from '@mui/material/Button'
import Api from '../../services/api.service'
import NewPost from './NewPost'
import FeedItem from './FeedItem'
import FeedCss from './feed.module.css'
import PharmacyCarousel from './recentlyReviewedPharmacies/PharmacyCarousel'
import QuestionSlider from './QuestionSlider/QuestionSlider'

const NewsFeed = props => {
  const [loading, setLoading] = useState(false)
  const [toggleNewPost, setToggleNewPost] = useState(false)
  const [feed, setFeed] = useState([])
  const [resultMeta, setResultMeta] = useState({
    count: 0,
    next: null,
  })

  const getNewsFeed = () => {
    setLoading(true)
    Api.get('/api/news-feed/list/').then(response => {
      setResultMeta(response.data)
      setFeed(response.data.results)
      setLoading(false)
    })
  }

  const loadMore = async () => {
    const {next} = resultMeta
    if (next) {
      const response = await Api.get(next)
      setFeed([...feed, ...response.data.results])
      setResultMeta(response.data)
    }
  }

  useEffect(() => {
    getNewsFeed()
  }, [])

  const feedItems = () => {
    if (feed.length == 0) {
      return (
        <>
          <PharmacyCarousel />
        </>
      )
    }
    return feed.map((item, index) => {
      if (index === 2) {
        return (
          <div key={index}>
            <PharmacyCarousel />
            <FeedItem item={item} />
          </div>
        )
      }
      return <FeedItem item={item} key={index} />
    })
  }
  return (
    <>
      <NewPost
        user={props.user}
        getNewsFeed={getNewsFeed}
        toggleNewPost={toggleNewPost}
        setToggleNewPost={setToggleNewPost}
      />
      <div className="mt-20" />
      {toggleNewPost && (
        <div
          className={FeedCss.FeedOverlay}
          onClick={() => setToggleNewPost(false)}
        />
      )}
      <div>
        <QuestionSlider />
        {!loading ? (
          feedItems()
        ) : (
          <>
            <PharmacyCarousel />
          </>
        )}

        {resultMeta.next && resultMeta.count >= 8 && (
          <div className={FeedCss.FeedLoadMore}>
            <Button onClick={() => loadMore()}>Load More</Button>
          </div>
        )}
      </div>
    </>
  )
}
export default NewsFeed

import React from 'react'
import Loadable from '@loadable/component'

import Loading from '../../components/base/ComponentLoading'

const ErrorComponent = Loadable(() => import('./Error'))

function Error() {
  return <ErrorComponent fallback={<Loading />} />
}

export default Error

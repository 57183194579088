import React from 'react'
import {Box, Typography} from '@mui/material'
import RandomAvatar from 'components/explore/common/RandomAvatar'
import StarRating from 'components/layout/common/starrating/StarRating'

function ReviewQuestionWithAnswer(props) {
  let {question, answer} = props

  if (typeof answer == 'boolean') {
    let newAnswer = answer ? 'Yes' : 'No'
    answer = newAnswer
  }
  return (
    <Box sx={{display: 'flex', gap: 1, mb: 1, flexWrap: 'wrap'}}>
      <Typography variant="caption">{question}</Typography>
      <Typography color={'secondary'} variant="caption">
        {answer}
      </Typography>
    </Box>
  )
}
export default function AgencySingleReview(props) {
  const {review} = props
  let year = 0
  if (review) {
    year = review?.date_created?.split('-')[0]
  }

  const stars = (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        mb: 1,
        flexWrap: 'wrap',
        alignItems: 'center',
      }}
    >
      <StarRating
        score={review.overall_rating}
        name={'Overall Rating:'}
        sx={{fontSize: '18px !important'}}
      />
      <Typography>{review.overall_rating}/10</Typography>
    </Box>
  )
  return (
    <Box sx={{mb: 8}}>
      <Box sx={{display: 'flex', gap: 2}}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <RandomAvatar />
          <Typography variant="body2">{year}</Typography>
        </Box>
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mb: 1,
            }}
          >
            <Typography variant="body1">Anonymised</Typography>
          </Box>
          <Typography variant="body2" color="GrayText">
            {review.review === 'nan' ? 'No Description' : review.review}
          </Typography>
          <Box sx={{mt: 2}}>
            {stars}
            <ReviewQuestionWithAnswer
              question={'Do you recommend this agency?'}
              answer={review.recommended}
            />
            <ReviewQuestionWithAnswer
              question={'Gets the highest rate?'}
              answer={review.gets_the_highest_rate}
            />
            <ReviewQuestionWithAnswer
              question={'Professional and Courtious?'}
              answer={review.professional_and_courteous}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import QuestionCard from './QuestionCard'
import {fetchClinicalQuestions} from 'services/calls/ClinicalAPI'
import {Typography, Link, Box} from '@mui/material'

export default function QuestionSlider() {
  const [clinicalQuestions, setClinicalQuestions] = React.useState([])
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  React.useEffect(async () => {
    const fetching = await fetchClinicalQuestions()
    setClinicalQuestions(fetching.data)
  }, [])

  const questionCards = clinicalQuestions?.results?.map((question, index) => {
    return <QuestionCard question={question} key={index} />
  })

  const questionSlider =
    clinicalQuestions?.results?.length > 0 ? (
      <Slider {...settings}>{questionCards}</Slider>
    ) : null

  return (
    <Box sx={{mb: 6, maxWidth: 660, mx: 'auto'}}>
      <Box sx={{display: 'flex', justifyContent: 'space-between', my: 2}}>
        <Typography>Clinical Questions</Typography>
        <Link href="/clinical-questions">View All</Link>
      </Box>
      {questionSlider}
    </Box>
  )
}

import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Chip from '@mui/material/Chip'

export default function AvatarChips(props) {
  const {user} = props
  return (
    <Chip
      avatar={
        <Avatar alt={user.full_name} src={user.profile.profile_image || ''} />
      }
      label={user.full_name}
    />
  )
}

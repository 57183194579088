import React, {useEffect} from 'react'
import {Button, Image, Icon} from 'semantic-ui-react'
import Api from '../../../services/api.service'
import ReplyToThread from './ReplyToThread'

export default function MessageThread({messageId, showInbox}) {
  const [message, setMessage] = React.useState([])
  // const [thread, setThread] = React.useState([]);
  const [loaded, seLoaded] = React.useState(false)
  const [showReply, setShowReply] = React.useState(false)
  const date = new Date(message.sent_at).toLocaleDateString()
  const time = new Date(message.sent_at).toLocaleTimeString()

  const MarkMessageAsRead = () => {
    let data = {
      field_bit: 'read_at',
      pks: [messageId],
      success_msg: 'Messages or conversations successfully marked as read.',
      field_value: 1,
    }
    Api.post(`api/messages/mark/`, data).then(response => {
      console.log(response.data)
    })
  }

  const DeleteAMessage = () => {
    let data = {
      field_bit: 'deleted_at',
      pks: [messageId],
      success_msg: 'Messages or conversations successfully deleted.',
      field_value: 1,
    }
    Api.post(`api/messages/move/`, data).then(() => {
      window.location = '/messages/'
    })
  }

  const getMessageThread = () => {
    Api.get(`api/messages/inbox/${messageId}/`).then(response => {
      console.log(response)
      setMessage(response.data)
      seLoaded(true)

      if (response.data.read_at === null) {
        MarkMessageAsRead()
      }
    })
  }

  useEffect(() => {
    getMessageThread()
  }, [])

  return (
    <div>
      {loaded ? (
        <>
          <div className="message_thread_top_bar d-flex-space-b">
            <Button
              icon="arrow left"
              size="tiny"
              onClick={() => showInbox(true)}
            />
            <Button animated="vertical" onClick={() => DeleteAMessage()}>
              <Button.Content hidden>Bin</Button.Content>
              <Button.Content visible>
                <Icon name="trash" />
              </Button.Content>
            </Button>
            <Button animated="vertical" onClick={() => setShowReply(true)}>
              <Button.Content hidden>Reply</Button.Content>
              <Button.Content visible>
                <Icon name="reply" />
              </Button.Content>
            </Button>
          </div>
          {showReply ? (
            <ReplyToThread message={message} />
          ) : (
            <>
              <div>
                <Image
                  src={message.sender.profile.profile_image}
                  className="feed__avatar_picture"
                  avatar
                />
                <span>{message.sender.full_name}</span>
              </div>
              <div className="message_bubble mt-20">
                <small className="float-right">
                  {date} {time}
                </small>
                <p>
                  <strong>{message.subject}</strong>
                </p>
                <p>{message.body}</p>
              </div>
              {message.thread ? (
                <div className="message_bubble mt-20">
                  <small className="float-right"></small>

                  <p>
                    <strong>{message.thread.subject}</strong>
                  </p>
                  <p>{message.thread.body}</p>
                </div>
              ) : null}
            </>
          )}
        </>
      ) : (
        'loading'
      )}
    </div>
  )
}

import React from 'react'
import AvatarImage1 from '../../../assets/images/avatars/avatar1.png'
import AvatarImage2 from '../../../assets/images/avatars/avatar2.png'
import AvatarImage3 from '../../../assets/images/avatars/avatar3.png'
import AvatarImage4 from '../../../assets/images/avatars/avatar4.png'
import AvatarImage5 from '../../../assets/images/avatars/avatar5.png'
import AvatarImage6 from '../../../assets/images/avatars/avatar6.png'
import AvatarImage7 from '../../../assets/images/avatars/avatar7.png'
import AvatarImage8 from '../../../assets/images/avatars/avatar8.png'
import AvatarImage9 from '../../../assets/images/avatars/avatar9.png'
import AvatarImage10 from '../../../assets/images/avatars/avatar10.png'
import AvatarImage11 from '../../../assets/images/avatars/avatar11.png'
import AvatarImage12 from '../../../assets/images/avatars/avatar12.png'
import AvatarImage13 from '../../../assets/images/avatars/avatar13.png'
import {Avatar} from '@mui/material'
export default function RandomAvatar() {
  const allAvatars = [
    AvatarImage1,
    AvatarImage2,
    AvatarImage3,
    AvatarImage4,
    AvatarImage5,
    AvatarImage6,
    AvatarImage7,
    AvatarImage8,
    AvatarImage9,
    AvatarImage10,
    AvatarImage11,
    AvatarImage12,
    AvatarImage13,
  ]
  // random number bwtween 1 and 13
  const randomNumber = Math.floor(Math.random() * 12) + 1
  return (
    <Avatar
      src={allAvatars[randomNumber]}
      alt={`avatar-${randomNumber}`}
      style={styles}
    />
  )
}

// react style
const styles = {
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  marginRight: '10px',
  marginLeft: '10px',
}

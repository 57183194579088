import React from 'react'
import List from '@mui/material/List'
import Box from '@mui/material/Box'
import HomeIcon from '@mui/icons-material/Home'
import AutoGraphIcon from '@mui/icons-material/AutoGraph'
import DrawerItem from './DrawerItem'
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined'

export default function DrawerList(props) {
  const {toggleDrawer} = props
  return (
    <Box
      sx={{width: 250}}
      role="presentation"
      onClick={toggleDrawer}
      onKeyDown={toggleDrawer}
    >
      <List>
        <DrawerItem text={'Home'} urlPath={'/'}>
          <HomeIcon />
        </DrawerItem>
        <DrawerItem
          text={'Clinical Questions'}
          urlPath={'/clinical-questions/'}
        >
          <QuizOutlinedIcon />
        </DrawerItem>
        <DrawerItem text={'Data Insight'} urlPath={'/data-insight/'}>
          <AutoGraphIcon />
        </DrawerItem>
      </List>
    </Box>
  )
}

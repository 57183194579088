import {useState} from 'react'
import Button from '@mui/material/Button'
import {useToasts} from 'react-toast-notifications'
import Api from '../../../services/api.service'

export default function FollowButton(props) {
  const {addToast} = useToasts()
  const {item, buttonStyle} = props
  const buttonVariant = buttonStyle || 'outlined'
  const userFullName = item.full_name
  const [relationStatus, setRelationStatus] = useState(item.relationship_status)
  let buttonDiv

  const followUser = userId => {
    const formData = new FormData()
    formData.append('to_user', userId)
    Api.post(`/api/friends/request/`, formData)
      .then(response => {
        console.log('response', response)
        addToast(`Your request to follow ${userFullName} has been sent.`, {
          appearance: 'success',
        })
        setRelationStatus('Requested')
        // setLoading(false);
      })
      .catch(error => {
        console.log(error)
        addToast(error.message, {
          appearance: 'error',
        })
        console.log(error.message)
      })
  }
  if (relationStatus === 'You are following') {
    buttonDiv = (
      <Button variant={buttonVariant} size="small" disabled>
        Following
      </Button>
    )
  } else if (
    relationStatus === 'You have requested to follow this user' ||
    relationStatus === 'Requested'
  ) {
    buttonDiv = (
      <Button variant={buttonVariant} size="small" disabled>
        Requested
      </Button>
    )
  } else {
    buttonDiv = (
      <Button
        variant={buttonVariant}
        size="small"
        onClick={() => followUser(item.id)}
      >
        Follow
      </Button>
    )
  }
  return buttonDiv
}

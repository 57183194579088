import * as React from 'react'
import Chip from '@mui/material/Chip'
import Box from '@mui/material/Box'

export default function Hashtags(props) {
  const {hashtags} = props

  const chips = hashtags.map((hashtag, index) => {
    return (
      <Chip
        label={hashtag.name}
        key={index}
        size="small"
        color="secondary"
        sx={{fontSize: '8px', p: '8px 8px'}}
      />
    )
  })
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        flexWrap: 'wrap',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      {chips}
    </Box>
  )
}

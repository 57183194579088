import NotificationCss from '../notification.module.css'
import {Typography, Box, Avatar, Link} from '@mui/material'

function FriendRequestNotification(props) {
  const {notification} = props
  const userSlug = notification?.data?.data?.user?.slug || false
  const postUrl = userSlug ? `/account/${userSlug}` : '#'

  return (
    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
      <Box sx={{display: 'flex', gap: 2}}>
        <Avatar
          src={notification?.data?.data?.user?.profile_image || null}
          alt={notification?.data?.data?.user?.full_name}
        />

        <Typography>
          <Link href={postUrl} underline="none">
            {notification?.data?.data?.user?.full_name}
          </Link>
          {'  '}
          {notification.verb} <br />
          <Typography variant="caption" color="secondary">
            {notification.time_ago}
          </Typography>
        </Typography>
      </Box>
      <Box>
        {notification.unread && (
          <div className={NotificationCss.unreadIndicator}></div>
        )}
      </Box>
    </Box>
  )
}

export default FriendRequestNotification

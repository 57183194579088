import React from 'react'
import {Header, Container, Button} from 'semantic-ui-react'
import {Tab} from 'semantic-ui-react'
import Inbox from '../../../components/account/messages/Inbox'
import SentBox from '../../../components/account/messages/SentBox'
import {NavLink} from 'react-router-dom'

const panes = [
  {
    menuItem: {key: 'inbox', icon: 'inbox', content: 'Inbox'},
    render: () => (
      <Tab.Pane>
        <Inbox />
      </Tab.Pane>
    ),
  },
  {
    menuItem: {key: 'sent', content: 'Sent'},
    render: () => (
      <Tab.Pane>
        <SentBox />
      </Tab.Pane>
    ),
  },
  //   {
  //     menuItem: (
  //       <Menu.Item key="messages">
  //         Messages<Label>15</Label>
  //       </Menu.Item>
  //     ),
  //     render: () => <Tab.Pane>Tab 2 Content</Tab.Pane>,
  //   },
]

export default function Messages() {
  return (
    <div className="main">
      <div className="main__content">
        <div className="wrapper">
          <Container>
            <div className="d-flex-space-b">
              <Header as="h1" content="Messages" />
              <NavLink to="/messages/send-message/">
                <Button basic icon="plus circle" />
              </NavLink>
            </div>
            <Tab panes={panes} />
          </Container>
        </div>
      </div>
    </div>
  )
}

import React from 'react'
import {withRouter, useHistory} from 'react-router-dom'
import {Formik} from 'formik'
import {Message, Button} from 'semantic-ui-react'
import * as Yup from 'yup'
import {useToasts} from 'react-toast-notifications'
import Lottie from 'lottie-react'

import Api from '../../../../services/api.service'
import reviewStyle from '../Review.module.css'
import TextArea from '../form-fields/TextArea'
import YesAndNoChoice from '../form-fields/YesAndNoChoice'
import MultiChoice from '../form-fields/MultiChoice'
import Stars from 'assets/lottie/stars.json'

const initialValues = {
  staff_greeted: '',
  adequate_number_of_staff: '',
  enough_time_break: '',
  how_often_self_check: '',
  pressure_of_commercial_targets: '',
  travel_expense_paid: '',
  how_long_to_be_paid: '',
  views_of_area_manager: '',
  booked_through_agency: '',
}

const validationSchema = Yup.object({
  staff_greeted: Yup.bool().required('Required'),
  adequate_number_of_staff: Yup.bool().required('Required'),
  enough_time_break: Yup.bool().required('Required'),
  how_often_self_check: Yup.string().required('Required'),
  pressure_of_commercial_targets: Yup.bool().required('Required'),
  travel_expense_paid: Yup.bool().required('Required'),
  how_long_to_be_paid: Yup.string().required('Required'),
  views_of_area_manager: Yup.string().required('Required'),
  booked_through_agency: Yup.bool().required('Required'),
})

function LocumMoreQuestions(props) {
  const {addToast} = useToasts()
  const history = useHistory()
  const {slug, review_id} = props.match.params
  const [serverSubmiited, setServerSubmiited] = React.useState(false)
  return (
    <div className="main">
      <div className={reviewStyle.pageContainer}>
        <div className={reviewStyle.FormikContainer}>
          {!serverSubmiited ? (
            <Formik
              initialValues={initialValues}
              onSubmit={async (values, {setSubmitting}) => {
                const apiCall = await Api.patch(
                  `/api/business/pharmacy/locum_pharmacy_review/${review_id}/update/`,
                  values,
                )
                if (apiCall.status === 201) {
                  addToast(
                    'Thank you for answering more questions, we have saved your answers!',
                    {
                      appearance: 'success',
                      autoDismiss: true,
                    },
                  )
                  setTimeout(() => {
                    history.push(`/page/pharmacy/${slug}`)
                  }, 2000)
                }
                setSubmitting(false)
                setServerSubmiited(true)
              }}
              validationSchema={validationSchema}
            >
              {({
                values,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                touched,
                errors,
              }) => (
                <form className="ui form" onSubmit={handleSubmit}>
                  <div className={reviewStyle.questionContainer}>
                    <label className={reviewStyle.Label}>
                      Did a staff member greet and show you around the branch at
                      the start of the shift?
                    </label>
                    <YesAndNoChoice
                      id="staff_greeted"
                      name="staff_greeted"
                      handleBlur={handleBlur}
                    />
                    {errors.staff_greeted && touched.staff_greeted && (
                      <Message negative>{errors.staff_greeted}</Message>
                    )}
                  </div>
                  <div className={reviewStyle.questionContainer}>
                    <label className={reviewStyle.Label}>
                      Do you feel there was an adequate number of qualified
                      staff members, on duty, to provide a safe working
                      environment?
                    </label>
                    <YesAndNoChoice
                      id="adequate_number_of_staff"
                      name="adequate_number_of_staff"
                      handleBlur={handleBlur}
                    />
                    {errors.adequate_number_of_staff &&
                      touched.adequate_number_of_staff && (
                        <Message negative>
                          {errors.adequate_number_of_staff}
                        </Message>
                      )}
                  </div>
                  <div className={reviewStyle.questionContainer}>
                    <label className={reviewStyle.Label}>
                      Do you feel you were given enough time to have a safety
                      break, to rest and recuperate?
                    </label>
                    <YesAndNoChoice
                      id="enough_time_break"
                      name="enough_time_break"
                      handleBlur={handleBlur}
                    />
                    {errors.enough_time_break && touched.enough_time_break && (
                      <Message negative>{errors.enough_time_break}</Message>
                    )}
                  </div>
                  <div className={reviewStyle.questionContainer}>
                    <label className={reviewStyle.Label}>
                      How often do you self check?
                    </label>
                    <MultiChoice
                      choices={[
                        'Never',
                        'Rarely',
                        'Sometimes',
                        'Frequently',
                        'All the time',
                      ]}
                      id="how_often_self_check"
                      name="how_often_self_check"
                      handleBlur={handleBlur}
                    />
                    {errors.how_often_self_check &&
                      touched.how_often_self_check && (
                        <Message negative>
                          {errors.how_often_self_check}
                        </Message>
                      )}
                  </div>
                  <div className={reviewStyle.questionContainer}>
                    <label className={reviewStyle.Label}>
                      Did you feel under pressure to hit commercial targets?
                    </label>
                    <YesAndNoChoice
                      id="pressure_of_commercial_targets"
                      name="pressure_of_commercial_targets"
                      handleBlur={handleBlur}
                    />
                    {errors.pressure_of_commercial_targets &&
                      touched.pressure_of_commercial_targets && (
                        <Message negative>
                          {errors.pressure_of_commercial_targets}
                        </Message>
                      )}
                  </div>
                  <div className={reviewStyle.questionContainer}>
                    <label className={reviewStyle.Label}>
                      Was the travel expenses paid?
                    </label>
                    <YesAndNoChoice
                      id="travel_expense_paid"
                      name="travel_expense_paid"
                      handleBlur={handleBlur}
                    />
                    {errors.travel_expense_paid &&
                      touched.travel_expense_paid && (
                        <Message negative>{errors.travel_expense_paid}</Message>
                      )}
                  </div>
                  <div className={reviewStyle.questionContainer}>
                    <label className={reviewStyle.Label}>
                      How long did you have to wait to be paid?
                    </label>
                    <MultiChoice
                      choices={[
                        'Same day',
                        'Within 4 weeks',
                        '4 to 8 weeks',
                        '2 to 6 months',
                        'More than 6 months',
                      ]}
                      id="how_long_to_be_paid"
                      name="how_long_to_be_paid"
                      handleBlur={handleBlur}
                    />
                    {errors.how_long_to_be_paid &&
                      touched.how_long_to_be_paid && (
                        <Message negative>{errors.how_long_to_be_paid}</Message>
                      )}
                  </div>
                  <div className={reviewStyle.questionContainer}>
                    <TextArea
                      type="textarea"
                      label="What are your views/experiences of cluster/area/regional
                    manager?"
                      id="views_of_area_manager"
                      name="views_of_area_manager"
                      onBlur={handleBlur}
                      handleBlur={handleBlur}
                      value={values.views_of_area_manager}
                    />
                    {errors.views_of_area_manager &&
                      touched.views_of_area_manager && (
                        <Message negative>
                          {errors.views_of_area_manager}
                        </Message>
                      )}
                  </div>
                  <div className={reviewStyle.questionContainer}>
                    <label className={reviewStyle.Label}>
                      Did you book through an agency?
                    </label>
                    <YesAndNoChoice
                      id="booked_through_agency"
                      name="booked_through_agency"
                      handleBlur={handleBlur}
                    />
                    {errors.booked_through_agency &&
                      touched.booked_through_agency && (
                        <Message negative>
                          {errors.booked_through_agency}
                        </Message>
                      )}
                  </div>
                  <div className="mt-20" />
                  <div>
                    <button
                      type="submit"
                      className={reviewStyle.filledButton}
                      disabled={isSubmitting}
                    >
                      SUBMIT
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          ) : (
            <div>
              <Lottie animationData={Stars} height={200} width={200} />
              <h2 className="mb-20">Thank you for sharing your feedback :)</h2>
              <a href="/explore">
                <Button>Back to explore page</Button>
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default withRouter(LocumMoreQuestions)

import React from 'react'
import Rating from '@mui/material/Rating'
import StarIcon from '@mui/icons-material/Star'

export default function StarRating(props) {
  const {score, name} = props
  return (
    <Rating
      name={name || 'Syrkle'}
      defaultValue={parseFloat(score)}
      readOnly
      precision={0.5}
      max={10}
      icon={<StarIcon color={'secondary'} {...props} />}
      emptyIcon={<StarIcon color={'grey'} {...props} />}
    />
  )
}

import React from 'react'
import {Box} from '@mui/material'
import FeedPostUserAvatar from '../FeedPostUserAvatar'
import ShortenCaption from '../ShortenCaption'
import SharedLink from '../SharedLink'

export default function PostAbstract(props) {
  const {post} = props
  return (
    <Box sx={styles.container}>
      <FeedPostUserAvatar item={post} avatarSize={35} />
      <Box sx={{whiteSpace: 'pre-line'}}>
        <ShortenCaption fullCaption={post.caption} />
      </Box>
      {post.shared_link ? <SharedLink item={post} /> : null}
    </Box>
  )
}

const styles = {
  container: {
    my: 2,
    p: 5,
    border: '1px solid',
    borderColor: 'primary.lightBackground',
    borderRadius: '25px',
    backgroundColor: 'primary.lightBackground',
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
}

import {useEffect, useState} from 'react'
import {Typography, Box} from '@mui/material'
import Api from '../../services/api.service'
import NotificationManager from './components/NotificationManager'

function NotificationList() {
  const [notifications, setNotifications] = useState([])

  const markAllAsRead = () => {
    Api.get('/notifications/mark-all-as-read/').then(response => {})
  }
  const getNotifications = () => {
    Api.get('/notifications/all/').then(response => {
      setNotifications(response.data)
      markAllAsRead()
    })
  }

  useEffect(() => {
    getNotifications()
  }, [])

  const items = notifications.map(item => {
    return <NotificationManager notification={item} key={item.id} />
  })
  return (
    <Box sx={{display: 'flex', flexDirection: 'column', gap: 3}}>
      <Typography variant="h5">Earlier</Typography>
      {items}
    </Box>
  )
}

export default NotificationList

import React from 'react'
import {useField} from 'formik'
import {Form as FormUi} from 'semantic-ui-react'
import CustomCss from '../Review.module.css'

const TextInput = ({label, helpText, ...props}) => {
  const [field, meta] = useField(props)

  return (
    <FormUi.Field>
      <label className={CustomCss.Label}>
        {label} <br />
        <span className={CustomCss.helpText}>{helpText}</span>
      </label>
      <FormUi.Input {...field} {...props} error={meta.touched && meta.error} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </FormUi.Field>
  )
}

export default TextInput

import React from 'react'
import * as _ from 'lodash'

export default function ShortenCaption(props) {
  const {fullCaption, wordLimit} = props
  const [captionReadMore, setCaptionReadMore] = React.useState(false)
  const limit = wordLimit || 550
  const shortened = () => {
    return _.truncate(fullCaption, {
      length: limit,
      separator: ' ',
    })
  }
  return (
    <>
      {fullCaption.length >= limit && !captionReadMore ? (
        <>
          {shortened()}
          <button
            className="read_more_button"
            onClick={() => setCaptionReadMore(true)}
          >
            Read More
          </button>
        </>
      ) : (
        fullCaption
      )}
    </>
  )
}

import React, {useRef, useEffect} from 'react'
import * as d3 from 'd3'

const LineChart = ({data, dataKey, trendLine}) => {
  const chartRef = useRef(null)

  useEffect(() => {
    const chartContainer = d3.select(chartRef.current)

    // Set up chart dimensions
    const width = window.innerWidth < 460 ? window.innerWidth - 40 : 460
    const height = 400
    const margin = {top: 20, right: 20, bottom: 30, left: 40}
    const innerWidth = width - margin.left - margin.right
    const innerHeight = height - margin.top - margin.bottom

    // Create x and y scales
    const xScale = d3
      .scaleTime()
      .domain(d3.extent(data, d => new Date(d.date)))
      .range([0, innerWidth])
    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, d => d[dataKey])])
      .range([innerHeight, 0])

    // Create x and y axes
    const xAxis = d3.axisBottom(xScale).tickFormat(d3.timeFormat('%b%y'))
    const yAxis = d3.axisLeft(yScale)

    // Create line generator
    const line = d3
      .line()
      .x(d => xScale(new Date(d.date)))
      .y(d => yScale(d[dataKey]))

    // create trend line data
    const lineData = data
      .filter((x, index) => {
        return index === 0 || index === data.length - 1
      })
      .map(x => {
        return {
          date: x.date,
          [dataKey]: x[dataKey],
        }
      })

    // Create chart container
    chartContainer.selectAll('*').remove()
    const svg = chartContainer
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('class', 'myXaxis')
    svg.selectAll('.myXaxis').transition().duration(3000).call(xAxis)
    svg.selectAll('.myXaxis').transition().duration(3000).call(yAxis)

    // add trendline to the graph

    // Create chart group
    const chartGroup = svg
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`)

    // Append x and y axes to the chart group
    chartGroup
      .append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0, ${innerHeight})`)
      .transition()
      .duration(500)
      .call(xAxis)
    chartGroup
      .append('g')
      .attr('class', 'y-axis')
      .transition()
      .duration(500)
      .call(yAxis)

    // Append line to the chart group
    chartGroup
      .append('path')
      .datum(data)
      .attr('class', 'line')
      .attr('d', line)
      .attr('fill', 'none')
      .attr('stroke', 'steelblue')
      .attr('stroke-width', 2)
      .attr('stroke-linejoin', 'round')
      .attr('stroke-linecap', 'round')
      .transition()
      .duration(500)

    // Append circles to the chart group for data points
    chartGroup
      .selectAll('.data-point')
      .data(data)
      .enter()
      .append('circle')
      .attr('class', 'data-point')
      .attr('cx', d => xScale(new Date(d.date)))
      .attr('cy', d => yScale(d[dataKey]))
      .attr('r', 5)
      .attr('fill', 'steelblue')
      .on('mouseover', (event, d) => {
        // Show tooltip on mouseover
        const tooltip = d3.select('#tooltip')
        tooltip
          .style('opacity', 1)
          .style('left', `${event.pageX}px`)
          .style('top', `${event.pageY}px`)
          .html(
            `<strong>Date: </strong>${d.date}<br /><strong>${dataKey}: </strong>${d[dataKey]}`,
          )
      })
      .on('mouseout', () => {
        // Hide tooltip on mouseout
        const tooltip = d3.select('#tooltip')
        tooltip.style('opacity', 0.2)
      })
      .append('text') // Append text to display numbers
      .attr('class', 'data-point-label')
      .attr('x', d => xScale(new Date(d.date)) - 10) // Adjust x position of text label
      .attr('y', d => yScale(d[dataKey]) - 15) // Adjust y position of text label
      .text(d => d[dataKey]) // Set text content to the value of dataKey
      .style('fill', 'white') // Set text color to white
      .style('font-size', '12px') // Set font size of text label

    if (trendLine) {
      chartGroup
        .append('path')
        .datum(lineData)
        .attr('class', 'trendline')
        .attr('d', line)
        .attr('fill', 'none')
        .attr('stroke', 'red')
        .attr('stroke-width', 2)
    }
  }, [data, dataKey, trendLine])

  return (
    <>
      <div
        id="tooltip"
        style={{
          borderBottom: '1px dotted black',
        }}
      />
      <div ref={chartRef} />
      {/* <Tooltip id="tooltip" title="hello">
      </Tooltip> */}
    </>
  )
}

export default LineChart
